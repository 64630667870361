import { environment } from 'environments/environment';
import { Log } from './../models/log.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class LogService {
  constructor(private http: HttpClient) {}

  create(data: Log) {
    return this.http.post(`${environment.apiUrl}/logs`, data);
  }
  show(id: string) {
    return this.http.get(`${environment.apiUrl}/logs/${id}`);
  }
  showAll(params: any = {}) {
    return this.http.get(`${environment.apiUrl}/logs`, { params: params });
  }
}
