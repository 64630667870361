import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actuator } from './../models/actuator.model';
import { environment } from '../../../environments/environment';


/**
 * Service to use the API calls related with actuations.
 */
@Injectable()
export class ActuatorService {
  /**
   * Build the component and initialize certain attributes.
   * @param {HttpClient} http Service to make API calls.
   */
  public constructor(private http: HttpClient) {}

  /**
   * Sends an actuation to store it in the backend.
   * @param {Actuator} data Actuation to be stored.
   */
  public create(data: Actuator) {
    return this.http.post(`${environment.apiUrl}/actuations`, data);
  }
}
