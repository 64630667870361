import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalAlertComponent } from './modal-alert/modal-alert.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DatatableComponent } from './datatable/datatable.component';
import { DataTablesModule } from 'angular-datatables';
import { NodeActuatorComponent } from './node-actuator/node-actuator.component';
import { ActuatorBlindComponent } from './node-actuator/actuator-blind/actuator-blind.component';
import { InputSelectServerComponent } from './form/input-select-server/input-select-server.component';
import { InputNodesServerComponent } from './form/input-nodes-server/input-nodes-server.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShowErrorsComponent } from './form/show-errors/show-errors.component';
import { InputTagsServerComponent } from './form/input-tags-server/input-tags-server.component';
import { NodeActuatorAutoComponent } from './node-actuator/node-actuator-auto/node-actuator-auto.component';
import { NodeActuatorCustomComponent } from './node-actuator/node-actuator-custom/node-actuator-custom.component';
import { ActuatorSensorComponent } from './node-actuator/actuator-sensor/actuator-sensor.component';
import { SwitchActuatorComponent } from './node-actuator/actuators/switch-actuator/switch-actuator.component';
import { DimmerActuatorComponent } from './node-actuator/actuators/dimmer-actuator/dimmer-actuator.component';
import { DimmerSwitchActuatorComponent } from './node-actuator/actuators/dimmer-switch-actuator/dimmer-switch-actuator.component';
import {
  DimmerSwitchCanvasComponent
} from './node-actuator/actuators/dimmer-switch-actuator/dimmer-switch-canvas/dimmer-switch-canvas.component';
import { FanActuatorComponent } from './node-actuator/actuators/fan-actuator/fan-actuator.component';
import { FanSpeedActuatorComponent } from './node-actuator/actuators/fan-speed-actuator/fan-speed-actuator.component';
import { BrightnessActuatorComponent } from './node-actuator/actuators/brightness-actuator/brightness-actuator.component';
import { HueActuatorComponent } from './node-actuator/actuators/hue-actuator/hue-actuator.component';
import { SaturationActuatorComponent } from './node-actuator/actuators/saturation-actuator/saturation-actuator.component';
import { TemperatureActuatorComponent } from './node-actuator/actuators/temperature-actuator/temperature-actuator.component';
import { TemperatureModeActuatorComponent } from './node-actuator/actuators/temperature-mode-actuator/temperature-mode-actuator.component';
import { SharedModule } from '../shared/shared.module';
import { ActuatorSmartlightSwitchComponent } from './node-actuator/actuator-smartlight-switch/actuator-smartlight-switch.component';
import { SwitchBoxComponent } from './switch-box/switch-box.component';
import { DatatableFiltersComponent } from './datatable-filters/datatable-filters.component';
import { DatatableFilterSelectComponent } from './datatable-filter-select/datatable-filter-select.component';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';
import {
  ColorTemperatureActuatorComponent
} from './node-actuator/actuators/color-temperature-actuator/color-temperature-actuator.component';
import {
  TemperatureCorrectionActuatorComponent
} from './node-actuator/actuators/temperature-correction-actuator/temperature-correction-actuator.component';
import { ActuatorSmartFourComponent } from './node-actuator/actuator-smart-four/actuator-smart-four.component';
import { MapComponent } from './map/map.component';
import { MapDevicesComponent } from './form/map-devices/map-devices.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SortableListComponent } from './sortable-list/sortable-list.component';
import { ActuatorSpeakerComponent } from './node-actuator/actuator-speaker/actuator-speaker.component';
import { SystemModeActuatorComponent } from './node-actuator/actuators/system-mode-actuator/system-mode-actuator.component';
import { EcoModeActuatorComponent } from './node-actuator/actuators/eco-mode-actuator/eco-mode-actuator.component';
import { ManualModeActuatorComponent } from './node-actuator/actuators/manual-mode-actuator/manual-mode-actuator.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PushActuatorComponent } from './node-actuator/actuators/push-actuator/push-actuator.component';

@NgModule({
  imports: [
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    ModalModule.forRoot(),
    DataTablesModule,
    TranslateModule,
    DragDropModule,
    FontAwesomeModule
  ],
  exports: [
    ShowErrorsComponent,
    ModalAlertComponent,
    DatatableComponent,
    DatatableFiltersComponent,
    NodeActuatorComponent,
    InputSelectServerComponent,
    InputTagsServerComponent,
    NotificationModalComponent,
    InputNodesServerComponent,
    MapComponent,
    MapDevicesComponent,
    SortableListComponent
  ],
  entryComponents: [ModalAlertComponent],
  declarations: [
    MapComponent,
    ModalAlertComponent,
    DatatableComponent,
    DatatableFiltersComponent,
    NodeActuatorComponent,
    ActuatorBlindComponent,
    InputSelectServerComponent,
    InputNodesServerComponent,
    InputTagsServerComponent,
    ShowErrorsComponent,
    NodeActuatorAutoComponent,
    NodeActuatorCustomComponent,
    ActuatorSensorComponent,
    SwitchActuatorComponent,
    DimmerActuatorComponent,
    DimmerSwitchActuatorComponent,
    DimmerSwitchCanvasComponent,
    FanActuatorComponent,
    FanSpeedActuatorComponent,
    BrightnessActuatorComponent,
    HueActuatorComponent,
    ColorTemperatureActuatorComponent,
    SaturationActuatorComponent,
    ActuatorSmartlightSwitchComponent,
    TemperatureCorrectionActuatorComponent,
    SwitchBoxComponent,
    DatatableFilterSelectComponent,
    NotificationModalComponent,
    TemperatureActuatorComponent,
    TemperatureModeActuatorComponent,
    ActuatorSmartFourComponent,
    MapDevicesComponent,
    SortableListComponent,
    ActuatorSpeakerComponent,
    SystemModeActuatorComponent,
    EcoModeActuatorComponent,
    ManualModeActuatorComponent,
    PushActuatorComponent
  ]
})
export class WidgetsModule { }
