import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Constants } from '../../../shared/constants';
import { BLIND_ACTIONS } from './blind-actions.enum';
import * as _ from 'underscore';
import { timer } from 'rxjs';

/**
 * Component used to represent blind actuators.
 */
@Component({
  selector: 'app-actuator-blind',
  templateUrl: './actuator-blind.component.html',
  styleUrls: ['./actuator-blind.component.scss']
})
export class ActuatorBlindComponent implements OnInit {
  /**
   * Indicates whether the actuator is connected or not.
   */
  @Input() public status: boolean;
  /**
   * Endpoints related to UP or DOWN actuations.
   */
  @Input() endpoints: any;
  /**
   * Contains the node ID.
   */
  @Input() device: any;
  /**
   * Complete and blade times.
   */
  @Input() times: any;
  /**
   * Emits an event to create a new blind actuation.
   */
  @Output() setAction: EventEmitter<any> = new EventEmitter();

  /**
   * List of endpoint types.
   */
  public TYPE_ENDPOINTS: any = Constants.TYPE_ENDPOINTS;
  /**
   * Enum of blind actions.
   */
  public BLIND_ACTIONS: any = BLIND_ACTIONS;
  /**
   * Endpoint UP.
   */
  public endpointUp: any;
  /**
   * Endpoint DOWN.
   */
  public endpointDown: any;
  /**
   * Contains the value of runningtiming.
   */
  public timing = 0;
  /**
   * Cronometer for partial actuations.
   */
  private runningtiming: any;
  /**
   * Indicates the actuation value of the blind. On or Off.
   */
  public sendedValue: any;
  /**
   * Blind action currently using.
   */
  public typeActionRunning: any;
  /**
   * Endpoint used.
   */
  private activeEndpoint: any;

  /**
   * Builds the component.
   */
  constructor() { }

  /**
   * Initializes both enpoints, UP and DOWN.
   */
  ngOnInit() {
    console.log('ENDPOINTS:::', this.endpoints);
    console.log('DEVICE:::', this.device, this.status);

    this.endpointUp = _.findWhere(this.endpoints, { id: this.TYPE_ENDPOINTS.RELAY_1 }) || {};
    this.endpointDown = _.findWhere(this.endpoints, { id: this.TYPE_ENDPOINTS.RELAY_0 }) || {};
  }

  /**
   * Function executed while the user is pressing a button. A blind action is performed.
   * @param action UP or DOWN.
   * @param typeAction Blind action.
   */
  pushOn(action: string, typeAction: string) {
    if (this.endpointUp && this.endpointDown) {
      this.activeEndpoint =
        action === 'UP' ? this.endpointUp._id : this.endpointDown._id;
      this.sendedValue = 'On';
      const objectSave: any = {
        device: this.device,
        endpoint: this.activeEndpoint,
        value: this.sendedValue
      };
      this.typeActionRunning = typeAction;
      console.log('NODE BLIND ON:::>', action, typeAction, objectSave);

      this.setAction.emit(objectSave);

      switch (typeAction) {
        case BLIND_ACTIONS.PARTIAL:
          this.initTime();
          break;
        case BLIND_ACTIONS.COMPLETE:
          // After x seconds, the complete action stops.
          timer(this.times.completeTime).subscribe(() => {
            console.log(
              typeAction,
              action,
              objectSave,
              'COMPLETE'
            );
            this.pushOff(BLIND_ACTIONS.COMPLETE);
          });
          break;
        case BLIND_ACTIONS.BLADES:
          // After x seconds the blade action stops.
          timer(this.times.bladeTime).subscribe(() => {
            console.log(
              typeAction,
              action,
              objectSave,
              'BLADE'
            );
            this.pushOff(BLIND_ACTIONS.BLADES);
          });
          break;
      }
    }
  }

  /**
   * Function executed when the user stops pressing a Partial button.
   * @param {string} typeAction Blind action.
   */
  pushOff(typeAction: string) {
    // If there is no active point, the push off function is not executed.
    if (this.activeEndpoint) {
      switch (typeAction) {
        case BLIND_ACTIONS.PARTIAL:
          this.clearTime();
          break;
      }
      this.sendedValue = 'Off';
      const objectSave: any = {
        device: this.device,
        endpoint: this.activeEndpoint,
        value: this.sendedValue
      };
      console.log('NODE BLIND STOP:::>', typeAction, objectSave);
      this.setAction.emit(objectSave);
      this.activeEndpoint = null;
    }
  }

  /**
   * Starts cronometer from 0.
   */
  initTime() {
    this.timing = 0;
    this.runningtiming = setInterval(() => {
      this.timing++;
    }, 1000);
  }

  /**
   * Stops the cronometer.
   */
  clearTime() {
    this.timing = 0;
    clearInterval(this.runningtiming);
  }
}
