import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Constants } from 'app/shared/constants';
import { environment } from 'environments/environment';
import { Node } from 'app/shared/models/node.model';
import { TranslateService } from '@ngx-translate/core';
import {
  IconDefinition,
  faVolumeOff,
  faVolumeUp,
  faStepBackward,
  faStop,
  faPause,
  faPlay,
  faStepForward
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-actuator-speaker',
  templateUrl: './actuator-speaker.component.html',
  styleUrls: ['./actuator-speaker.component.scss']
})
export class ActuatorSpeakerComponent implements OnInit {

  public albumCover: any;
  public volume: any;
  public mute: any;
  public state: any;
  public title: any;
  public artist: any;
  public album: any;
  public duration: any;
  private action: any;
  public apiUrl: string;
  public translations: any = {};
  public faVolumeOff: IconDefinition = faVolumeOff;
  public faVolumeUp: IconDefinition = faVolumeUp;
  public faStepBackward: IconDefinition = faStepBackward;
  public faStop: IconDefinition = faStop;
  public faPause: IconDefinition = faPause;
  public faPlay: IconDefinition = faPlay;
  public faStepForward: IconDefinition = faStepForward;

  @Input() public device: Node;
  @Input() public status: boolean;
  @Output() private setAction: EventEmitter<any> = new EventEmitter();

  public constructor(private translate: TranslateService) { }

  public ngOnInit() {
    this.translations.mute = this.translate.instant('multimedia.mute');
    this.translations.unmute = this.translate.instant('multimedia.unmute');
    this.translations.previous = this.translate.instant('multimedia.previous');
    this.translations.play = this.translate.instant('multimedia.play');
    this.translations.pause = this.translate.instant('multimedia.pause');
    this.translations.next = this.translate.instant('multimedia.next');
    this.translations.stop = this.translate.instant('multimedia.stop');
    this.apiUrl = environment.baseUrl;
    this.device.scheme.endpoints.forEach(endpoint => {
      switch (endpoint.id) {
        case Constants.TYPE_ENDPOINTS.IMAGE:
          this.albumCover = endpoint;
          break;
        case Constants.TYPE_ENDPOINTS.VOLUME:
          this.volume = endpoint;
          break;
        case Constants.TYPE_ENDPOINTS.MUTE:
          this.mute = endpoint;
          break;
        case Constants.TYPE_ENDPOINTS.STATE:
          this.state = endpoint;
          break;
        case Constants.TYPE_ENDPOINTS.TITLE:
          this.title = endpoint;
          break;
        case Constants.TYPE_ENDPOINTS.ARTIST:
          this.artist = endpoint;
          break;
        case Constants.TYPE_ENDPOINTS.ALBUM:
          this.album = endpoint;
          break;
        case Constants.TYPE_ENDPOINTS.DURATION:
          this.duration = endpoint;
          break;
        case Constants.TYPE_ENDPOINTS.ACTION:
          this.action = endpoint;
          break;
      }
    });
  }

  public onMute() {
    this.setAction.emit({ device: this.device._id, endpoint: this.mute._id, value: this.mute.current === 'On' ? 'Off' : 'On' });
  }
  public onPrevious() {
    this.onAction('Previous');
  }
  public onStop() {
    this.onAction('Stop')
  }
  public onPlaypause() {
    if (this.state.current !== 'no_media') {
      if (this.state.current === 'playing' || this.state.current === 'transitioning') {
        this.onAction('Pause');
      } else {
        this.onAction('Play');
      }
    }
  }
  public onNext() {
    this.onAction('Next');
  }
  private onAction(value) {
    this.setAction.emit({ device: this.device._id, endpoint: this.action._id, value: value });
  }

  public onVolumeChange(data) {
    this.setAction.emit({ device: this.device._id, endpoint: this.volume._id, value: data.value });
  }
}
