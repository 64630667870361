import { Subscription, timer } from 'rxjs';
import { SettingsService } from './services/settings.service';
import { NodeService } from './services/node.service';
import { User } from './models/user.model';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

export class Global {
  static call$: Subscription;
  static timer$: Subscription;
  static disconnected$: Subscription;
  static setting$: Subscription;
  static settings: any = {};

  static initializeGlobal(service: SettingsService) {
    this.call$ = service.initializeGlobal().subscribe((res: any) => {
      res.docs.forEach((setting: any) => {
        Global.settings[setting.name] = setting.value;
      });
      this.call$.unsubscribe();
    });
  }

  static startNotificationTimer(
    translate: TranslateService,
    deviceService: NodeService,
    settingsService: SettingsService,
    currentUser: User,
    toastr: ToastrService,
    dueTime: boolean = false
  ) {
    if (currentUser.is_super_admin || currentUser.is_admin) {
      this.setting$ = settingsService.showAll({ names: 'status-time-notification' }).subscribe((res: any) => {
        if (res.docs.length !== 0) {
          this.timer$ = timer(dueTime ? res.docs[0].value : 0, res.docs[0].value).subscribe(() => {
            if (this.disconnected$) {
              this.disconnected$.unsubscribe();
            }
            this.disconnected$ = deviceService.disconnected().subscribe((res: any) => {
              let notification: string = '';
      
              if (res.nodes) {
                notification += translate.instant('nodes.disconnected') + ': ' + res.nodes + '<br/>';
              }
              if (res.gateways) {
                notification += translate.instant('gateway.disconnected') + ': ' + res.gateways + '<br/>';
              }
              if (res.mailboxes) {
                notification += translate.instant('mailboxes.disconnected') + ': ' + res.mailboxes + '<br/>';
              }
              if (res.sensors) {
                notification += translate.instant('sensors.disconnected') + ': ' + res.sensors + '<br/>';
              }
              if (res.mobiles) {
                notification += translate.instant('mobileAssets.disconnected') + ': ' + res.mobiles + '<br/>';
              }
              if (res.edgeServers) {
                notification += translate.instant('edgeServers.disconnected') + ': ' + res.edgeServers;
              }
              if (res.sipServers) {
                notification += translate.instant('sipSerfvers.disconnected') + ': ' + res.sipServers;
              }
      
              if (notification) {
                toastr.toastrConfig.enableHtml = true
                toastr.warning(notification);
              }
            });
          });
        } else {
          if (this.disconnected$) {
            this.disconnected$.unsubscribe();
          }
          this.disconnected$ = deviceService.disconnected().subscribe((res: any) => {
            let notification: string = '';
    
            if (res.nodes) {
              notification += translate.instant('nodes.disconnected') + ': ' + res.nodes + '<br/>';
            }
            if (res.gateways) {
              notification += translate.instant('gateway.disconnected') + ': ' + res.gateways + '<br/>';
            }
            if (res.mailboxes) {
              notification += translate.instant('mailboxes.disconnected') + ': ' + res.mailboxes + '<br/>';
            }
            if (res.sensors) {
              notification += translate.instant('sensors.disconnected') + ': ' + res.sensors + '<br/>';
            }
            if (res.mobiles) {
              notification += translate.instant('mobileAssets.disconnected') + ': ' + res.mobiles + '<br/>';
            }
            if (res.edgeServers) {
              notification += translate.instant('edgeServers.disconnected') + ': ' + res.edgeServers;
            }
            if (res.sipServers) {
              notification += translate.instant('sipServers.disconnected') + ': ' + res.sipServers;
            }

            if (notification) {
              toastr.toastrConfig.enableHtml = true
              toastr.warning(notification);
            }
          });
        }
      })
    }
  }

  static stopNotificationTimer() {
    if (this.setting$) {
      this.setting$.unsubscribe();
    }
    if (this.timer$) {
      this.timer$.unsubscribe();
    }
    if (this.disconnected$) {
      this.disconnected$.unsubscribe();
    }
  }

  static errorManager(httpError: any, toastr: ToastrService) {
    const error = httpError.error;
    if (typeof error === 'string') {
      toastr.error(error);
    } else {
      let message: string = error.errors[0].msg;
      for (let index = 1; index < error.errors.length; index++) {
        const err = error.errors[index];
        message += '<br/>' + err.msg;
      }
      toastr.error(message, '', { enableHtml: true });
    }
  }
}
