<div *ngIf="!status" style="width: 100%; height: 100%; position: absolute; z-index: 1;"></div>
<div class="row">
    <h4>{{ data.display_name || data.name }}</h4>
    <div class="col-sm-6">
        <h5>Dimmer 0
            <span class="badge badge-info">{{ data.current[0] | transformEndpointValues:endpoint || 0 }}</span>
        </h5>
        <input (mouseup)="onChangeDimmer(0, data.current[0])" [(ngModel)]="data.current[0]" [value]="data.current[0]"
            class="form-control" type="range" min="0" max="255" step="1">
    </div>

    <div class="col-sm-6">
        <h5>Dimmer 1
            <span class="badge badge-info">{{ data.current[1] | transformEndpointValues:endpoint || 0 }}</span>
        </h5>
        <input (mouseup)="onChangeDimmer(1, data.current[1])" [(ngModel)]="data.current[1]" [value]="data.current[1]"
            class="form-control" type="range" min="0" max="255" step="1">
    </div>
</div>