import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Setting } from '../models/setting.model';
import { environment } from 'environments/environment';

@Injectable()
export class SettingsService {
  constructor(private http: HttpClient) {}

  create(data: Setting) {
    return this.http.post(`${environment.apiUrl}/settings`, data);
  }
  update(id: string, data: Setting) {
    return this.http.put(`${environment.apiUrl}/settings/${id}`, data);
  }
  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/settings/${id}`);
  }
  show(id: string) {
    return this.http.get(`${environment.apiUrl}/settings/${id}`);
  }
  showAll(params: any = {}) {
    return this.http.get(`${environment.apiUrl}/settings`, { params: params });
  }
  public initializeGlobal() {
    return this.http.get(`${environment.apiUrl}/settings`, {
      params: { names: ['default-periodicity'] }
    });
  }
}
