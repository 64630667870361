import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Rule } from './../models/rule.model';
import { environment } from '../../../environments/environment';

/**
 * Service to use the API rules related calls.
 */
@Injectable()
export class RuleService {
  /**
   * Build the component and initialize certain attributes.
   * @param {HttpClient} http Service to make API calls.
   */
  constructor(private http: HttpClient) {}

  /**
   * Sends a rule to store it in the backend.
   * @param {Rule} data Rule to be stored.
   */
  create(data: Rule) {
    return this.http.post(`${environment.apiUrl}/rules`, data);
  }
  /**
   * Updates a rule in the backend.
   * @param {string} id Rule ID.
   * @param {Rule} data Rule to be updated.
   */
  update(id: string, data: Rule) {
    return this.http.put(`${environment.apiUrl}/rules/${id}`, data);
  }
  /**
   * Deletes a rule in the backend.
   * @param {string} id Rule ID.
   */
  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/rules/${id}`);
  }
  /**
   * Gets Rules filtering by certain parameters.
   * @param params Parameters to filter the query.
   */
  showAll(params: any = {}) {
    return this.http.get(`${environment.apiUrl}/rules`, { params: params });
  }
}
