
import { ViewChild, Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../shared/services/auth.service';
import { environment } from 'environments/environment';
import { LoginForm } from './login-form.class';
import { RegisterForm } from './register-form.class';
import { RoutingService } from '../../shared/services/routing.service';
import { SocketService } from './../../socket.service';
import { NodeService } from 'app/shared/services/node.service';
import { TranslateService } from '@ngx-translate/core';
import { Global } from 'app/shared/global';
import { SettingsService } from 'app/shared/services/settings.service';
import { IconDefinition, faEye, faEyeSlash, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Constants } from 'app/shared/constants';

const TAB_LOGIN = 'tabLogin';
const TAB_REGISTER = 'tabRegister';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public version: string;
  private sub$: Subscription = new Subscription();

  public data: LoginForm = new LoginForm();
  public dataRegister: RegisterForm = new RegisterForm();
  public error: any = {
    success: null,
    message: null
  };
  public showPassword1 = false;
  public showPassword2 = false;
  public showPassword3 = false;
  public faEye: IconDefinition = faEye;
  public faEyeSlash: IconDefinition = faEyeSlash;
  public faGlobe: IconDefinition = faGlobe;

  public TAB_LOGIN = TAB_LOGIN;
  public TAB_REGISTER = TAB_REGISTER;
  public selectedTab = TAB_LOGIN;
  public languages: any = Constants.LANGUAGES;
  public language: string;

  @ViewChild('f', { static: true }) form: any;
  @ViewChild('fr', { static: true }) formRegister: any;

  /**
   * Builds the component and initializes necessary services.
   * @param {AuthService} auth Service used to obtain the user logged in.
   * @param {Router} router Provides the navigation and url manipulation capabilities.
   * @param {RoutingService} routingService Service that provides methods to manage routes.
   * @param {SocketService} socketService Service to subscribe to the device creation event.
   * @param {ToastrService} toastr Service to show notifications to the user.
   */
  public constructor(
    private authService: AuthService,
    private router: Router,
    private routingService: RoutingService,
    private socketService: SocketService,
    private toastr: ToastrService,
    private deviceService: NodeService,
    private settingsService: SettingsService,
    private translate: TranslateService
  ) { }

  public ngOnInit() {
    this.version = environment.buildingVersion;
    this.language = localStorage.getItem('login_lang');
    if (!this.language) {
      this.language = Constants.LANGUAGES.english;
    }
    this.translate.use(this.language);
  }

  public onSubmit() {
    if (this.form.valid) {
      this.sub$.add(this.authService.doLogin(this.data).subscribe(
        () => {
          const currentUser = this.authService.getAuthUser();
          this.socketService.initSocket();
          this.router.navigate([this.routingService.getPreviousUrl()]);
          this.translate.setDefaultLang(Constants.LANGUAGES.english);
          if (currentUser.language) {
            this.translate.use(currentUser.language);
            sessionStorage.setItem('session_lang', currentUser.language);
          }
          Global.startNotificationTimer(
            this.translate,
            this.deviceService,
            this.settingsService,
            currentUser,
            this.toastr
          );
        },
        res => {
          this.error.success = false;
          this.error.message = 'Username and password do not match';
        }
      ));
    }
  }

  public onRegister() {
    if (this.formRegister.valid) {
      this.dataRegister.languageRegister = this.language;
      this.sub$.add(
        this.authService.doRegister(this.dataRegister).subscribe(
          (data: any) => {
            this.error.success = true;
            this.formRegister.reset();
            this.toastr.success('Now you are registered, your account is pending to approve.');
          },
          (httpError: any) => {
            switch(httpError.status) {
              case 404:
                this.error.message = httpError.error;
                break;
              
              case 422:
                const error = httpError.error;
                if (typeof error === 'string') {
                  this.error.message = error;
                } else {
                  let message: string = error.errors[0].msg;
                  for (let index = 1; index < error.errors.length; index++) {
                    const err = error.errors[index];
                    message += `\n${err.msg}`;
                  }
                  this.error.message = message;
                }
                break;
              
              default:
                this.error.message = this.translate.instant('somethingHappened');
            }
            this.error.success = false;
          }
        ));
    }
  }

  public onChangeLanguage(language: string) {
    localStorage.setItem('login_lang', language);
    this.language = language;
    this.translate.use(this.language);
  }

  public onShowPassword1() {
    this.showPassword1 = !this.showPassword1;
  }
  public onShowPassword2() {
    this.showPassword2 = !this.showPassword2;
  }
  public onShowPassword3() {
    this.showPassword3 = !this.showPassword3;
  }

  public ngOnDestroy() {
    if (this.sub$) {
      this.sub$.unsubscribe();
    }
  }
}
