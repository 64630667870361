import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { OAuth2Client } from '../models/oauth2-client.model';

@Injectable({
  providedIn: 'root'
})
export class Oauth2ClientService {
  public constructor(private http: HttpClient) { }

  public create(data: OAuth2Client) {
    return this.http.post(`${environment.apiUrl}/oauth2/clients`, data);
  }
  public update(id: string, data: OAuth2Client) {
    return this.http.put(`${environment.apiUrl}/oauth2/clients/${id}`, data);
  }
  public delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/oauth2/clients/${id}`);
  }
  public show(id: string) {
    return this.http.get(`${environment.apiUrl}/oauth2/clients/${id}`);
  }
  public showAll(params: any = {}) {
    return this.http.get(`${environment.apiUrl}/oauth2/clients`, {
      params: params
    });
  }
}
