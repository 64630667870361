
<!-- Provisional hasta que todos los formularios sean reactivos -->
<span *ngIf="group" [formGroup]="group">
    <span [ngClass]="{'disabled':disableSelector}">
        <ng-select
            [items]="items"
            [virtualScroll]="true"
            [loading]="loading"
            [hideSelected]="true"
            [typeahead]="itemsTypeahead"
            bindLabel="{{ label }}"
            bindValue="_id"
            placeholder="{{ settings.placeholder }}"
            (changes)="onChangeItem($event); onSelectItem($event)"
            (clear)="onClearBox($event)"
            [formControlName]="name"
            (scrollToEnd)="fetchMore($event)"
            class="input-select">
            <ng-template ng-header-tmp>
                <small class="form-text text-muted">{{ 'Loaded' | translate }} {{ items.length }} {{ 'of' | translate }} {{ totalItems }}</small>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <b>{{ index + 1 }}</b> - {{ getNestedLabelValue(item) }}
            </ng-template>
        </ng-select>
    </span>
</span>

<span *ngIf="!group">
    <span [ngClass]="{'disabled':disableSelector}">
        <ng-select
            [items]="items"
            [virtualScroll]="true"
            [loading]="loading"
            [hideSelected]="true"
            [typeahead]="itemsTypeahead"
            bindLabel="{{ label }}"
            bindValue="_id"
            [(ngModel)]="selected"
            (scrollToEnd)="fetchMore($event)"
            class="input-select">
            <ng-template ng-header-tmp>
                <small class="form-text text-muted">{{ 'Loaded' | translate }} {{ items.length }} {{ 'of' | translate }} {{ totalItems }}</small>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <b>{{ index }}</b> - {{ getNestedLabelValue(item) }}
            </ng-template>
        </ng-select>
    </span>
</span>
