<nav class="navbar">
  <button class="navbar-toggler btn btn-primary" type="button" (click)="toggleNavigation()">
    <fa-icon [icon]="faBars"></fa-icon>
  </button>
  <ul class="nav navbar-nav">
    <li class="nav-item">
      <a class="nav-link active" (click)="logout()">
        <fa-icon [icon]="faSignOutAlt"></fa-icon> {{ 'log_out' | translate }}
      </a>
    </li>
  </ul>
</nav>