<div *ngIf="!status" style="width: 100%; height: 100%; position: absolute; z-index: 1;"></div>
<div class="row" *ngIf="endpoint.current !== undefined">
  <h4>{{ endpoint.display_name || endpoint.name }}</h4>
  <div class="col-12">
    <div class="btn-group">
      <button [class.btn-primary]="endpoint.current === 'On'" [class.btn-default]="endpoint.current !== 'On'"
        (click)="onChangeEndpoint('On')" class="btn btn-lg">On
      </button>
      <button [class.btn-primary]="endpoint.current === 'Off'" [class.btn-default]="endpoint.current !== 'Off'"
        (click)="onChangeEndpoint('Off')" class="btn btn-lg">Off
      </button>
    </div>
  </div>
</div>