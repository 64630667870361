import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class MeasureService {

  constructor(
    private http: HttpClient
  ) { }

  showAll(params: any = {}) {
    return this.http.get(`${environment.apiUrl}/measures`, { params: params });
  }
}
