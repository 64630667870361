<nav class="navbar-default navbar-static-side" role="navigation">
  <div class="sidebar-collapse">
    <ul *ngIf="menu" class="nav metismenu" id="side-menu">
      <li class="nav-header" dropdown>
        <div class="profile-element" dropdown="true">
          <img
            *ngIf="!currentUser.is_super_admin && currentUser.organizationObj && currentUser.organizationObj.logo !== ''"
            [alt]="currentUser.organizationObj.name" class="organization-logo"
            src="{{baseUrl}}assets/organizations/{{currentUser.organizationObj.logo}}" />
          <img
            *ngIf="currentUser.is_super_admin || !currentUser.organizationObj || currentUser.organizationObj.logo === ''"
            alt="gozmart-logo" class="default-logo" src="assets/custom/images/logo.png" />
          <a aria-expanded="false" dropdownToggle *ngIf="currentUser">
            <div class="row justify-content-between">
              <div class="col-4">
                <a class="block m-t-xs">{{ currentUser.name }}</a>
                <span class="text-muted text-xs block">{{ currentUser.surname }}<b class="caret"></b></span>
              </div>
              <div class="col-8 text-right">
                <span *ngIf="currentUser.is_admin"
                  class="label label-warning-light">{{ 'roles.admin' | translate }}</span>
                <span *ngIf="currentUser.is_super_admin"
                  class="label label-warning-light">{{ 'roles.superAdmin' | translate }}</span>
                <span *ngIf="!currentUser.is_super_admin && !currentUser.is_admin"
                  class="label label-warning-light">{{ 'roles.subscriber' | translate }}</span>
              </div>
            </div>
          </a>
          <ul class="animated fadeIn dropdown-menu" *dropdownMenu>
            <li>
              <a [routerLink]="['/settings/profile']">{{ 'tables.profile' | translate }}</a>
            </li>
            <li class="divider"></li>
            <li>
              <a class="logout" (click)="logout()">{{ 'log_out' | translate }}</a>
            </li>
          </ul>
        </div>

        <div class="logo-element">
          GZM
        </div>
      </li>

      <li *ngFor="let item of menu" routerLinkActive="mm-active">
        <a *ngIf="item.state !== null && item.permission" [ngClass]="{ 'has-arrow': item.subMenu.length }"
          [routerLink]="item.state" [target]="item.target">
          <fa-icon [icon]="item.icon"></fa-icon>
          <span class="nav-label">{{item.label}}</span>
        </a>

        <a *ngIf="item.state === null && item.permission" [ngClass]="{ 'has-arrow': item.subMenu.length }">
          <fa-icon [icon]="item.icon"></fa-icon>
          <span class="nav-label">{{item.label}}</span>
        </a>

        <ul *ngIf="item.subMenu.length && item.permission" class="nav nav-second-level" routerLinkActive
          #rla="routerLinkActive" [ngClass]="rla.isActive ? 'in' : 'mm-collapse'">
          <li *ngFor="let subItem1 of item.subMenu" routerLinkActive="mm-active">
            <a *ngIf="subItem1.state !== null && subItem1.permission"
              [ngClass]="{ 'has-arrow': subItem1.subMenu.length }" [routerLink]="subItem1.state">
              <fa-icon *ngIf="subItem1.icon" [icon]="subItem1.icon"></fa-icon>
              {{subItem1.label}}
            </a>

            <a *ngIf="subItem1.state === null && subItem1.permission"
              [ngClass]="{ 'has-arrow': subItem1.subMenu.length }" href="#">
              <fa-icon *ngIf="subItem1.icon" [icon]="subItem1.icon"></fa-icon>
              {{subItem1.label}}
            </a>

            <ul *ngIf="subItem1.subMenu.length && subItem1.permission" class="nav nav-third-level">
              <li *ngFor="let subItem2 of subItem1.subMenu" routerLinkActive="mm-active">
                <a [routerLink]="subItem2.state" [ngClass]="{ 'has-arrow': subItem2.subMenu.length }">
                  <fa-icon *ngIf="subItem2.icon" [icon]="subItem2.icon"></fa-icon>{{subItem2.label}}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</nav>