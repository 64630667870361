import { TranslateService } from '@ngx-translate/core';
import {
  Component,
  ViewChild,
  OnInit,
  Output,
  Input,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  AfterViewInit,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import { Subscription, Subject, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { DataTableDirective } from 'angular-datatables';
declare var $: any;

/**
 * Component used to manage datatables.
 */
@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatatableComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  /**
   * Name of the datatable.
   */
  public name: string;
  /**
   * Several options datatables.js manages.
   */
  public dtOptions: any;
  public delete: any;
  /**
   * We use this trigger because fetching a list can be quite long, thus we ensure the data is fetched before rendering.
   * Internal attribute for datatables.js.
   */
  public dtTrigger: Subject<any> = new Subject();
  /**
   * Datatable row modified.
   */
  private itemUpdated: any;
  /**
   * Observes changes on itemUpdated.
   */
  private updatedItem$ = new BehaviorSubject<any>(null);
  private data: any;
  private request$: Subscription;
  private interval$: Subscription;
  private translate$: Subscription;
  /**
   * Options for datatables.js coming from a wrapping component.
   */
  @Input() settings: any;
  /**
   * One of the variables that determines whether the table should be refreshed or not, on datatable changes.
   */
  @Input() reload: any;

  @Input() filters: any;
  /**
   * New item added to the datatable dynamically.
   */
  @Input() liveData: any;
  /**
   * Emits info about what action the user chose to be performed for a row in the datatable.
   */
  @Output() datatableAction: EventEmitter<any> = new EventEmitter();
  /**
   * Emits info about datatable instance.
   */
  @Output() datatableComponent: EventEmitter<any> = new EventEmitter();
  /**
   * Element datatable from the component HTML.
   */
  @ViewChild(DataTableDirective, { static: true }) dtElement: DataTableDirective;

  /**
   * Show button in html.
   */
  private showActionHtml: any;
  /**
   * Edit button in html.
   */
  private editActionHtml: any;
  /**
   * Remove button in html.
   */
  private removeActionHtml: any;
  /**
   * Unlink button in html.
   */
  private unlinkActionHtml: any;

  /**
   * Build the component and initialize certain attributes.
   * @param {HttpClient} http Service to make API calls.
   * @param {TranslateService} translate Service to make translations.
   */
  constructor(private http: HttpClient, private translate: TranslateService) { }

  /**
   * Initializes certain variables and most important, the datatables attributes.
   */
  ngOnInit() {
    this.translate$ = this.translate.get(['buttons']).subscribe((translations: any) => {
      this.getButtons(translations);
    });
    this.name = this.settings.name || 'table';
    const columns = this.getColumns(this.translate);
    this.dtOptions = {
      // Defines the pagination control below the table.
      pagingType: 'full_numbers',
      // Number of rows to display on a single page when using pagination.
      pageLength: 10,
      // Determines whether filtering, paging and sorting calculations are all performed by a server.
      serverSide: true,
      // Enable or disable the display of a 'processing' indicator when the table is being processed.
      processing: true,
      // Enable or disable state saving. When enabled aDataTables will store state information
      // such as pagination position, display length, filtering and sorting. When the end user
      // reloads the page the table's state will be altered to match what they had previously set up.
      stateSave: true,
      // Load data for the table's content from an Ajax source, in this case, a function.
      // This method is executed only at the display and for interactions with the datatable. Not automatically every certain time.
      // dataTablesParameters contains current values of the datatable.
      ajax: (dataTablesParameters: any, callback) => {
        let order = '';
        if (dataTablesParameters.order.length) {
          order +=
            dataTablesParameters.columns[dataTablesParameters.order[0].column]
              .data;
          if (dataTablesParameters.order[0].dir === 'asc') {
            order = '+' + order;
          } else {
            order = '-' + order;
          }
        }

        let params: any = {
          search: dataTablesParameters.search.value,
          include: this.settings.include || [],
          limit: dataTablesParameters.length,
          page: +(dataTablesParameters.start / dataTablesParameters.length) + 1,
          sort: order
        };

        params = {
          ...this.removeEmptyFields(params),
          ...this.settings.filter
        };

        this.request$ = this.http
          .get(`${environment.apiUrl}/${this.settings.section}`, {
            params: params
          })
          .subscribe((res: any) => {
            if (!res.docs || res.docs.length === 0) {
              if (res.page !== 1) {
                this.redrawTable();
              } else {
                callback({
                  recordsTotal: res.total,
                  recordsFiltered: res.total,
                  data: []
                });
              }
            } else {
              this.data = res.docs;
              if (this.settings.updateLiveData) {
                // If any change is detected by the datatable, the observable will execute this method.
                this.updatedItem$.subscribe(item => {
                  callback({
                    recordsTotal: res.total,
                    recordsFiltered: res.total,
                    data: this.settings.updateLiveData(this.data, item)
                  });
                });
              }
              if (this.settings.renderStatusColumns) {
                callback({
                  recordsTotal: res.total,
                  recordsFiltered: res.total,
                  data: this.settings.renderStatusColumns(this.data)
                });
              } else {
                callback({
                  recordsTotal: res.total,
                  recordsFiltered: res.total,
                  data: this.data
                });
              }
              if (this.settings.instance) {
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                  this.datatableComponent.emit(dtInstance);
                });
              }
            }
          });
        return this.request$;
      },
      // Allows you to define details about the way individual columns behave.
      columns: columns,
      // Allows you to 'post process' each row after it have been generated for each table draw,
      // but before it is rendered into the document.
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        // console.log(row, data);

        const self = this;
        // console.log('columns', this.settings.columns);

        if (this.settings.rowCallback) {
          row = this.settings.rowCallback(row, data, index);
        }
        // We should try to avoid jquery and just use angular.
        $('td .btn-edit', row).off('click');
        $('td .btn-remove', row).off('click');
        $('td .btn-show', row).off('click');
        $('td .btn-unlink', row).off('click');

        $('td .btn-edit', row).on('click', () => {
          self.editClickHandler(data);
        });
        $('td .btn-remove', row).on('click', () => {
          self.removeClickHandler(data);
        });

        $('td .btn-show', row).on('click', () => {
          self.showClickHandler(data);
        });
        $('td .btn-unlink', row).on('click', () => {
          self.unlinkClickHandler(data);
        });
        $('td .btn-key', row).on('click', () => {
          self.keyClickHandler(data);
        });
        $('td .btn-power', row).on('click', () => {
          self.powerClickHandler(data);
        });

        return row;
      },
      preDrawCallback: () => {
        $(
          'div.paging_full_numbers > ul.pagination > li.paginate_button > a:focus'
        ).blur();
      }
    };
  }

  getButtons(translations: any) {
    this.removeActionHtml = `
    <button class='btn-remove btn btn-action btn-xs btn-danger' tooltip=${
      translations.buttons.delete
      }  tooltip-position=”top”>
      <i class='fas fa-trash'></i>
    </button>`;
    this.unlinkActionHtml = `
    <button class='btn-unlink btn btn-action btn-xs' tooltip=${
      translations.buttons.unlink
      }  tooltip-position=”top”>
      <i class='fas fa-unlink'></i>
    </button>`;
    this.editActionHtml = `
    <button class='btn-edit btn btn-action btn-xs btn-primary' tooltip=${
      translations.buttons.edit
      }  tooltip-position=”top”>
      <i class='fas fa-edit'></i>
    </button>`;
    this.showActionHtml = `
    <button class='btn-show btn btn-action btn-xs' tooltip=${
      translations.buttons.show
      }  tooltip-position=”top”>
        <i class='fa fa-eye'></i>
    </button>`;
  }
  /**
   * On any of the component @input references changes.
   * @param {SimpleChanges} changes Used to get new and previous values of input properties.
   */
  ngOnChanges(changes: SimpleChanges) {
    // If there are changes in liveData.
    if (changes.liveData) {
      this.itemUpdated = changes.liveData.currentValue;
      // We emit the changes of liveData.
      this.updatedItem$.next(this.itemUpdated);
    }
    // If there are changes in reload.
    if (changes.reload) {
      if (changes.reload.currentValue) {
        this.reloadTable();
      }
    }
  }

  /**
   * Lifecycle hook that is called after a component's view has been fully initialized.
   */
  ngAfterViewInit(): void {
    // Renders the table.
    this.dtTrigger.next();
  }

  /**
   * Handles the click event for the unlink button.
   * @param info Information needed for the unlink action.
   */
  unlinkClickHandler(info: any): void {
    this.datatableAction.emit({
      action: 'unlink',
      data: info
    });
  }

  /**
   * Handles the click event for the remove button.
   * @param info Information needed for the remove action.
   */
  removeClickHandler(info: any): void {
    this.datatableAction.emit({
      action: 'remove',
      data: info
    });
  }

  /**
   * Handles the click event for the edit button.
   * @param info Information needed for the edit action.
   */
  editClickHandler(info: any): void {
    this.datatableAction.emit({
      action: 'edit',
      data: info
    });
  }

  /**
   * Handles the click event for the show button.
   * @param info Information needed for the show action.
   */
  showClickHandler(info: any): void {
    this.datatableAction.emit({
      action: 'show',
      data: info
    });
  }
  /**
   * Handles the click event for the show button.
   * @param info Information needed for the show action.
   */
  keyClickHandler(info: any): void {
    this.datatableAction.emit({
      action: 'key',
      data: info
    });
  }

  powerClickHandler(info: any): void {
    this.datatableAction.emit({
      action: 'power',
      data: info
    });
  }

  /**
   * Reloads the datatable with the latest data.
   */
  private async reloadTable() {
    const dtInstance = await this.dtElement.dtInstance;
    // Ajax request to the already defined URL.
    dtInstance.ajax.reload(null, false);
  }

  private async redrawTable() {
    const dtInstance = await this.dtElement.dtInstance;
    dtInstance.draw();
  }

  /**
   * Returns an array with the datatables columns and some of their options.
   * @param {TranslateService} t Service to make translations
   */
  getColumns(t: TranslateService) {
    let defaultContent: any = '';
    const columns = [...this.settings.columns];

    if (!this.settings.no_id) {
      columns.push({
        title: '#ID',
        data: '_id',
        visible: false,
        searchable: false
      });
    }

    if (this.settings.action) {
      let actionColumWidth = 90;
      if (this.settings.action.length) {
        actionColumWidth = this.settings.action.length * 30;
        for (let i = 0; i < this.settings.action.length; i++) {
          const action = this.settings.action[i];
          switch (action) {
            case 'show':
              defaultContent += this.showActionHtml;
              break;
            case 'edit':
              defaultContent += this.editActionHtml;
              break;
            case 'remove':
              defaultContent += this.removeActionHtml;
              break;
            case 'unlink':
              defaultContent += this.unlinkActionHtml;
              break;
            default:
              if (
                this.settings.actionButtons &&
                this.settings.actionButtons[action]
              ) {
                defaultContent += this.settings.actionButtons[action];
              }
          }
        }
      }

      columns.push({
        title: t.instant('tables.actions'),
        width: actionColumWidth + 'px',
        targets: -1,
        searchable: false,
        data: null,
        defaultContent: `<td><div class='btn-group'>${defaultContent}</div></td>`,
        orderable: false
      });
    }

    return columns;
  }

  /**
   * Generic function to remove empty attributes from an object.
   * @param obj Object from which to remove empty fields.
   */
  removeEmptyFields(obj: any) {
    const newObj = {};
    Object.keys(obj).forEach(prop => {
      if (obj[prop]) {
        newObj[prop] = obj[prop];
      }
    });
    return newObj;
  }

  /**
   * Lifecycle hook that is called when a directive, pipe or service is destroyed.
   */
  ngOnDestroy() {
    if (this.updatedItem$) {
      this.updatedItem$.unsubscribe();
    }
    if (this.request$) {
      this.request$.unsubscribe();
    }
    if (this.interval$) {
      this.interval$.unsubscribe();
    }
    if (this.translate$) {
      this.translate$.unsubscribe();
    }
    if (this.dtTrigger) {
      this.dtTrigger.unsubscribe();
    }
  }
}
