import {
    Component, Input, ElementRef, AfterViewInit, OnInit, ViewChild, EventEmitter, Output
  } from '@angular/core';
  
  @Component({
    selector: 'dimmer-switch-canvas',
    template: '<canvas #canvas class="dimmer_progress" width="300" height="300" (mousemove)="onMouseMove($event)" (mousedown)="onMouseDown($event)" (mouseup)="onMouseUp($event)"></canvas>',
    styles: ['canvas { z-index: 20; position: absolute; left:0; }']
  })
  export class DimmerSwitchCanvasComponent implements OnInit, AfterViewInit {
    // a reference to the canvas element from our template
    @ViewChild('canvas', { static: true }) public canvas: ElementRef;
    @Input() endpoint: any;
    @Output() endpointChange: EventEmitter<any> = new EventEmitter();
  
    private steps = 4;
    private raf: null;
    private mdown: boolean = false;
  
    private mPos: { 
      x: number,
      y: number
    }
  
    public elementPosition: {
      x: number,
      y: number
    }
    public data: any;
    

    private target: number = 0;
    private radius: number = 150;
    private maxDiff: number = 150;
    private constraint: number = 360;
    public maxAngle: number = 255;

    private centerX;
    private centerY;

    private cx: CanvasRenderingContext2D;
    private canvasEl: HTMLCanvasElement;
    private $progress;
    private $context;
    private canvasSize;

    public ngOnInit(){
      this.data = { ...this.endpoint };
      this.target = this.data.current;
    }

    public ngAfterViewInit() {
      // get the context
      this.canvasEl = this.canvas.nativeElement;
      this.$context = this.canvasEl.closest('.dimmer');
 
      const knob = this.$context .getElementsByClassName('knob')[0];
      const handle = this.$context .getElementsByClassName('handle')[0];
      this.$progress = this.$context .getElementsByClassName('dimmer_progress')[0];
      const center = this.$context .getElementsByClassName('center')[0];
      const textOutput = center.getElementsByTagName('span')[0];

      const knobOffset = knob.getBoundingClientRect();
  
      this.elementPosition = {
        x: knobOffset.left,
        y: knobOffset.top
      };

      this.centerX = this.$progress.clientWidth/2;
      this.centerY = this.$progress.clientHeight/2;

      this.canvasSize = this.$progress.clientWidth;

      this.cx = this.canvasEl.getContext('2d');

      this.drawLine((this.target*Math.PI)/180);
      this.draw();
    }
  
    setDimmerPosition() {
      this.draw();
    }
  
    drawLine(endAngle) {
      this.cx.save();
      this.cx.translate(this.centerX, this.centerY);
      this.cx.rotate(145 * (Math.PI/180));

      let startAngle = 0;
      let radius =  93;
      let x = 0;
      let y = 0;

      this.cx.moveTo(98, 0);

      this.cx.beginPath();
      this.cx.shadowBlur = 10;
      this.cx.lineWidth = 2.4;
      this.cx.strokeStyle = "#fffdcf";
      this.cx.shadowBlur = 10;
      this.cx.shadowColor = "#fff";
      this.cx.arc(x, y, radius, startAngle, endAngle, false);
      this.cx.stroke();

      this.cx.beginPath();
      this.cx.strokeStyle = "#7f7f7f";
      this.cx.shadowBlur = 0;
      this.cx.arc(x, y, radius, endAngle, (this.maxAngle * Math.PI) / 180, false);
      this.cx.stroke();

      return this.cx.restore();
    } 

    drawSteps(){
      this.cx.save();
      this.cx.translate(this.centerX, this.centerY);
      this.cx.rotate((135*Math.PI)/180);

      for (let i = 0; i <= this.steps; i += 1) {
        this.cx.beginPath();
        this.cx.rotate((180*Math.PI)/180 / this.steps);

        this.cx.strokeStyle = "#7f7f7f";
        this.cx.lineWidth = 2;

        this.cx.lineTo(108, 0);
        this.cx.lineTo(100, 0);
        this.cx.stroke();
      }

      this.cx.restore();
    }

    drawNumbers() {
      let angle = (180*(Math.PI/180));
      let step = (180*Math.PI)/180 / this.steps;
      let radius = 116;
  
      this.cx.save();
      this.cx.translate(this.centerX, this.centerY);
  
      for (let i = 0; i <= this.steps; i += 1) {
        let x = (radius * Math.cos(angle))-4;
        let y = (radius * Math.sin(angle))+4;
        angle += step;
  
        this.cx.fillStyle = "#7f7f7f";
        this.cx.font = "bold 13px Arial";
        this.cx.fillText(''+(i+1), x, y);
      }

      this.cx.fillStyle = "#636262";
      this.cx.font = "normal 12px Arial";
      this.cx.fillText("OFF", -84, 75);
      this.cx.fillText("MAX", 62, 75);

      this.cx.restore();
    }

    draw() {
      this.$progress.height = this.canvasSize;
      this.$progress.width = this.canvasSize;

      this.drawLine((this.target * Math.PI) / 180);
      this.drawSteps();
      this.drawNumbers();
    }

    onMouseDown(event: MouseEvent) {
      this.mdown = true;
    }
  
    onMouseUp(event: MouseEvent) {
      this.mdown = false; 

      console.log(this.target);
      this.data.current = this.target;
      this.endpointChange.emit(Math.trunc(this.target));
    }

    onMouseMove(event: MouseEvent) {
      if (this.mdown) {
        this.setMousePosition(event);
      }
    }

    setMousePosition(event: MouseEvent) {
      var ang, deg, diff, target;
      this.mPos = {
        x: event.pageX - (this.elementPosition.x + this.centerX),
        y: event.pageY - (this.elementPosition.y + this.centerY)
      };
      ang = Math.atan2(this.mPos.x, this.mPos.y);
      if (ang < 0) {
        ang = ang + 2 * Math.PI;
      }
      deg = 360 - (ang * (180 / Math.PI));
      target = this.map(deg, 0, 360, -40, 270);
      diff = Math.abs(target - this.target);
      if (diff < this.maxDiff && target < this.constraint) {
        this.target = target;
        if (this.target > this.maxAngle) {
          this.target = this.maxAngle;
        }
        if (this.target < 0) {
          this.target = 0;
        }
      }
      this.setDimmerPosition();
    }
 
    map(value, low1, high1, low2, high2) {
      return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
    }
  }
