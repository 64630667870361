import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable()
export class UserService {
  public constructor(private http: HttpClient) {}

  public create(data: FormData) {
    return this.http.post(`${environment.apiUrl}/users`, data);
  }

  public usersConnected(params: any = {}) {
    return this.http.get(`${environment.apiUrl}/users/connected`, {
      params: params
    });
  }

  public update(id: string, data: FormData) {
    return this.http.put(`${environment.apiUrl}/users/${id}`, data);
  }

  public delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/users/${id}`);
  }

  public show(id: string) {
    return this.http.get(`${environment.apiUrl}/users/${id}`);
  }

  public showAll(params: any = {}) {
    return this.http.get(`${environment.apiUrl}/users`, { params: params });
  }

  public register(data = {}) {
    return this.http.post(`${environment.apiUrl}/users/register`, data);
  }
}
