<div *ngIf="!status" style="width: 100%; height: 100%; position: absolute; z-index: 1;"></div>
<div *ngIf="endpointsFan" class="text-center p-md">
  <div class="row">
    <h4>{{ 'fanSpeed' | translate }}</h4>
    <div class="col-12">
      <div class="btn-group">
        <button [class.btn-primary]="endpointsFan[0].current === 'On'" class="btn btn-lg"
          [class.btn-default]="endpointsFan[0].current === 'Off'"
          (click)="onChangeFanSpeed(endpointsFan[0])">Low
        </button>
        <button [class.btn-primary]="endpointsFan[1].current === 'On'" class="btn btn-lg"
          [class.btn-default]="endpointsFan[1].current === 'Off'"
          (click)="onChangeFanSpeed(endpointsFan[1])">Medium
        </button>
        <button [class.btn-primary]="endpointsFan[2].current === 'On'" class="btn btn-lg"
          [class.btn-default]="endpointsFan[2].current === 'Off'"
          (click)="onChangeFanSpeed(endpointsFan[2])">High
        </button>
      </div>
    </div>
  </div>
  <br />
</div>
<div *ngFor="let endpoint of endpoints">
  <div [ngSwitch]="true" *ngIf="endpoint.assigned_id">
    <div *ngSwitchCase="
      endpoint.assigned_id === TYPE_ENDPOINTS.LIGHT ||
      endpoint.assigned_id === TYPE_ENDPOINTS.HEATER ||
      endpoint.assigned_id === TYPE_ENDPOINTS.TEMPERATURE_RELAY">
      <app-switch-actuator [node]="node" [endpoint]="endpoint" (endpointChange)="onChange($event)" [status]="status">
      </app-switch-actuator>
    </div>
    <div *ngSwitchCase="endpoint.assigned_id === TYPE_ENDPOINTS.TEMPERATURE_DISPLAY_TARGETTEMPERATURE">
      <app-temperature-actuator [node]="node" [endpoint]="endpoint" (endpointChange)="onChange($event)">
      </app-temperature-actuator>
    </div>
  </div>
  <div *ngIf="!endpoint.assigned_id && isSwitch(endpoint.id)">
    <app-switch-actuator [node]="node" [endpoint]="endpoint" (endpointChange)="onChange($event)" [status]="status">
    </app-switch-actuator>
  </div>
</div>
<div *ngIf="endpointsBlinds" class="text-center p-md">
  <app-actuator-blind (setAction)="updateEndpoint($event)" [endpoints]="endpointsBlinds" [device]="device"
    [status]="status" [times]="blind_times"></app-actuator-blind>
</div>