import { User } from './../../../shared/models/user.model';
import { AuthService } from './../../../shared/services/auth.service';
import { Subscription } from 'rxjs';
import { SocketService } from './../../../socket.service';
import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'app/shared/services/settings.service';
import { Global } from 'app/shared/global';
import { Notification } from 'app/shared/models/notification.model';
import { UserService } from 'app/shared/services/user.service';
import { Constants } from 'app/shared/constants';
import { GroupService } from 'app/shared/services/group.service';
import { Group } from 'app/shared/models/group.model';

@Component({
  selector: 'app-basic-layout',
  templateUrl: './basic-layout.component.html',
  styleUrls: ['./basic-layout.component.scss']
})
export class BasicLayoutComponent implements OnInit, OnDestroy {
  private userGroups: any = {};
  public settings: any = {};
  /**
   * Subscription to the web socket event actuation:updated.
   */
  private ioConnectionNotification$: Subscription;
  private ioConnectionTest$: Subscription;
  private ioError$: Subscription;
  public modalRef: BsModalRef;
  private currentUser: User;
  private device$: Subscription;
  private user$: Subscription;

  /**
   * Template to alert the user.
   */
  @ViewChild('notification', { static: true }) private notification: TemplateRef<any>;

  public constructor(
    private modalService: BsModalService,
    private socketService: SocketService,
    private auth: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private settingsService: SettingsService,
    private userService: UserService,
    private groupService: GroupService
  ) { }

  public ngOnInit() {
    this.currentUser = this.auth.getAuthUser();
    if (this.currentUser.is_super_admin) {
      Global.initializeGlobal(this.settingsService);
    }
    const language = sessionStorage.getItem('session_lang');
    if (language) {
      this.translate.use(language);
    }
    this.groupService
      .showAll({ name: Constants.GROUPS })
      .subscribe((groups: any) => {
        groups.docs.forEach((group: Group) => {
          this.userGroups[group._id] = group.name;
        });
      });
    this.initSocketIO();
  }

  /**
   * Subscribes to socket events 'actuation:updated', 'device:created' y 'measure:created'.
   */
  private initSocketIO() {
    this.ioConnectionNotification$ = this.socketService
      .onNotificationCreated()
      .subscribe((notification: Notification) => {
        this.user$ = this.userService
          .show(this.currentUser._id)
          .subscribe((user: User) => {
            if (user.groups.notifications.indexOf(notification.group) !== -1) {
              this.openModal(
                this.notification,
                Constants.ICONS[this.userGroups[notification.group]]
              );
            }
          });
      });
    this.ioConnectionTest$ = this.socketService
      .onTest()
      .subscribe(message => this.toastr.info(message));
    this.ioError$ = this.socketService
      .onError()
      .subscribe((message: string) => {
        this.toastr.error(this.translate.instant('backend.' + message));
      });
  }

  private openModal(template: TemplateRef<any>, icon) {
    this.settings = {
      icon: icon
    };
    this.modalRef = this.modalService.show(template, { class: 'modal-full' });
  }

  public ngOnDestroy() {
    if (this.ioConnectionNotification$) {
      this.ioConnectionNotification$.unsubscribe();
    }
    if (this.ioConnectionTest$) {
      this.ioConnectionTest$.unsubscribe();
    }
    if (this.device$) {
      this.device$.unsubscribe();
    }
    if (this.ioError$) {
      this.ioError$.unsubscribe();
    }
    if (this.user$) {
      this.user$.unsubscribe();
    }
  }
}
