
import { Injectable } from '@angular/core';

import { NodeService } from './services/node.service';
import { Node } from './models/node.model';
import { ActuatorService } from './services/actuator.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import { Actuator } from './models/actuator.model';

@Injectable()
export class Helpers {
  /**
   * Build the component and initialize certain attributes.
   * @param {NodeService} nodeService Service to call the API methods related to nodes.
   * @param {ToastrService} toastr Service to show notifications to the user.
   * @param {AuthService} auth Service to get the logged user.
   * @param {TranslateService} translate Service to make translations.
   */
  public constructor(
    private actuatorService: ActuatorService,
    private nodeService: NodeService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) { }

  public updateNodeAndMakeActuation(item: Node, actuation: Actuator, successMessage: string) {
    return new Promise((resolve) => {
      this.nodeService.update(item._id, {
        room: item.room ? item.room : null,
        apartment: item.apartment ? item.apartment : null,
        building: item.building ? item.building : null,
        organization: item.organization ? item.organization : null,
        on_maintenance: item.on_maintenance,
        active: item.status,
        'scheme.endpoints': item.scheme.endpoints
      }).subscribe(() => {
        this.actuatorService.create(actuation).subscribe(res => {
          if (successMessage) {
            this.toastr.success(successMessage);
          }
          resolve(true);
        }, () => {
          this.toastr.error(this.translate.instant('endpoints.somethingHappened'));
          resolve(false);
        });
      }, () => this.toastr.error(this.translate.instant('nodes.somethingHappened')));
    });
  }

  public findEndpoint(node: Node, enpointID: string) {
    return new Promise((resolve) => {
      const index = node.scheme.endpoints.map(endpoint => endpoint.id).indexOf(enpointID);
      const endpointObj = node.scheme.endpoints.find(endpoint => endpoint.id === enpointID);

      if (!endpointObj) {
        console.warn(this.translate.instant('endpointNotFound') + ' ' + enpointID)
        resolve(false);
        return;
      }

      resolve({
        index: index,
        endpoint: endpointObj,
        actuation: {
          device: node._id,
          endpoint: endpointObj._id
        }
      });
    })
  }
}
