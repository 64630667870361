import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { LoginFormInterface } from './../../user/login/login-form.interface';
import { RegisterForm } from './../../user/login/register-form.class';
import { SocketService } from '../../socket.service';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';
import { Global } from '../global';

@Injectable()
export class AuthService {
  private currentToken: string;
  private currentUser: any;
  private tokenKey = 'localhost:token';
  private expired: any;

  public constructor(
    private http: HttpClient,
    private router: Router,
    private socketService: SocketService,
    private userService: UserService
  ) {}

  public doLogin(data: LoginFormInterface) {
    return this.http
      .post(`${environment.apiUrl}/users/login`, {
        email: data.username,
        password: data.password
      })
      .pipe(map(res => {
        return this.setSession(res);
      }));
  }

  public doRegister(data: RegisterForm) {
    return this.userService.register({
      name: data.nameRegister,
      surname: data.surnameRegister,
      email: data.emailRegister,
      language: data.languageRegister,
      password: data.passwordRegister,
      passwordConfirm: data.passwordConfirm
    });
  }

  private setSession(res: any) {
    // res.expires_at
    const expiresAt = moment().add(1, 'day');
    if (res.success) {
      const user = res.user;
      this.setSessionUser(user);
      localStorage.setItem(this.tokenKey, res.token);
      localStorage.setItem(
        this.tokenKey + ':expires_at',
        JSON.stringify(expiresAt.valueOf())
      );
      this.currentUser = res.user;
      this.expired = expiresAt;
      this.currentToken = res.token;
      return res;
    }
    return res;
  }

  private setSessionUser(user: any) {
    if (!user) {
      return;
    }

    if (user.organization) {
      const organization = user.organization;

      user.organization = organization._id;
      user.organizationObj = organization;
    }

    delete user.password;
    delete user.created_at;
    delete user.active;
    localStorage.setItem(this.tokenKey + ':user', JSON.stringify(user));
  }

  public logout() {
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem(this.tokenKey + ':expires_at');
    localStorage.removeItem(this.tokenKey + ':user');
    this.currentUser = null;
    this.currentToken = null;
    this.expired = null;
    this.router.navigate(['/login']);
    Global.stopNotificationTimer();
    this.socketService.disconnectSocket();
  }

  private getExpiration() {
    if (!this.expired) {
      const expiration = localStorage.getItem(this.tokenKey + ':expires_at');
      this.expired = JSON.parse(expiration);
    }
    if (!this.expired && this.currentUser) {
      this.logout();
      return;
    }
    return moment(this.expired);
  }

  public getAuthToken() {
    if (!this.currentToken) {
      this.currentToken = localStorage.getItem(this.tokenKey) || null;
    }
    if (!this.currentToken) {
      return;
    }
    return this.currentToken;
  }

  public getAuthUser() {
    try {
      if (!this.currentUser) {
        this.currentUser =
          JSON.parse(localStorage.getItem(this.tokenKey + ':user')) || null;
      }
      if (!this.currentUser) {
        this.logout();
        return;
      }
      return this.currentUser;
    } catch (e) {
      this.logout();
      return;
    }
  }

  public isLogged() {
    return moment().isBefore(this.getExpiration());
  }
}
