import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Constants } from '../constants';

@Pipe({
  name: 'GetAssignedID'
})
// tslint:disable-next-line:class-name
export class GetAssignedID implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer
  ) { }

  transform(
    endpoint: any,
    node: any
  ): SafeHtml {
    let html = '';
    try {
      switch (true) {
        case Constants.TYPE_ENDPOINTS.RELAY_0 === endpoint.id:
        case Constants.TYPE_ENDPOINTS.RELAY_1 === endpoint.id:
        case Constants.TYPE_ENDPOINTS.RELAY_2 === endpoint.id:
          if (node.scheme.code === Constants.TYPE_NODE.SMART_CLIMATE) {
            html = '<span class="badge">Climate</span>';
            break;
          }
        // tslint:disable-next-line:no-switch-case-fall-through
        case Constants.TYPE_ENDPOINTS.RELAY_X.test(endpoint.id) || Constants.TYPE_ENDPOINTS.POWER_X.test(endpoint.id) || Constants.TYPE_ENDPOINTS.POWERX.test(endpoint.id):
          switch (endpoint.assigned_id) {
            case 'light_relay':
              html = '<span class="badge">Light</span>';
              break;
            case 'blind_relay_0':
              html = '<span class="badge">Blind - Down</span>';
              break;
            case 'blind_relay_1':
              html = '<span class="badge">Blind - Up</span>';
              break;
            case 'heater_relay':
              html = '<span class="badge">Heater</span>';
              break;
            case 'temp_relay':
              html = '<span class="badge">Temperature</span>';
              break;
            case 'temp_display_targettemperature':
              html = '<span class="badge">Display Temperature</span>';
              break;
          }
      }
    } catch (e) {
      console.error('No assigned ID value', e);
    }

    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
