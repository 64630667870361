import {
  Input,
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { SocketService } from '../../../../socket.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-fan-actuator',
  templateUrl: './fan-actuator.component.html',
  styleUrls: ['./fan-actuator.component.scss']
})
export class FanActuatorComponent implements OnInit, OnDestroy {
  @Output() endpointChange: EventEmitter<any> = new EventEmitter();
  @Input() endpoint: any;
  /**
   * Current node, parent of the endpoint.
   */
  @Input() node: any;
  @Input() public status: boolean;

  public data: any;
  private ioConnectionActuation$: Subscription;

  public constructor(private socketService: SocketService) { }

  public ngOnInit() {
    this.data = { ...this.endpoint };
    if (!this.data.current) {
      this.data.current = 'Off';
    }

    this.ioConnectionActuation$ = this.socketService
      .onActuationUpdated()
      .subscribe((measure: any) => {
        this.updateMeasure(measure);
      });
  }

  updateMeasure(measure: any) {
    if (measure.device !== this.node._id || measure.endpoint !== this.data._id) {
      return;
    }
    this.data.current = measure.value;
  }

  onChangeEndpoint(value: any) {
    this.data.current = value;
    this.endpointChange.emit({
      updated: true,
      _id: this.data._id,
      value: this.data.current
    });
  }

  public ngOnDestroy() {
    if (this.ioConnectionActuation$) {
      this.ioConnectionActuation$.unsubscribe();
    }
  }
}
