import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

/**
 * Component used to represent a switch for certain endpoints.
 */
@Component({
  selector: 'app-switch-box',
  templateUrl: './switch-box.component.html',
  styleUrls: ['./switch-box.component.scss']
})
export class SwitchBoxComponent {
  /**
   * Indicates whether the switch is turned on or off.
   */
  @Input() checked = false;
  /**
   * Indicates whether the switch is disabled or not.
   */
  @Input() disabled = false;
  /**
   * Emits an event with the switch status every time it is pressed.
   */
  @Output() switchStatus: EventEmitter<any> = new EventEmitter();

  /**
   * Builds the component.
   */
  constructor() {}

  /**
   * Handler for the switch click event.
   * @param e Event.
   */
  clickHandler(e) {
    this.switchStatus.emit(this.checked);
  }
}
