import { Injectable } from '@angular/core';
import { Router, CanLoad } from '@angular/router';
import { AuthService } from './../services/auth.service';
import { SocketService } from './../../socket.service';

@Injectable()
export class AuthGuardService implements CanLoad {

  public constructor(
    private authService: AuthService,
    private router: Router,
    private socketService: SocketService
  ) { }

  public canLoad(): boolean {
    if (!this.authService.isLogged()) {
      this.router.navigate(['/login']);
      return false;
    }
    this.socketService.initSocket();
    return true;
  }
}
