import { Injectable } from '@angular/core';
import { Router, CanLoad } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from './../services/auth.service';
import { LoginFormInterface } from './../../user/login/login-form.interface';
import { map } from 'rxjs/operators';

@Injectable()
export class MobileGuardService implements CanLoad {

  public constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  public canLoad() {
    const data = <LoginFormInterface>{};
    data.username = 'qr@gozmart.ch';
    data.password = 'pass';
    return this.authService.doLogin(data).pipe(map((res: any) => {
      if (res.success) {
        return true;
      }
      this.router.navigate(['/login']);
      return false;
    }));
  }
}
