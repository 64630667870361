import { Roles } from './../models/permission.model';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class RolesGuardService implements CanActivateChild {

  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  canActivateChild(route: ActivatedRouteSnapshot) {
    let activate = false;
    const currentUser = this.auth.getAuthUser();
    if (currentUser.is_super_admin) {
      activate = true;
    } else {
      if (route.data.roles) {
        const routeRoles = route.data.roles;
        let index;
        for (index = 0; index < routeRoles.length; index++) {
          // tslint:disable-next-line:no-shadowed-variable
          const element = routeRoles[index];
          if ((element === Roles.ADMIN && currentUser.is_admin) ||
            (element === Roles.USER && !currentUser.is_admin)) {
            activate = true;
            break;
          }
        }
      }
    }

    if (activate) {
      return true;
    }
    this.router.navigate(['dashboard']);
    return false;
  }
}
