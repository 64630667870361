import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Room } from './../models/room.model';
import { environment } from '../../../environments/environment';

/**
 * Service to use the API rooms related calls.
 */
@Injectable()
export class RoomService {
  /**
   * Build the component and initialize certain attributes.
   * @param {HttpClient} http Service to make API calls.
   */
  constructor(private http: HttpClient) {}

  /**
   * Sends a room to store it in the backend.
   * @param {Room} data Room to be stored.
   */
  create(data: Room) {
    return this.http.post(`${environment.apiUrl}/rooms`, data);
  }
  /**
   * Updates a room in the backend.
   * @param {string} id Room ID.
   * @param {Room} data Room to be updated.
   */
  update(id: string, data: Room) {
    return this.http.put(`${environment.apiUrl}/rooms/${id}`, data);
  }
  /**
   * Deletes a room in the backend.
   * @param {string} id Room ID.
   */
  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/rooms/${id}`);
  }
  /**
   * Gets one Room from the backend.
   * @param {string} id Room ID.
   */
  show(id: string) {
    return this.http.get(`${environment.apiUrl}/rooms/${id}`);
  }
  /**
   * Gets Rooms filtering by certain parameters.
   * @param params Parameters to filter the query.
   */
  showAll(params: any = {}) {
    return this.http.get(`${environment.apiUrl}/rooms`, { params: params });
  }
}
