import { SocketService } from 'app/socket.service';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  OnChanges
} from '@angular/core';
import { Node } from '../../shared/models/node.model';
import { Constants } from '../../shared/constants';
import { ActuatorService } from '../../shared/services/actuator.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription, timer } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'underscore';
import { Global } from 'app/shared/global';
import { AuthService } from 'app/shared/services/auth.service';
import { User } from 'app/shared/models/user.model';
import { IconDefinition, faMinus, faPlay } from '@fortawesome/free-solid-svg-icons';

/**
 * Component to represent the node endpoint regulation.
 */
@Component({
  selector: 'app-node-actuator',
  templateUrl: './node-actuator.component.html',
  styleUrls: ['./node-actuator.component.scss']
})
export class NodeActuatorComponent implements OnInit, OnDestroy, OnChanges {
  /**
   * Contains the node ID.
   */
  public device: string;
  /**
   * All endpoints whose dirs aren't input.
   */
  public endpoints: any[];
  /**
   * All endpoints whose dirs are input.
   */
  public transducers: any[];
  /**
   * List with the types of nodes list.
   */
  public TYPE_NODE: any = Constants.TYPE_NODE;
  /**
   * Complete and blade times for blinds.
   */
  public blind_times: any;

  private action$: Subscription;
  private ioConnectionUpdate$: Subscription;
  private timer$: Subscription;

  /**
   * Indicates whether the actuator panel should display the node and scheme names.
   */
  @Input()
  name: boolean;
  /**
   * Current node.
   */
  @Input()
  data: Node;

  @Input()
  reload: String;
  /**
   * Indicates whether the actuator is available or not.
   */
  public status: Boolean;
  /**
   * Indicates whether the actuator is connected or not.
   */
  public connected: Boolean;
  public reloadComponent: any;
  public user: User;
  public faMinus: IconDefinition = faMinus;
  public faPlay: IconDefinition = faPlay;

  /**
   * Build the component and initialize certain attributes.
   * @param {ToastrService} toastr Service to show notifications to the user.
   * @param {ActuatorService} actuatorService Service to call the API methods related to actuators.
   */
  constructor(
    private toastr: ToastrService,
    private actuatorService: ActuatorService,
    private socketService: SocketService,
    private auth: AuthService
  ) { }

  /**
   * Initialize transducers, endpoints and device.
   */
  public ngOnInit() {
    console.log('NODE::::', this.data);
    this.user = this.auth.getAuthUser();
    this.transducers = this.data.scheme.endpoints.filter(
      item => item.dir === 'input'
    );
    this.endpoints = this.data.scheme.endpoints.filter(
      item => item.dir !== 'input'
    );
    this.device = this.data._id;
    this.blind_times = this.data.extra_attributes;
    const code = this.data.scheme.code;
    if (code.substring(0, 2) === '31')
    {
      this.connected = true;
      this.status = true && !(this.data.blocked && (this.user.is_super_admin || this.user.is_admin));
    }
    else
    {
      this.connected = this.data.active;
      this.status = this.data.active && !(this.data.blocked && (this.user.is_super_admin || this.user.is_admin));
    }
    this.ioConnectionUpdate$ = this.socketService
      .onMeasureCreated()
      .subscribe((measure: any) => {
        if (this.data._id === measure.device && !this.connected && !measure.api)
        {
          this.connected = true;
          this.status = !(this.data.blocked && (this.user.is_super_admin || this.user.is_admin));
        }
      });

    if (!this.data.periodicity)
    {
      this.data.periodicity = Global.settings['default-periodicity'];
    }
    this.timer$ = timer(0, this.data.periodicity * 2).subscribe(
      () => {
        const periodicity = this.data.periodicity;
        const timestampDevice = moment(new Date(this.data.last_update));
        const currentTimestamp = moment(new Date());
        if (code.substring(0, 2) === '31' || code.substring(0, 2) === '10')
        {
          this.connected = true;
          this.status = true && !(this.data.blocked && (this.user.is_super_admin || this.user.is_admin));
        }
        else
        {
          if (currentTimestamp.diff(timestampDevice, 'milliseconds') > periodicity)
          {
            this.connected = false;
            this.status = false && !(this.data.blocked && (this.user.is_super_admin || this.user.is_admin));
          }
          else
          {
            this.connected = true;
            this.status = true && !(this.data.blocked && (this.user.is_super_admin || this.user.is_admin));
          }
        }
      }
    );
  }

  /**
   * Creates a new actuation for the current node.
   * @param params Necessary data.
   */
  public updateEndpoint(params: any) {
    this.action$ = this.actuatorService.create(params).subscribe(
      res => {
        console.log('Updated', res);
        if (params.silent === undefined) {
          this.toastr.success('Endpoint updated');
        }
      },
      err => {
        console.error('Update error:::', err);
        this.toastr.error('Error when updated endpoint');
      }
    );
  }

  ngOnChanges() {
    this.reloadComponent = Date();
  }

  public ngOnDestroy() {
    if (this.action$) {
      this.action$.unsubscribe();
    }
    if (this.ioConnectionUpdate$) {
      this.ioConnectionUpdate$.unsubscribe();
    }
    if (this.timer$) {
      this.timer$.unsubscribe();
    }
  }
}
