import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Oauth2Component } from './oauth2.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ROUTES_OAUTH2 } from './oauth2.routes';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from 'app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptorService } from 'app/shared/services/request-interceptor.service';

@NgModule({
  declarations: [Oauth2Component],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    TranslateModule,
    RouterModule.forChild(ROUTES_OAUTH2)
  ],
  exports: [Oauth2Component],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true
    }
  ]
})
export class Oauth2Module { }
