import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/shared/services/auth.service';
import { LoginForm } from 'app/user/login/login-form.class';
import { environment } from 'environments/environment';
import { IconDefinition, faEye, faEyeSlash, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'app/shared/constants';
import { Subscription } from 'rxjs';
import { SocketService } from 'app/socket.service';
import { Global } from 'app/shared/global';
import { NodeService } from 'app/shared/services/node.service';
import { SettingsService } from 'app/shared/services/settings.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-oauth2',
  templateUrl: './oauth2.component.html',
  styleUrls: ['./oauth2.component.scss']
})
export class Oauth2Component implements OnInit, OnDestroy {

  private client_id: string;
  private redirect_uri: string;
  private response_type: string;
  private grant_type: string;
  private state: string;
  private scope: string;

  public queryError: boolean = false;
  public showLoginPage: boolean = false;
  public apiUrl = environment.baseUrl;
  public showPassword1 = false;
  public faEye: IconDefinition = faEye;
  public faEyeSlash: IconDefinition = faEyeSlash;
  public faGlobe: IconDefinition = faGlobe;
  public form: FormGroup;
  public languages: any = Constants.LANGUAGES;
  public language: string;
  public version: string;
  public error: any = {
    success: null,
    message: null
  };

  private login$: Subscription;

  public constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private socket: SocketService,
    private deviceService: NodeService,
    private settingsService: SettingsService,
    private toastr: ToastrService,
    private http: HttpClient
  ) { }

  public ngOnInit(): void {
    this.version = environment.buildingVersion;
    this.language = localStorage.getItem('login_lang');
    if (!this.language) {
      this.language = Constants.LANGUAGES.english;
    }
    this.translate.use(this.language);

    this.client_id = this.route.snapshot.queryParams.client_id;
    this.redirect_uri = this.route.snapshot.queryParams.redirect_uri;
    this.response_type = this.route.snapshot.queryParams.response_type;
    this.grant_type = this.route.snapshot.queryParams.grant_type || 'authorization_code';
    this.state = this.route.snapshot.queryParams.state;
    this.scope = this.route.snapshot.queryParams.scope;
    if (!this.scope) {
      this.scope = 'read';
    }

    if (!this.client_id || !this.redirect_uri || !this.response_type || !this.grant_type || !this.state) {
      this.queryError = true;
    }

    if (!this.auth.isLogged()) {
      const loginForm = new LoginForm();
      this.form = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required]
      });
      this.showLoginPage = true;
    }
  }

  public onLogin() {
    if (this.form.valid) {
      this.login$ = this.auth.doLogin({ username: this.form.value.username, password: this.form.value.password }).subscribe(
        () => {
          const currentUser = this.auth.getAuthUser();
          this.socket.initSocket();
          this.translate.setDefaultLang(Constants.LANGUAGES.english);
          if (currentUser.language) {
            this.translate.use(currentUser.language);
            sessionStorage.setItem('session_lang', currentUser.language);
          }
          Global.startNotificationTimer(
            this.translate,
            this.deviceService,
            this.settingsService,
            currentUser,
            this.toastr,
            true
          );

          this.showLoginPage = false;
        },
        res => {
          this.error.success = false;
          this.error.message = 'Username and password do not match';
        }
      );
    }
  }

  public onAuthorize() {
    this.http.post(`${environment.apiUrl}/oauth2/authorize`, {
      client_id: this.client_id,
      redirect_uri: this.redirect_uri,
      response_type: this.response_type,
      grant_type: this.grant_type,
      state: this.state,
      scope: this.scope
    }).subscribe(
      (res: any) => {
        window.location.href = res.redirectUri + `?code=${res.authorizationCode}`;
      },
      err => {
        this.error.success = false;
        this.error.message = 'Authorization failed';
      }
    )
  }

  public onChangeLanguage(language: string) {
    localStorage.setItem('login_lang', language);
    this.language = language;
    this.translate.use(this.language);
  }

  public onShowPassword1() {
    this.showPassword1 = !this.showPassword1;
  }

  public ngOnDestroy() {
    if (this.login$) {
      this.login$.unsubscribe();
    }
  }
}
