<!-- Wrapper-->
<div id="wrapper">
  <!-- Left navigation bar -->
  <app-navigation></app-navigation>

  <!-- Main page wrapper -->
  <div id="page-wrapper" class="gray-bg">

    <!-- Top navigation -->
    <app-topnavbar></app-topnavbar>

    <app-breadcrumbs></app-breadcrumbs>
    <!-- Main view/routes wrapper <router-outlet></router-outlet>-->
    <router-outlet></router-outlet>

    <!-- Footer -->
    <app-footer></app-footer>

  </div>
  <!-- End page wrapper-->

</div>
<!-- End wrapper-->

<ng-template #notification>
  <app-notification-modal [settings]="settings" (close)="modalRef.hide()"></app-notification-modal>
</ng-template>