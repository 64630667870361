import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { SocketService } from '../../../../socket.service';

/**
 * Component used to represent a dimmer.
 */
@Component({
  selector: 'app-dimmer-switch-actuator',
  templateUrl: './dimmer-switch-actuator.component.html',
  styleUrls: ['./dimmer-switch-actuator.component.scss']
})
export class DimmerSwitchActuatorComponent implements OnInit, OnDestroy {
  /**
   * Dimmer endpoint.
   */
  @Input() endpoint: any;
  /**
   * Current node, parent of the endpoint.
   */
  @Input() node: any;
  /**
   * Emit dimmer changes.
   */
  @Output() endpointChange: EventEmitter<any> = new EventEmitter();

  /**
   * Current endpoint. 
   */
  public data: any;
  /**
   * Suscription to the socket event related to the actuation updates.
   */
  private ioConnectionActuation$: any;

  /**
   * Builds the component and initializes the socket service.
   * @param {SocketService} socketService Service to subscribe to actuation updates event.
   */
  constructor(private socketService: SocketService) {}


  /**
   * Initializes the endpoint data and the socket connection.
   */
  ngOnInit() {
    this.data = { ...this.endpoint };
    
    //console.log('ENDPOINT DIMMER:::', this.data);

    // console.log('DATA LOADED', this.data);
    // this.ioConnectionActuation$ = this.socketService
    //   .onActuationUpdated()
    //   .subscribe((measure: any) => {
    //     console.log('ACTUATION RECEIVED:::> ', measure);
    //     this.updateMeasure(measure);
    //   });
  }

  /**
   * Updates the value of the dimmer after it is updated in the backend.
   * @param measure Value of the dimmers.
   */
  updateMeasure(measure: any) {
    if (measure.device !== this.node._id || measure.endpoint !== this.data._id) {
      return;
    }
    this.data.current = measure.value;
  }

  /**
   * Emits a change in the dimmer when the value is modified by the user.
   * @param index Number of the dimmer modified.
   * @param value New value for the dimmer.
   */
  onChangeDimmer(value) {
    this.data.current = value;
    console.log('dimmer', value);
    this.endpointChange.emit({
      updated: true,
      _id: this.data._id,
      value: this.data.current
    });
  }

  /**
   * Removes the connection to the socket.
   */
  ngOnDestroy() {
    if (this.ioConnectionActuation$) {
      this.ioConnectionActuation$.unsubscribe();
    }
  }
}
