<div *ngIf="!status" style="width: 100%; height: 100%; position: absolute; z-index: 1;"></div>
<div class="temperature-correction-actuator-container">
  <h4>{{ data.display_name || data.name }}</h4>
  <div>
    <div class="actuator-container">
      <button (click)="rest()" type="button" class="btn btn-danger btn-minus-temp">
        <span class="glyphicon glyphicon-minus"></span>
      </button>
      <input type="text" class="form-control input-temp" [value]="data.current" [min]="min" [max]="max">
      <button (click)="add()" type="button" class="btn btn-success btn-plus-temp">
        <span class="glyphicon glyphicon-plus"></span>
      </button>
    </div>
  </div>
</div>