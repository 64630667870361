import {LoginFormInterface} from './login-form.interface'; 

export class LoginForm implements LoginFormInterface{
    username: string;
    password: string;
    constructor(data:any = {}){
        this.username = data.username || '';
        this.password = data.password || '';
    }

}