<span *ngIf="group" [formGroup]="group">
    <span>
        <ng-select [items]="items" [addTag]="addTag" [virtualScroll]="true" [loading]="loading"
            [typeahead]="itemsTypeahead" [multiple]="true" [formControlName]="name" [hideSelected]="true"
            (scrollToEnd)="fetchMore($event)" (add)="onAdd($event)" bindLabel="name" class="input-tags"
            [ngClass]="positionClass">
            <ng-template ng-header-tmp>
                <small class="form-text text-muted">{{'Loaded'|translate}} {{items.length}} {{'of'|translate}}
                    {{totalItems}}</small>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <b>{{index +1}}</b> - {{item.name}}
            </ng-template>
        </ng-select>
    </span>
</span>