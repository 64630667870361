import {
  Component,
  OnInit,
  OnChanges,
  Output,
  Input,
  EventEmitter
} from '@angular/core';
import { Constants } from '../../../shared/constants';

/**
 * Component used to represent certain types of nodes.
 */
@Component({
  selector: 'app-node-actuator-custom',
  templateUrl: './node-actuator-custom.component.html',
  styleUrls: ['./node-actuator-custom.component.scss']
})
export class NodeActuatorCustomComponent implements OnInit, OnChanges {
  /**
   * Indicates whether the actuator is connected or not.
   */
  @Input() public status: boolean;
  /**
   * All endpoints whose dirs aren't input.
   */
  @Input() node: any;
  /**
   * All endpoints whose dirs aren't input.
   */
  @Input() endpoints: any;
  /**
   * Contains the node ID.
   */
  @Input() device: any;
  /**
   * Used to emit an object with updated data related to the actuation.
   */
  @Output() setAction: EventEmitter<any> = new EventEmitter();

  @Input() reload: String;
  /**
   * List with the types of endpoints list.
   */
  public TYPE_ENDPOINTS: any = Constants.TYPE_ENDPOINTS;

  public endpointsBlinds: any;
  public endpointsFan: any;
  private lightEndpointsPatt;

  /**
   * Builds the component.
   */
  public constructor() { }

  /**
   * Displays through the console, the node endpoints, ID and status.
   */
  public ngOnInit() {
    console.log('ENDPOINTS:::', this.endpoints);
    console.log('DEVICE:::', this.device, this.status);
    this.lightEndpointsPatt = /^relay_[3-9]$/;
    this.selectEndpoints();
  }

  public ngOnChanges() {
    this.selectEndpoints();
  }

  private selectEndpoints() {
    this.endpointsBlinds = null;
    this.endpointsFan = null;

    const endpointsBlinds = [];
    const endpointsFan = [];
    let hasDown = false;
    let hasUp = false;
    for (let i = 0; i < this.endpoints.length; i++) {
      if (
        this.endpoints[i].assigned_id === Constants.TYPE_ENDPOINTS.BLIND_DOWN &&
        hasDown === false
      ) {
        endpointsBlinds.push(this.endpoints[i]);
        hasDown = true;
      } else if (
        this.endpoints[i].assigned_id === Constants.TYPE_ENDPOINTS.BLIND_UP &&
        hasUp === false
      ) {
        endpointsBlinds.push(this.endpoints[i]);
        hasUp = true;
      } else if (Constants.TYPE_ENDPOINTS.RELAY_0 === this.endpoints[i].id ||
        Constants.TYPE_ENDPOINTS.RELAY_1 === this.endpoints[i].id ||
        Constants.TYPE_ENDPOINTS.RELAY_2 === this.endpoints[i].id) {
        endpointsFan.push(this.endpoints[i]);
      }
    }

    if (endpointsBlinds.length === 2) {
      this.endpointsBlinds = endpointsBlinds;
    } else {
      this.endpointsBlinds = null;
    }

    if (endpointsFan.length === 3) {
      this.endpointsFan = endpointsFan;
    } else {
      this.endpointsFan = null;
    }
  }

  /**
   * Emits an object with the value of the actuation when it has been changed.
   * @param data Information from the actuation.
   */
  public onChange(data: any) {
    if (data.updated) {
      const objectSave: any = {
        device: this.device,
        endpoint: data._id,
        value: data.value
      };
      console.log('NODE AUTO GUARDAR:::>', objectSave);
      this.setAction.emit(objectSave);
      this.selectEndpoints();
    } else {
      console.error('Endpoint no updated');
    }
  }

  public onChangeFanSpeed(endpoint: any) {
    this.onChange({
      updated: true,
      _id: endpoint._id,
      value: endpoint.current === 'Off' ? 'On' : 'Off'
    });
  }

  public isSwitch(endpoint: any) {
    return this.lightEndpointsPatt.test(endpoint);
  }
}
