<div class="ibox float-e-margins">
  <div class="ibox-title">
    <span *ngIf="connected" class="label label-success float-right">{{ 'connected' | translate }}</span>
    <span *ngIf="!connected" class="label label-danger float-right">{{ 'unconnected' | translate }}</span>
    <span *ngIf="data.blocked" class="label label-danger float-right">{{ 'blocked' | translate }}</span>
    <div [ngSwitch]="data.scheme?.code">
      <h4 *ngSwitchCase="TYPE_NODE.SENSOR">{{ 'sensors.' | translate }}</h4>
      <h4 *ngSwitchDefault>{{ 'Actuators' | translate }}</h4>
    </div>
  </div>
  <div class="ibox-content">
    <div class="row" *ngFor="let transducer of transducers">
      <div class="col-sm-11">{{ transducer?.display_name || transducer?.name }}</div>
      <div class="col-sm-1" [ngSwitch]="transducer?.trend">
        <span *ngSwitchCase="'equal'" class="text-warning">
          <fa-icon [icon]="faMinus"></fa-icon>
        </span>
        <span *ngSwitchCase="'rising'" class="text-navy">
          <fa-icon [icon]="faPlay" rotate="270"></fa-icon>
        </span>
        <span *ngSwitchCase="'falling'" class="text-danger">
          <fa-icon [icon]="faPlay" rotate="90"></fa-icon>
        </span>
      </div>
    </div>
    <div class="row position-relative" [ngSwitch]="true" *ngIf="endpoints">
      <div class="col-12 text-center p-md">
        <app-actuator-blind
          *ngSwitchCase="data.scheme?.code === TYPE_NODE.BLIND_PUSH || data.scheme?.code === TYPE_NODE.BLIND || data.scheme?.code === TYPE_NODE.SMART_BLIND || data.scheme?.code === TYPE_NODE.SMART_BLIND_W || data.scheme?.code === TYPE_NODE.SMART_BLIND_PUSH_WP2"
          (setAction)="updateEndpoint($event)" [endpoints]="endpoints" [device]="device" [status]="status"
          [times]="blind_times"></app-actuator-blind>
        <app-actuator-smartlight-switch *ngSwitchCase="data.scheme?.code === TYPE_NODE.SMARTLIGHT_SWITCH"
          (setAction)="updateEndpoint($event)" [endpoints]="endpoints" [device]="device" [status]="status">
        </app-actuator-smartlight-switch>
        <app-actuator-sensor *ngSwitchCase="data.scheme?.code === TYPE_NODE.SENSOR" (setAction)="updateEndpoint($event)"
          [endpoints]="endpoints" [device]="device" [status]="status"></app-actuator-sensor>
        <app-node-actuator-custom *ngSwitchCase="data.scheme?.code === TYPE_NODE.SMART_CLIMATE"
          (setAction)="updateEndpoint($event)" [reload]="reloadComponent" [node]="data" [endpoints]="endpoints"
          [device]="device" [status]="status"></app-node-actuator-custom>
        <app-actuator-smart-four *ngSwitchCase="data.scheme?.code === TYPE_NODE.SMART_FOUR_CHANNELS"
          (setAction)="updateEndpoint($event)" [endpoints]="endpoints" [device]="device" [status]="status">
        </app-actuator-smart-four>
        <app-actuator-speaker *ngSwitchCase="data.scheme?.code === TYPE_NODE.SMART_SPEAKER"
          (setAction)="updateEndpoint($event)" [device]="data" [status]="status">
        </app-actuator-speaker>
        <app-node-actuator-auto *ngSwitchDefault class="text-center p-md" (setAction)="updateEndpoint($event)"
          [node]="data" [endpoints]="endpoints" [device]="device" [status]="status" [connected]="connected">
        </app-node-actuator-auto>
      </div>
    </div>
  </div>
</div>