import { Component, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-sortable-list',
  templateUrl: './sortable-list.component.html',
  styleUrls: ['./sortable-list.component.scss']
})
export class SortableListComponent {

  @Input() public items: any[];

  public constructor() { }

  public drop(event: CdkDragDrop<string[]>) {
    console.warn(this.items);
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    console.warn(this.items);
  }
}
