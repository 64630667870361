import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Group } from './../models/group.model';
import { environment } from '../../../environments/environment';


/**
 * Service to use the API tags related calls.
 */
@Injectable()
export class GroupService {
  /**
   * Build the component and initialize certain attributes.
   * @param {HttpClient} http Service to make API calls.
   */
  constructor(private http: HttpClient) {}

  /**
   * Sends a tag to store it in the backend.
   * @param {Group} data Tag to be stored.
   */
  create(data: any) {
    return this.http.post(`${environment.apiUrl}/groups`, data);
  }
  /**
   * Updates a tag in the backend.
   * @param {string} id Tag ID.
   * @param {Group} data Tag to be updated.
   */
  update(id: string, data: Group) {
    return this.http.put(`${environment.apiUrl}/groups/${id}`, data);
  }
  /**
   * Deletes a tag in the backend.
   * @param {string} id Tag ID.
   */
  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/groups/${id}`);
  }
  /**
   * Gets one Tag from the backend.
   * @param {string} id Tag ID.
   */
  show(id: string) {
    return this.http.get(`${environment.apiUrl}/groups/${id}`);
  }
  /**
   * Gets Tags filtering by certain parameters.
   * @param params Parameters to filter the query.
   */
  showAll(params: any = {}) {
    return this.http.get(`${environment.apiUrl}/groups`, { params: params });
  }
}
