import {
  Input,
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { SocketService } from '../../../../socket.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'app/shared/services/auth.service';
import { User } from 'app/shared/models/user.model';

@Component({
  selector: 'app-temperature-correction-actuator',
  templateUrl: './temperature-correction-actuator.component.html',
  styleUrls: ['./temperature-correction-actuator.component.scss']
})
export class TemperatureCorrectionActuatorComponent implements OnInit, OnDestroy, OnChanges {

  @Output() endpointChange: EventEmitter<any> = new EventEmitter();
  /**
   * Current node, parent of the endpoint.
   */
  @Input() private node: any;
  @Input() private endpoint: any;
  @Input() private connected: boolean;
  public status: boolean = false;

  public min: number;
  public max: number;
  public data: any;
  private user: User;
  private ioConnectionActuation$: Subscription;

  public constructor(
    private socketService: SocketService,
    private auth: AuthService
  ) { }

  public ngOnInit() {
    this.data = { ...this.endpoint };
    this.min = this.data.value[0].min;
    this.max = this.data.value[0].max;
    if (!this.data.current) {
      this.data.current = 0;
    }

    this.ioConnectionActuation$ = this.socketService
      .onActuationUpdated()
      .subscribe((measure: any) => {
        this.updateMeasure(measure);
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!this.user) {
      this.user = this.auth.getAuthUser();
    }
    if (changes.connected) {
      this.status = changes.connected.currentValue && (!this.node.blocked || this.user.is_super_admin || this.user.is_admin);
    }
  }

  public add() {
    if (this.data.current < this.max) {
      const inc = this.data.current + 0.5;
      this.onChangeEndpoint(inc);
    }
  }

  public rest() {
    if (this.data.current > this.min) {
      const dec = this.data.current - 0.5;
      this.onChangeEndpoint(dec);
    }
  }

  private updateMeasure(measure: any) {
    if (measure.device !== this.node._id || measure.endpoint !== this.data._id) {
      return;
    }
    this.data.current = measure.value;
  }

  private onChangeEndpoint(value: any) {
    this.data.current = value;
    this.endpointChange.emit({
      updated: true,
      _id: this.data._id,
      value: this.data.current
    });
  }

  public ngOnDestroy() {
    if (this.ioConnectionActuation$) {
      this.ioConnectionActuation$.unsubscribe();
    }
  }
}
