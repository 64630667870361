import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '../constants';

@Pipe({
  name: 'sensorData'
})
export class SensorDataPipe implements PipeTransform {
  transform(value: any, subtype: any): any {

    const convertBase = num => {
      return {
          from : function (baseFrom) {
              return {
                  to : function (baseTo) {
                      return parseInt(num, baseFrom).toString(baseTo);
                  }
              };
          }
      };
    };

    const hexToBin = () => {
      return convertBase(value).from(16).to(2);
    }

    const bits = hexToBin();

    const binToDec = num => {
      return convertBase(num).from(2).to(10);
    };

    // Functions for retrieving data from every single sensor tipe
    const getUltrasonic = () => {
      if (bits.substr(6, 1) !== '0') {
        return binToDec(bits.substr(8, 16));
      }
      return 0;
    }

    const getParkingSensor = () => {
      const slot_status: any = binToDec(bits.substr(0, 1));
      const slot_free = 1 - slot_status;
      if (slot_free) {
        return 'Taken';
      } else {
        return 'Free';
      }
    }

    const getPersonCounter = () => {
      return binToDec(bits.substr(8, 16));
    }

    switch (subtype) {
      case Constants.TYPE_SENSORS.ULTRASONIC:
        return getUltrasonic();
      case Constants.TYPE_SENSORS.PARKING_SENSOR:
        return getParkingSensor();
      case Constants.TYPE_SENSORS.PERSON_COUNTER:
        return getPersonCounter();
      default:
        return value
    }
  }
}

// NO BORRAR, POSIBLE USO PARA NÚMEROS NEGATIVOS
// function hexToInt(hex) {
//   if (hex.length % 2 != 0) {
//       hex = "0" + hex;
//   }
//   var num = parseInt(hex, 16);
//   var maxVal = Math.pow(2, hex.length / 2 * 8);
//   if (num > maxVal / 2 - 1) {
//       num = num - maxVal
//   }
//   return num;
// }
