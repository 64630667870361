/// <reference types="@types/googlemaps" />
import { Component, OnInit, Output, Input, EventEmitter, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-map',
    template: `
  <div style="display:flex; flex-direction: column; padding-bottom: 10px">
    <div #gmap [style.width]="width" [style.height]="height"></div>
  </div>`
})
export class MapComponent implements OnInit {

    @Input() address: string;
    @Input() location: { lat: number, lng: number };
    @Input() width: string = "100%";
    @Input() height: string = "400px";
    @Input() draggable: boolean = true;
    @Output() coordinates: EventEmitter<{ lat: number, lng: number }> = new EventEmitter();

    @ViewChild('gmap', { static: true }) gmapElement: ElementRef;

    private marker: any;
    private map: google.maps.Map;
    private geocoder = new google.maps.Geocoder();

    constructor(
        private toastr: ToastrService,
        private translate: TranslateService
    ) { }

    ngOnInit() {
        this.initializeMap();
    }

    initializeMap() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position: any) => {
                this.map = new google.maps.Map(this.gmapElement.nativeElement, {
                    zoom: 5,
                    center: { lat: position.coords.latitude, lng: position.coords.longitude }
                });
                this.marker = new google.maps.Marker({
                    map: this.map,
                    position: { lat: position.coords.latitude, lng: position.coords.longitude },
                    draggable: this.draggable,
                });
                if (this.draggable) {
                    this.marker.addListener('dragend', (event: any) => this.dragEndMarker(event));
                }
            });
        } else {
            this.map = new google.maps.Map(this.gmapElement.nativeElement, {
                zoom: 3,
                center: { lat: 0, lng: 0 }
            });
            this.marker = new google.maps.Marker({
                map: this.map,
                position: { lat: 0, lng: 0 },
                draggable: this.draggable,
            });
            if (this.draggable) {
                this.marker.addListener('dragend', (event: any) => this.dragEndMarker(event));
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.location) {
            if (
                !_.isEqual(changes.location.currentValue, changes.location.previousValue)
            ) {
                //Pone el marker por location
                if (changes.location.isFirstChange) {
                    if (changes.location.currentValue) {
                        this.codeLocation(this.geocoder, changes.location.currentValue)
                    }
                }
            }
        }

        if (changes.address && this.draggable) {
            if (changes.address.currentValue !== changes.address.previousValue) {
                if (changes.address.currentValue !== "") {
                    this.codeAddress(this.geocoder, changes.address.currentValue)
                }
            }
        }
    }

    codeAddress(geocoder, address) {
        geocoder.geocode({ 'address': address }, (results, status) => {
            if (status === 'OK') {
                this.map.setCenter(results[0].geometry.location);
                this.marker.setPosition(results[0].geometry.location);
            } else {
                this.toastr.error(this.translate.instant('errors.maps') + ': ' + this.translate.instant('errors.mapLoad'));
            }
        });
    }

    codeLocation(geocoder, location) {
        geocoder.geocode({ 'location': location }, (results, status) => {
            if (status === 'OK') {
                this.map.setCenter(results[0].geometry.location);
                this.marker.setPosition(results[0].geometry.location);
            } else {
                this.toastr.error(this.translate.instant('errors.maps') + ': ' + this.translate.instant('errors.mapLoad'));
            }
        });
    }

    dragEndMarker(event: any) {
        var response = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
        }
        this.coordinates.emit(response);
    }

}