<div *ngIf="!status" style="width: 100%; height: 100%; position: absolute; z-index: 1;"></div>
<div class="outer-container">
  <h4>{{ data.display_name || data.name }}</h4>
  <div class="tempGraph-container" id="tempGraph-container-{{ endpoint._id }}">
    <div class="tempGraph" id="tempGraph-{{ endpoint._id }}">
      <input class="temp-input" type="range" [(ngModel)]="data.current" />
      <label *ngIf="actualTemperature && data.current >= actualTemperature.current" for="temp">heating</label>
      <label *ngIf="actualTemperature && data.current < actualTemperature.current" for="temp">cooling</label>
      <output for="temp">
        <p>{{ data.current }}</p>
      </output>
      <button id="decr" (mousedown)="onChangeDimmer(-0.5)" (mouseup)="onEndChangeDimmer()" style="--s:-1;">decrease
        temperature</button>
      <button id="incr" (pointerdown)="onChangeDimmer(0.5)" (pointerup)="onEndChangeDimmer()" style="--s:1;">increase
        temperature</button>
    </div>
  </div>
</div>