<div class="modal-header">
  <h3 class="modal-title">{{ title }}</h3>
</div>
<div class="modal-body">
  <p>{{ message }}</p>
</div>
<div class="modal-footer">
  <button (click)="sendConfirm()" class="btn btn-primary">
    <strong>{{ 'accept' | translate }}</strong>
  </button>
  <button (click)="sendCancel()" class="btn btn-white">
    <strong>{{ 'cancel' | translate }}</strong>
  </button>
</div>