import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { RoutingService } from './shared/services/routing.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private router: Router,
    private routingService: RoutingService
  ) {
    this.translate.setDefaultLang('en');
    if (environment.production) {
      window.console.log = function() {};
    }
    this.routingService.loadRouting();
  }

  ngOnInit() {
    if (window.location.pathname === '/') {
      this.router.navigate(['dashboard']);
    }
  }
}
