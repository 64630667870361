import { Constants } from "app/shared/constants";

export class RegisterForm {
    nameRegister: string;
    surnameRegister: string;
    languageRegister: string;
    emailRegister: string;
    passwordRegister: string;
    passwordConfirm: string;
    constructor(data:any = {}){
        this.nameRegister = data.nameRegister || '';
        this.surnameRegister = data.surnameRegister || '';
        this.languageRegister = data.languageRegister || Constants.LANGUAGES.english;
        this.emailRegister = data.emailRegister || '';
        this.passwordRegister = data.password || '';
        this.passwordConfirm = data.passwordConfirm || '';
    }
}
