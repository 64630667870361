<div *ngIf="!status" style="width: 100%; height: 100%; position: absolute; z-index: 1;"></div>
<div class="row" *ngIf="endpoint.current">
  <h4>{{ endpoint.display_name || endpoint.name }}</h4>
  <div class="col-12">
    <div class="btn-group">
      <button [class.btn-primary]="endpoint.current === 'Cool'" [class.btn-default]="endpoint.current !== 'Cool'"
        [disabled]="endpoint.current == 'Off'" (click)="onChangeEndpoint('Cool')"
        class="btn btn-lg">{{ 'cool' | translate }}
      </button>
      <button [class.btn-primary]="endpoint.current === 'Ventilation'" [class.btn-default]="endpoint.current !== 'Ventilation'"
        [disabled]="endpoint.current == 'Off'" (click)="onChangeEndpoint('Ventilation')"
        class="btn btn-lg">{{ 'ventilation' | translate }}
      </button>
      <button [class.btn-primary]="endpoint.current === 'Heat'" [class.btn-default]="endpoint.current !== 'Heat'"
        [disabled]="endpoint.current == 'Off'" (click)="onChangeEndpoint('Heat')"
        class="btn btn-lg">{{ 'heat' | translate }}
      </button>
    </div>
  </div>
</div>