import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToHumanReadable'
})
export class SecondsToHumanReadablePipe implements PipeTransform {

  transform(value: any, args?: any): string {
    const seconds = Number(value);
    if (seconds) {
      const days = Math.floor(seconds / 86400);
      let reminder = seconds % 86400;
      const hours = Math.floor(reminder / 3600);
      reminder = reminder % 3600;
      const minutes = Math.floor(reminder / 60);
      reminder = reminder % 60;
      return days + 'd ' + hours + 'h ' + minutes + 'm ' + reminder + 's';
    }
  }

}
