
  <h4>{{data.display_name || data.name}}
      <span class="badge badge-info">{{data.current|transformEndpointValues:data || 0}}</span>
  </h4>
  <div class="row dimmer-switch-container">
    <figure class="dimmer">
      <div class="wrapper">
        <div class="knob">
          <div class="center"></div>
        </div>
      </div>
      <dimmer-switch-canvas [endpoint]="endpoint" (endpointChange)="onChangeDimmer($event)" ></dimmer-switch-canvas>
    </figure>
  </div>
