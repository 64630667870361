import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class TabletService {
  public constructor(private http: HttpClient) {}

  public show(id: string) {
    return this.http.get(`${environment.apiUrl}/tablets/${id}`);
  }
  public showAll(params: any = {}) {
    return this.http.get(`${environment.apiUrl}/tablets`, { params: params });
  }
  public update(id: string, data: any) {
    return this.http.put(`${environment.apiUrl}/tablets/${id}`, data);
  }
  public sendMessage(data: any = {}) {
    return this.http.post(`${environment.apiUrl}/tablets/send`, data);
  }
  public sendAlarm(id: string) {
    return this.http.get(`${environment.apiUrl}/tablets/${id}/sendAlarm`);
  }
  public reboot(id: string) {
    return this.http.get(`${environment.apiUrl}/tablets/${id}/reboot`);
  }
  public buildings(params: any = {}) {
    return this.http.get(`${environment.apiUrl}/tablets/groups`, { params: params });
  }
}
