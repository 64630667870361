import {
  Input,
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { SocketService } from '../../../../socket.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-fan-speed-actuator',
  templateUrl: './fan-speed-actuator.component.html',
  styleUrls: ['./fan-speed-actuator.component.scss']
})
export class FanSpeedActuatorComponent implements OnInit, OnDestroy {
  @Output() endpointChange: EventEmitter<any> = new EventEmitter();
  @Input() public endpoint: any;
  /**
   * Current node, parent of the endpoint.
   */
  @Input() node: any;
  @Input() public status: boolean;

  private ioConnection$: Subscription;

  public constructor(private socketService: SocketService) { }

  public ngOnInit() {
    if (!this.endpoint.current) {
      this.endpoint.current = 'Auto';
    }

    this.ioConnection$ = this.socketService
      .onMeasureCreated()
      .subscribe((measure: any) => {
        if (measure.device !== this.node._id || measure.endpoint !== this.endpoint._id) {
          return;
        }
        this.endpoint.current = measure.value;
      });
  }

  public onChangeEndpoint(value: any) {
    this.endpoint.current = value;
    this.endpointChange.emit({
      updated: true,
      _id: this.endpoint._id,
      value: this.endpoint.current
    });
  }

  public ngOnDestroy() {
    if (this.ioConnection$) {
      this.ioConnection$.unsubscribe();
    }
  }
}
