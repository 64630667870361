
import {throwError as observableThrowError, Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
  HttpErrorResponse,
  HttpEvent
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Location } from '@angular/common';
import { tap } from 'rxjs/operators';

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {
  private isRefreshingToken = false;
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  private whiteList = [
    'mobile/',
    '.css',
    '.js',
    'googleapis.com',
    'assets/i18n',
    'gstatic.com',
    'assets/custom',
    'socket.io',
    '.json',
    'users/login'
  ];

  constructor(private authService: AuthService, private location: Location) { }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    if (token) {
      let headers = req.headers.set('Authorization', 'Bearer ' + token);

      return req.clone({ headers });
    } else {
      return req;
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
  > {
    const started = Date.now();

    let onWhiteList = false;
    this.whiteList.forEach(function (rule) {
      if (req.url.indexOf(rule) > -1) {
        onWhiteList = true;
      }
    });
    if (!onWhiteList) {
      req = this.addToken(req, this.authService.getAuthToken());
    }

    return next.handle(req).pipe(tap(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const elapsed = Date.now() - started;
          console.log(`Request for ${req.urlWithParams} took ${elapsed} ms.`);
        }
      },
      (error: any) => {
        if (error instanceof HttpErrorResponse) {
          const elapsed = Date.now() - started;
          console.log(
            `Request for ${req.urlWithParams} failed after ${elapsed} ms.`
          );

          switch ((<HttpErrorResponse>error).status) {
            case 400:
              console.log('400', error);
              return this.handle400Error(error);
            case 401:
              console.log('401', error);
              return this.handle401Error(req, next);
          }
        } else {
          return observableThrowError(error);
        }
      }
    ));
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    this.location.back();
    // return this.logoutUser();
    /*
            if (!this.isRefreshingToken) {
                this.isRefreshingToken = true;

                // Reset here so that the following requests wait until the token
                // comes back from the refreshToken call.
                this.tokenSubject.next(null);

                return this.authService.refreshToken()
                    .switchMap((newToken: string) => {
                        if (newToken) {
                            this.tokenSubject.next(newToken);
                            return next.handle(this.addToken(this.getNewRequest(req), newToken));
                        }

                        // If we don't get a new token, we are in trouble so logout.
                        return this.logoutUser();
                    })
                    .catch(error => {
                        // If there is an exception calling 'refreshToken', bad news so logout.
                        return this.logoutUser();
                    })
                    .finally(() => {
                        this.isRefreshingToken = false;
                    });
            } else {
                return this.tokenSubject
                    .filter(token => token != null)
                    .take(1)
                    .switchMap(token => {
                        return next.handle(this.addToken(this.getNewRequest(req), token));
                    });
            }
        */
  }

  handle400Error(error) {
    return observableThrowError(error);
    /*
        if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
            // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
            return this.logoutUser();
        }

        return Observable.throw(error);
        */
  }

  logoutUser() {
    // Route to the login page (implementation up to you)
    this.authService.logout();
    return observableThrowError('');
  }

  /*
        getNewRequest(req: HttpRequest<any>): HttpRequest<any> {

            // This method is only here so the example works.
            // Do not include in your code, just use 'req' instead of 'this.getNewRequest(req)'.
            if (req.url.indexOf('getData') > 0) {
            return new HttpRequest('GET', 'http://private-4002d-testerrorresponses.apiary-mock.com/getData');
            }
            return new HttpRequest('GET', 'http://private-4002d-testerrorresponses.apiary-mock.com/getLookup');
        }
    */
}
