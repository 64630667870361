import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import 'jquery-slimscroll';
import { AuthService } from '../../shared/services/auth.service';
import 'metismenu';
import {
  faTachometerAlt,
  faUser,
  faEye,
  faDotCircle,
  faList,
  faPlusSquare,
  faTabletAlt,
  faBuilding,
  faHome,
  faWindowMaximize,
  faDoorOpen,
  faEnvelope,
  faKey,
  faCompass,
  faWifi,
  faExclamationCircle,
  faChartBar,
  faFileSignature,
  faFileExcel,
  faUsers,
  faTags,
  faSitemap,
  faSlidersH,
  faHistory,
  faCogs,
  faIdBadge,
  faProjectDiagram,
  faPhoneAlt
} from '@fortawesome/free-solid-svg-icons';
import { faConnectdevelop } from '@fortawesome/free-brands-svg-icons';
import { User } from 'app/shared/models/user.model';

declare var jQuery: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy, AfterViewInit {
  public menu: any = [];
  public currentUser: any;
  private translate$: Subscription;

  private notificationPermission: boolean;
  private buildingPermission: boolean;
  private apartmentPermission: boolean;
  private roomPermission: boolean;
  private devicePermission: boolean;
  private sensorsPermission: boolean;
  private reportsPermission: boolean;
  private statisticsPermission: boolean;
  private mobilePermission: boolean;
  private apiPermission: boolean;

  constructor(
    private router: Router,
    private auth: AuthService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.currentUser = this.auth.getAuthUser();
    this.sensorsPermission = this.currentUser.is_super_admin || this.currentUser.organizationObj.menus.sensors;
    this.reportsPermission = this.currentUser.is_super_admin ||
      (this.currentUser.is_admin && this.currentUser.organizationObj.menus.reports);
    this.statisticsPermission = this.currentUser.is_super_admin ||
      (this.currentUser.is_admin && this.currentUser.organizationObj.menus.statistics);
    this.notificationPermission = this.currentUser.is_super_admin ||
      (this.currentUser.organizationObj.menus.notifications && this.checkTags('notifications'));
    this.mobilePermission = this.currentUser.is_super_admin ||
      (this.currentUser.is_admin && this.currentUser.organizationObj.menus.mobile);
    this.apiPermission = this.currentUser.is_super_admin ||
      (this.currentUser.is_admin && this.currentUser.organizationObj.menus.api);
    this.buildingPermission = this.currentUser.is_super_admin || this.currentUser.is_admin || this.checkTags('buildings');
    this.apartmentPermission = this.currentUser.is_super_admin || this.currentUser.is_admin || this.checkTags('apartments');
    this.roomPermission = this.currentUser.is_super_admin || this.currentUser.is_admin || this.checkTags('rooms');
    this.devicePermission = this.currentUser.is_super_admin || this.currentUser.is_admin || this.checkTags('devices');
    this.translate$ = this.translate.get(['nav', 'devices']).subscribe(translations => {
      this.menu = this.getMenu(translations, this.currentUser);
    });
  }

  getMenu(translations: any, currentUser: User) {
    return [
      {
        label: translations.nav.dashboard,
        icon: faTachometerAlt,
        params: null,
        state: '/dashboard',
        permission: true,
        subMenu: []
      },
      {
        label: translations.nav.users,
        icon: faUser,
        params: null,
        state: '/subscriber',
        permission: currentUser.is_super_admin || currentUser.is_admin,
        subMenu: []
      },
      {
        label: translations.nav.deviceManagement,
        icon: faEye,
        params: null,
        state: null,
        permission: true,
        subMenu: [
          {
            label: translations.nav.edgeServers,
            icon: faProjectDiagram,
            params: null,
            permission: this.devicePermission,
            state: '/edge-server',
            subMenu: []
          },
          {
            label: translations.nav.gateways,
            icon: faConnectdevelop,
            params: null,
            state: '/gateway',
            permission: this.devicePermission,
            subMenu: []
          },
          {
            label: translations.nav.nodes,
            icon: faDotCircle,
            params: null,
            permission: this.devicePermission,
            state: '/node',
            subMenu: []
          },
          {
            label: translations.nav.tablets,
            icon: faTabletAlt,
            params: null,
            permission: true,
            state: '/tablet',
            subMenu: []
          },
          {
            label: translations.nav.sipServers,
            icon: faPhoneAlt,
            params: null,
            permission: this.devicePermission,
            state: '/sip-server',
            subMenu: []
          },
          {
            label: translations.nav.device_types,
            icon: faList,
            params: null,
            state: '/devicetype',
            permission: this.currentUser.is_super_admin || this.currentUser.is_admin,
            subMenu: []
          },
          {
            label: translations.devices.addNew,
            icon: faPlusSquare,
            params: null,
            state: '/node/assign',
            permission: this.currentUser.is_admin,
            subMenu: []
          }
        ]
      },
      {
        label: translations.nav.facilityManagement,
        icon: faBuilding,
        params: null,
        state: null,
        permission:
          this.devicePermission ||
          this.buildingPermission ||
          this.apartmentPermission ||
          this.roomPermission,
        subMenu: [
          {
            label: translations.nav.buildings,
            icon: faBuilding,
            params: null,
            state: '/building',
            permission: this.buildingPermission,
            subMenu: []
          },
          {
            label: translations.nav.apartments,
            icon: faHome,
            params: null,
            permission: this.apartmentPermission,
            state: '/apartment',
            subMenu: []
          },
          {
            label: translations.nav.rooms,
            icon: faWindowMaximize,
            params: null,
            state: '/room',
            permission: this.roomPermission,
            subMenu: []
          },
          {
            label: translations.nav.doors,
            icon: faDoorOpen,
            params: null,
            state: '/doors',
            permission: true,
            subMenu: []
          },
          {
            label: translations.nav.mailboxes,
            icon: faEnvelope,
            params: null,
            state: '/mailbox',
            permission: this.devicePermission,
            subMenu: []
          },
          {
            label: translations.nav.keys,
            icon: faKey,
            params: null,
            state: '/key',
            permission: this.devicePermission,
            subMenu: []
          },
          {
            label: translations.nav.nodes,
            icon: faDotCircle,
            params: null,
            permission: this.devicePermission,
            state: '/facility/node',
            subMenu: []
          }
        ]
      },
      {
        label: translations.nav.mobileAssets,
        icon: faCompass,
        params: null,
        state: '/mobiles',
        permission: this.mobilePermission,
        subMenu: []
      },
      {
        label: translations.nav.loraSensors,
        icon: faWifi,
        params: null,
        state: '/sensor',
        permission: this.sensorsPermission,
        subMenu: []
      },
      {
        label: translations.nav.notifications,
        icon: faExclamationCircle,
        params: null,
        state: '/notifications',
        permission: this.notificationPermission,
        subMenu: []
      },
      {
        label: translations.nav.statistics,
        icon: faChartBar,
        params: null,
        state: '/statistics',
        permission: this.statisticsPermission,
        subMenu: []
      },
      {
        label: translations.nav.reports,
        icon: faFileSignature,
        params: null,
        state: null,
        permission: this.reportsPermission,
        subMenu: [
          {
            label: translations.nav.nodeMeasures,
            icon: faFileExcel,
            params: null,
            state: '/reports/node/measures',
            permission: this.reportsPermission,
            subMenu: []
          },
          {
            label: translations.nav.organizationDevices,
            icon: faFileExcel,
            params: null,
            state: '/reports/organization/devices',
            permission: this.reportsPermission,
            subMenu: []
          }
        ]
      },
      {
        label: translations.nav.administrators,
        icon: faUsers,
        params: null,
        state: '/administrator',
        permission: this.currentUser.is_super_admin || this.currentUser.is_admin,
        subMenu: []
      },
      {
        label: translations.nav.tags,
        icon: faTags,
        params: null,
        state: '/tags',
        permission: this.currentUser.is_super_admin || this.currentUser.is_admin,
        subMenu: []
      },
      {
        label: translations.nav.organizations,
        icon: faSitemap,
        params: null,
        state: '/organization',
        permission: this.currentUser.is_super_admin,
        subMenu: []
      },
      {
        label: translations.nav.settings,
        icon: faSlidersH,
        params: null,
        state: '/settings',
        permission: this.currentUser.is_super_admin,
        subMenu: []
      },
      {
        label: translations.nav.logs,
        icon: faHistory,
        params: null,
        state: '/logs',
        permission:
          this.currentUser.is_super_admin || this.currentUser.is_admin,
        subMenu: []
      },
      {
        label: 'API',
        icon: faCogs,
        params: null,
        state: '/api-docs',
        permission: this.apiPermission,
        subMenu: [],
        target: '_blank'
      },
      {
        label: translations.nav.oauth2Clients,
        icon: faIdBadge,
        params: null,
        state: '/oauth2-clients',
        permission: this.currentUser.is_super_admin,
        subMenu: []
      }
    ];
  }

  private checkTags(tagsGroup: string) {
    const tags = this.currentUser.groups[tagsGroup];
    if (tags && tags.length > 0) {
      return true;
    }
    return false;
  }

  loadJQuery() {
    jQuery('#side-menu').metisMenu();

    if (jQuery('body').hasClass('fixed-sidebar')) {
      jQuery('.sidebar-collapse').slimscroll({
        height: '100%'
      });
    }
  }

  logout() {
    this.auth.logout();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loadJQuery();
    }, 1000);
  }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) > -1;
  }

  ngOnDestroy() {
    if (this.translate$) {
      this.translate$.unsubscribe();
    }
  }
}
