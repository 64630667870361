<div *ngIf="!status" style="width: 100%; height: 100%; position: absolute; z-index: 1;"></div>
<div class="actuator">
  <div style="text-align:center">
    <div class="row">
      <div class="col-lg-12" style="margin-bottom: 10px;">FanSpeed</div>
      <div class="col-lg-12">
        <div class="btn-group">
          <button [class.btn-primary]="fanSpeedMedium.current === 'Low'"
            [class.btn-default]="fanSpeedMedium.current !== 'Low'" (click)="changeFanSpeed('Medium')" class="btn btn-lg"
            [disabled]="disable">Low
          </button>
          <button [class.btn-primary]="fanSpeedMedium.current === 'On'"
            [class.btn-default]="fanSpeedMedium.current !== 'On'" (click)="changeFanSpeed('Medium')" class="btn btn-lg"
            [disabled]="disable">Medium
          </button>
          <button [class.btn-primary]="fanSpeedHigh.current === 'On'"
            [class.btn-default]="fanSpeedHigh.current !== 'On'" (click)="changeFanSpeed('High')" class="btn btn-lg"
            [disabled]="disable">High
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12" style="margin-bottom: 10px;">Cooling</div>
      <div class="col-lg-12">
        <div class="btn-group">
          <button [class.btn-primary]="coolingOn.current === 'On'" [class.btn-default]="coolingOn.current !== 'On'"
            (click)="changeCooling('On')" class="btn btn-lg" [disabled]="disable">On
          </button>
          <button [class.btn-primary]="coolingOff.current === 'On'" [class.btn-default]="coolingOff.current !== 'On'"
            (click)="changeCooling('Off')" class="btn btn-lg" [disabled]="disable">Off
          </button>
        </div>
      </div>
    </div>
  </div>
</div>