import {
  Input,
  Component,
  OnInit,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GroupService } from '../../../shared/services/group.service';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, switchMap, debounceTime } from 'rxjs/operators';

/**
 * Component used to represent a tags select box.
 */
@Component({
  selector: 'app-input-tags-server',
  templateUrl: './input-tags-server.component.html',
  styleUrls: ['./input-tags-server.component.scss']
})
export class InputTagsServerComponent implements OnInit, OnDestroy {
  /**
   * Array of tags.
   */
  public items: any[] = [];
  /**
   * Variable to paginate the tags server response.
   */
  public currentPage = 1;
  /**
   * Total number of pages.
   */
  public totalPages = 0;
  /**
   * Total number of items.
   */
  public totalItems = 0;
  /**
   * Limit the number of items to return.
   */
  public limit = 10;
  /**
   * Indicates whether the component is loading the tags.
   */
  public loading = false;
  /**
   * Items or strings typed in select box.
   */
  public itemsTypeahead = new EventEmitter<string>();
  /**
   * Filters for the search call.
   */
  public filter: any = {};
  /**
   * Selected values in select box.
   */
  public selectedValues: any;
  /**
   * Indicates whether the select box is disabled or not.
   */
  public disableSelector = false;
  public positionClass: string;

  /**
   * Manages changes related to disableSelector.
   */
  @Input() disabled: boolean;
  /**
   * Form where the select box is placed.
   */
  @Input() group: FormGroup;
  /**
   * The name of the form control used as select box.
   */
  @Input() name: string;
  /**
   * Force top or bottom position.
   */
  @Input() position: string;
  @Input() type: string;

  private create$: Subscription;
  private showAll$: Subscription;

  /**
   * Builds the component and initializes certain services.
   * @param {AuthService} auth Service needed to get the logged user.
   * @param {GroupService} groupService Service that provides the API calls needed to manage tags in the backend.
   */
  public constructor(private groupService: GroupService) { }

  /**
   * Initializes certain attributes and loads data.
   */
  public ngOnInit() {
    if (this.position === 'top') {
      this.positionClass = 'force-top';
    } else if (this.position === 'bottom') {
      this.positionClass = 'force-bottom';
    }
    if (this.group) {
      this.group.controls[this.name].valueChanges.subscribe(selectedValue => {
        this.selectedValues = selectedValue;
        if (selectedValue && selectedValue.length === 0) {
          this.items = [];
          if (this.group.value.organization !== null) {
            this.loadData(this.group.value.organization, this.type);
          }
        }
      });
      if (this.group.controls.organization) {
        this.group.controls.organization.valueChanges.subscribe(
          organization => {
            this.items = [];
            if (organization !== null) {
              this.loadData(organization, this.type);
            }
          }
        );
        if (this.group.controls.organization.value !== null) {
          this.loadData(this.group.value.organization, this.type);
          this.serverSideSearch();
        } else {
          this.items = [];
          this.totalItems = 0;
          this.totalPages = 0;
        }
      }
      if (this.disabled) {
        this.group.controls[this.name].disable();
      }
    }
  }

  /**
   * Takes more tags from the server when the user scrolls down to the bottom of the select box options, using the next page too.
   */
  public fetchMore() {
    if (this.currentPage >= this.totalPages) {
      return;
    }
    this.currentPage++;
    this.loadData(this.group.value.organization, this.type, this.currentPage);
  }

  /**
   * Creates a new tag for the select box.
   * @param name Name of the new tag.
   */
  public addTag(name) {
    return { name: name, tag: true };
  }

  /**
   * Stores the created tag in the backend.
   * @param event The tag object.
   */
  public onAdd(event) {
    if (event.tag) {
      this.create$ = this.groupService
        .create({
          organization: this.group.value.organization,
          name: event.name,
          type: this.type
        })
        .subscribe(
          res => {
            if (this.selectedValues) {
              for (let i = 0; i < this.selectedValues.length; i++) {
                if (this.selectedValues[i].name === event.name) {
                  this.selectedValues[i] = res;
                }
              }
            } else {
              this.selectedValues = [];
              this.selectedValues.push(res);
            }
            this.loadData(this.group.value.organization, this.type);
          },
          err => {
            let i = 0;
            for (; i < this.selectedValues.length; i++) {
              if (this.selectedValues[i].name === event.name) {
                break;
              }
            }
            delete this.selectedValues[i];
            this.loadData(this.group.value.organization, this.type);
          }
        );
    }
  }

  /**
   * Loads data into the select box refreshing it and filtering through certain parameters.
   * @param {string} search Term to search.
   * @param {string} page Number of the page to take.
   */
  private loadData(
    organization: string,
    type?: string,
    page?: any
  ) {
    let params = {
      page: page || 1,
      limit: this.limit,
      ...this.filter,
      organization: organization
    };

    if (type) {
      params.type = type;
      params.inclusive_type = true;
    }

    this.currentPage = params.page;
    this.loading = true;
    this.showAll$ = this.groupService.showAll(params).subscribe((res: any) => {
      this.loading = false;
      this.totalItems = res.total;
      this.totalPages = res.pages;
      this.items = [...this.items, ...res.docs];
    });
  }

  /**
   * Performs a search of tags in the backend, every 200ms and every time the search text box changes.
   */
  private serverSideSearch() {
    this.showAll$ = this.itemsTypeahead.pipe(
      distinctUntilChanged(),
      debounceTime(200),
      switchMap((term: any) => {
        const params = {
          search: term || '',
          organization: this.group.value.organization,
          type: this.type,
          inclusive_type: true
        };
        return this.groupService.showAll(params);
      })
    ).subscribe(
      (res: any) => {
        this.items = [].concat(res.docs);
        this.totalItems = res.total;
        this.totalPages = res.pages;
      },
      err => {
        console.error(err);
        this.items = [];
        this.totalItems = 0;
        this.totalPages = 0;
      }
    );
  }

  public ngOnDestroy() {
    if (this.create$) {
      this.create$.unsubscribe();
    }
    if (this.showAll$) {
      this.showAll$.unsubscribe();
    }
  }
}
