import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class NotificationService {
  /**
   * Build the component and initialize certain attributes.
   * @param {HttpClient} http Service to make API calls.
   */
  constructor(private http: HttpClient) { }

  /**
   * Deletes a notification in the backend.
   * @param {string} id Notification ID.
   */
  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/notifications/${id}`);
  }
}
