<div
  class="m-grid__item m_grid_wrapper m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-grid--tablet-and-mobile m-grid--hor-tablet-and-mobile m-login m-login--1 m-login--signin"
  id="m_login">
  <div class="m-grid__item m-grid__right m-grid__item--order-tablet-and-mobile-2 m-login__aside">
    <div class="input-group" id="language-selector">
      <span class="input-group-addon">
        <fa-icon [icon]="faGlobe"></fa-icon>
      </span>
      <select class="form-control" [ngModel]="language" (ngModelChange)="onChangeLanguage($event)">
        <option [value]="languages.english">English</option>
        <option [value]="languages.german">Deutsche</option>
        <option [value]="languages.spanish">Español</option>
        <option [value]="languages.french">Français</option>
        <option [value]="languages.italian">Italiano</option>
      </select>
    </div>
    <div class="m-stack m-stack--hor m-stack--desktop">
      <div class="m-stack__item m-stack__item--fluid">
        <div class="m-login__wrapper">
          <div class="m-login__logo">
            <a href="#">
              <img src="assets/custom/images/logo_green.png">
            </a>
          </div>
          <div class="m-login__signin">
            <div class="m-login__head">
              <ul class="nav-links new-session-tabs nav-tabs nav" role="tablist">
                <li [ngClass]="{'active': selectedTab === TAB_LOGIN }" class="nav-item active" role="presentation">
                  <a class="nav-link qa-sign-in-tab" data-toggle="tab" (click)="selectedTab=TAB_LOGIN">
                    Sign In
                  </a>
                </li>
                <li [ngClass]="{'active': selectedTab === TAB_REGISTER }" class="nav-item" role="presentation">
                  <a class="nav-link qa-register-tab" data-toggle="tab" (click)="selectedTab=TAB_REGISTER">
                    Register
                  </a>
                </li>
              </ul>
              <div class="tab-content">
                <div [ngClass]="{'active': selectedTab === TAB_LOGIN }" class="login-box tab-pane" id="login-pane">
                  <form (ngSubmit)="onSubmit()" #f="ngForm" class="m-login__form m-form" novalidate autocomplete="off">

                    <div *ngIf="error.success != null && error.success == false" class="alert alert-danger">
                      {{ error.message }}
                    </div>

                    <div class="form-group m-form__group" [ngClass]="{
                        'has-danger': username.invalid && (username.dirty || username.touched),
                        'has-success': username.valid && (username.dirty || username.touched)
                      }">
                      <input placeholder="Username" type="text" class="form-control m-input" name="username"
                        [(ngModel)]="data.username" required #username="ngModel" autocomplete="username" autofocus>
                      <span class="" *ngIf="username.errors && (username.dirty || username.touched)">
                        <p *ngIf="username.errors.required">{{'usernameRequired'|translate}}</p>
                      </span>
                    </div>
                    <div class="form-group m-form__group input-password">
                      <input placeholder="Password" [type]="showPassword1 ? 'text' : 'password'"
                        class="form-control m-input input-password" name="password" [(ngModel)]="data.password" required
                        minlength="2" #password="ngModel" autocomplete="current-password">
                      <fa-icon (click)="onShowPassword1()" [icon]="showPassword1 ? faEye : faEyeSlash"></fa-icon>
                    </div>
                    <div class="form-group m-form__group" [ngClass]="{
                          'has-danger': password.invalid && (password.dirty || password.touched),
                          'has-success': password.valid && (password.dirty || password.touched)
                      }">
                      <span class="" *ngIf="password.errors && (password.dirty || password.touched)">
                        <p *ngIf="password.errors.required">{{'passRequired'|translate}}</p>
                        <p *ngIf="password.errors.minlength">{{'passLength'|translate}}</p>
                      </span>
                    </div>
                    <div class="row m-login__form-sub">
                      <div class="col m--align-left">
                        <label class="m-checkbox m-checkbox--primary">
                          <input ng-model="vm.form.rememberMe" id="rememberme" type="checkbox" name="remember">
                          {{'rememberMe'|translate}}
                          <span></span>
                        </label>
                      </div>
                      <div class="col m--align-right">
                        <a routerLink="" id="m_login_forget_password" class="m-link">
                          {{'forgetPass'|translate}} ?
                        </a>
                      </div>
                    </div>
                    <div class="m-login__form-action">
                      <button [disabled]="f.invalid" id="m_login_signin_submit"
                        class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">
                        {{'signIn'|translate}}
                      </button>
                    </div>
                  </form>
                </div>
                <div [ngClass]="{'active': selectedTab === TAB_REGISTER }" class="login-box tab-pane"
                  id="register-pane">
                  <form (ngSubmit)="onRegister()" #fr="ngForm" class="m-login__form m-form" novalidate
                    autocomplete="off">

                    <div *ngIf="error.success != null && error.success == false" class="alert alert-danger">
                      <span style="white-space: pre;">{{ error.message }}</span>
                    </div>

                    <div class="form-group m-form__group" [ngClass]="{
                        'has-danger': nameRegister.invalid && (nameRegister.dirty || nameRegister.touched),
                        'has-success': nameRegister.valid && (nameRegister.dirty || nameRegister.touched)
                      }">
                      <input placeholder="Name" type="text" class="form-control m-input" name="nameRegister"
                        [(ngModel)]="dataRegister.nameRegister" required #nameRegister="ngModel">
                      <span class="" *ngIf="nameRegister.errors && (nameRegister.dirty || nameRegister.touched)">
                        <p *ngIf="nameRegister.errors.required">Name is required</p>
                      </span>
                    </div>

                    <div class="form-group m-form__group" [ngClass]="{
                        'has-danger': surnameRegister.invalid && (surnameRegister.dirty || surnameRegister.touched),
                        'has-success': surnameRegister.valid && (surnameRegister.dirty || surnameRegister.touched)
                      }">
                      <input placeholder="Surname" type="text" class="form-control m-input" name="surnameRegister"
                        [(ngModel)]="dataRegister.surnameRegister" required #surnameRegister="ngModel">
                      <span class=""
                        *ngIf="surnameRegister.errors && (surnameRegister.dirty || surnameRegister.touched)">
                        <p *ngIf="surnameRegister.errors.required">Surname is required</p>
                      </span>
                    </div>

                    <div class="form-group m-form__group" [ngClass]="{
                        'has-danger': emailRegister.invalid && (emailRegister.dirty || emailRegister.touched),
                        'has-success': emailRegister.valid && (emailRegister.dirty || emailRegister.touched)
                      }">
                      <input placeholder="Email" [email]="true" type="email" class="form-control m-input"
                        name="emailRegister" [(ngModel)]="dataRegister.emailRegister" required #emailRegister="ngModel">
                      <span *ngIf="emailRegister.errors && (emailRegister.dirty || emailRegister.touched)">
                        <p *ngIf="emailRegister.errors.required">Email is required</p>
                        <p *ngIf="emailRegister.invalid && !emailRegister.errors.required">
                          Please provide a valid email address</p>
                      </span>
                    </div>


                    <div class="form-group m-form__group input-password" [ngClass]="{
                        'has-danger': passwordRegister.invalid && (passwordRegister.dirty || passwordRegister.touched),
                        'has-success': passwordRegister.valid && (passwordRegister.dirty || passwordRegister.touched)
                      }">
                      <input placeholder="Password" [type]="showPassword2 ? 'text' : 'password'"
                        class="form-control m-input" name="passwordRegister" [(ngModel)]="dataRegister.passwordRegister"
                        required minlength="2" #passwordRegister="ngModel" autocomplete="new-password">
                      <fa-icon (click)="onShowPassword2()" [icon]="showPassword2 ? faEye : faEyeSlash"></fa-icon>
                    </div>
                    <div class="form-group m-form__group" [ngClass]="{
                        'has-danger': passwordRegister.invalid && (passwordRegister.dirty || passwordRegister.touched),
                        'has-success': passwordRegister.valid && (passwordRegister.dirty || passwordRegister.touched)
                      }">
                      <span class=""
                        *ngIf="passwordRegister.errors && (passwordRegister.dirty || passwordRegister.touched)">
                        <p *ngIf="passwordRegister.errors.required">{{'passRequired'|translate}}
                        </p>
                        <p *ngIf="passwordRegister.errors.minlength">{{'passLength'|translate}}
                        </p>
                      </span>
                    </div>
                    <div class="form-group m-form__group input-password" [ngClass]="{
                        'has-danger': passwordConfirm.invalid && (passwordConfirm.dirty || passwordConfirm.touched),
                        'has-success': passwordConfirm.valid && (passwordConfirm.dirty || passwordConfirm.touched)
                      }">
                      <input placeholder="Password confirmation" [type]="showPassword3 ? 'text' : 'password'"
                        class="form-control m-input" name="passwordConfirm" [(ngModel)]="dataRegister.passwordConfirm"
                        required minlength="2" #passwordConfirm="ngModel">
                      <fa-icon (click)="onShowPassword3()" [icon]="showPassword3 ? faEye : faEyeSlash"></fa-icon>
                    </div>
                    <div class="form-group m-form__group" [ngClass]="{
                        'has-danger': passwordConfirm.invalid && (passwordConfirm.dirty || passwordConfirm.touched),
                        'has-success': passwordConfirm.valid && (passwordConfirm.dirty || passwordConfirm.touched)
                      }">
                      <span class=""
                        *ngIf="passwordConfirm.errors && (passwordConfirm.dirty || passwordConfirm.touched)">
                        <p *ngIf="passwordConfirm.errors.required">Password confirmation is
                          required</p>
                        <p *ngIf="passwordConfirm.errors.minlength">{{'passLength'|translate}}
                        </p>
                      </span>
                    </div>
                    <div class="m-login__form-action">
                      <button [disabled]="fr.invalid" id="m_register_submit"
                        class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">
                        Sign up
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="m-grid__item m-grid__left m-grid__item--fluid m-grid m-grid--center m-grid--hor m-grid__item--order-tablet-and-mobile-1	m-login__content"
    style="background-image: url(assets/custom/images/login_bg.png)">
    <div class="m-grid__item m-grid__item--middle">
      <h3 class="m-login__welcome">
        Join Our Community
      </h3>
      <p class="m-login__msg">
        The new standard for an IoT Real Estate platform!
      </p>
    </div>
  </div>
</div>
<footer class="main-footer copyright-footer">
  Real Estate Platform - <strong>{{ version }}</strong>
  <div class="float-right">
    <strong>Copyright © 2017-21 goZmart Iberia. {{ 'allRights' | translate }}.</strong>
  </div>
</footer>