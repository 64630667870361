import { Component, Output, EventEmitter, Input } from '@angular/core';
import * as _ from 'underscore';

@Component({
  selector: 'app-datatable-filters',
  templateUrl: './datatable-filters.component.html',
  styleUrls: ['./datatable-filters.component.scss']
})
export class DatatableFiltersComponent {
  private filters: any = {};
  public enable: any;
  private enableDependent: any;

  @Input()
  settings: any;
  @Output()
  processedFilter = new EventEmitter();

  constructor() {}

  preprocessFilter(ev) {
    if (ev.target.value === 'null') {
      const id = ev.target.id;
      delete this.filters[id];
      let current = this.settings.find(o => o.apiFilter === id);
      while (current.linksTo) {
        current = this.settings.find(o => o.name === current.linksTo);
        delete this.filters[current['apiFilter']];
      }
      this.emitFilters(this.filters);
      this.emitEnableSelect(this.enableDependent, false, null);
    } else {
      const id = ev.target.id;
      const obj = {};
      obj[id] = ev.target.value;
      let current = this.settings.find(o => o.apiFilter === id);
      while (current.linksTo) {
        current = this.settings.find(o => o.name === current.linksTo);
        delete this.filters[current['apiFilter']];
      }
      this.filters = { ...this.filters, ...obj };
      this.emitFilters(this.filters);
      this.emitEnableSelect(this.enableDependent, true, ev.target.value);
    }
  }

  enableSelect(ev) {
    this.enableDependent = ev;
    // ['linksTo', 'apiFilter']
  }

  emitEnableSelect(select, condition, id) {
    this.enable = [...select, condition, id];
  }

  emitFilters(data) {
    this.processedFilter.emit(data);
  }
}
