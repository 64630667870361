<div *ngIf="!status" style="width: 100%; height: 100%; position: absolute; z-index: 1;"></div>
<div class="row" *ngIf="data.current !== undefined">
  <h4>{{ data.display_name || data.name }}</h4>
  <div class="col-12">
    <div class="btn-group">
      <button [class.btn-primary]="data.current === 'Cool'" [class.btn-default]="data.current !== 'Cool'"
        [disabled]="data.current == 'Off'" (click)="onChangeEndpoint('Cool')" class="btn btn-lg">Cool
      </button>
      <button [class.btn-primary]="data.current === 'Fan'" [class.btn-default]="data.current !== 'Fan'"
        [disabled]="data.current == 'Off'" (click)="onChangeEndpoint('Fan')" class="btn btn-lg">Fan
      </button>
      <button [class.btn-primary]="data.current === 'Heat'" [class.btn-default]="data.current !== 'Heat'"
        [disabled]="data.current == 'Off'" (click)="onChangeEndpoint('Heat')" class="btn btn-lg">Heat
      </button>
      <button [class.btn-primary]="data.current === 'Auto'" [class.btn-default]="data.current !== 'Auto'"
        [disabled]="data.current == 'Off'" (click)="onChangeEndpoint('Auto')" class="btn btn-lg">Auto
      </button>
    </div>
  </div>
</div>
<br />