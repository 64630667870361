import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class DevicetypeService {
  public constructor(private http: HttpClient) {}

  public create(data: FormData) {
    return this.http.post(`${environment.apiUrl}/devicetypes`, data);
  }
  public update(id: string, data: FormData) {
    return this.http.put(`${environment.apiUrl}/devicetypes/${id}`, data);
  }
  public delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/devicetypes/${id}`);
  }
  public show(id: string) {
    return this.http.get(`${environment.apiUrl}/devicetypes/${id}`);
  }
  public showAll(params: any = {}) {
    return this.http.get(`${environment.apiUrl}/devicetypes`, {
      params: params
    });
  }
}
