<!-- Wrapper-->
<div id="wrapper">

  <!-- Main page wrapper -->
  <div id="page-wrapper" class="gray-bg">
    <!-- Main view/routes wrapper <router-outlet></router-outlet>-->
    <router-outlet></router-outlet>

  </div>
  <!-- End page wrapper-->

</div>
<!-- End wrapper-->
  