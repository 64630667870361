import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy
} from '@angular/core';
import { SocketService } from '../../../../socket.service';

/**
 * Component used to represent a dimmer.
 */
@Component({
  selector: 'app-dimmer-actuator',
  templateUrl: './dimmer-actuator.component.html',
  styleUrls: ['./dimmer-actuator.component.scss']
})
export class DimmerActuatorComponent implements OnInit, OnDestroy {
  /**
   * Dimmer endpoint.
   */
  @Input() endpoint: any;
  /**
   * Current node, parent of the endpoint.
   */
  @Input() node: any;
  @Input() public status: boolean;
  /**
   * Emit dimmer changes.
   */
  @Output() endpointChange: EventEmitter<any> = new EventEmitter();

  /**
   * Current endpoint.
   */
  public data: any;
  /**
   * Suscription to the socket event related to the actuation updates.
   */
  private ioConnectionActuation$: any;

  /**
   * Builds the component and initializes the socket service.
   * @param {SocketService} socketService Service to subscribe to actuation updates event.
   */
  public constructor(private socketService: SocketService) { }

  /**
   * Initializes the endpoint data and the socket connection.
   */
  public ngOnInit() {
    this.data = { ...this.endpoint };
    if (!this.data.current) {
      this.data.current = [];
      this.data.current = [0, 0];
    }
    this.ioConnectionActuation$ = this.socketService
      .onActuationUpdated()
      .subscribe((measure: any) => {
        if (this.node._id === measure.device && this.data._id === measure.endpoint)
        {
          return this.updateMeasure(measure);
        }
        return;
      });
  }

  /**
   * Updates the value of the dimmer after it is updated in the backend.
   * @param measure Value of the dimmers.
   */
  updateMeasure(measure: any) {
    this.data.current = measure.value;
  }

  /**
   * Emits a change in the dimmer when the value is modified by the user.
   * @param index Number of the dimmer modified.
   * @param value New value for the dimmer.
   */
  onChangeDimmer(index: number, value: any) {
    this.data.current[index] = value;
    console.log('dimmer', value);
    this.endpointChange.emit({
      updated: true,
      _id: this.data._id,
      value: this.data.current
    });
  }

  /**
   * Removes the connection to the socket.
   */
  ngOnDestroy() {
    if (this.ioConnectionActuation$) {
      this.ioConnectionActuation$.unsubscribe();
    }
  }
}
