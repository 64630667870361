<div *ngIf="!status" style="width: 100%; height: 100%; position: absolute; z-index: 1;"></div>
<div class="actuator">
  <div class="text-center">
    <h3>{{ title.current }} {{ 'by.author' | translate }} {{ artist.current }}</h3>
    <h4>{{ album.current }}</h4>
    <img [src]="albumCover.current" alt="Album cover">
    <div id="controls">
      <button class="btn btn-default" (click)="onMute()"
        [attr.tooltip]="mute.current === 'On' ? translations?.unmute : translations?.mute">
        <fa-icon *ngIf="mute.current === 'On'" [icon]="faVolumeOff"></fa-icon>
        <fa-icon *ngIf="mute.current === 'Off'" [icon]="faVolumeUp"></fa-icon>
      </button>
      <button class="btn btn-default" (click)="onPrevious()" [attr.tooltip]="translations?.previous">
        <fa-icon [icon]="faStepBackward"></fa-icon>
      </button>
      <button [disabled]="state.current === 'no_media' || state.current === 'stopped'" class="btn btn-default"
        (click)="onStop()" [attr.tooltip]="translations?.stop">
        <fa-icon [icon]="faStop"></fa-icon>
      </button>
      <button *ngIf="state.current === 'playing' || state.current === 'transitioning'" class="btn btn-default"
        (click)="onPlaypause()" [disabled]="state.current === 'no_media'" [attr.tooltip]="translations?.pause">
        <fa-icon [icon]="faPause"></fa-icon>
      </button>
      <button *ngIf="state.current === 'stopped' || state.current === 'paused'" class="btn btn-default"
        (click)="onPlaypause()" [disabled]="state.current === 'no_media'" [attr.tooltip]="translations?.play">
        <fa-icon [icon]="faPlay"></fa-icon>
      </button>
      <button class="btn btn-default" (click)="onNext()" [attr.tooltip]="translations?.next">
        <fa-icon [icon]="faStepForward"></fa-icon>
      </button>
      <span>{{ duration.current | transformEndpointValues:duration}}</span>
    </div>
    <app-brightness-actuator [node]="device" [endpoint]="volume" (endpointChange)="onVolumeChange($event)">
    </app-brightness-actuator>
  </div>
</div>