<div *ngIf="!status" style="width: 100%; height: 100%; position: absolute; z-index: 1;"></div>
<div class="row" *ngIf="data.current !== undefined">
  <h4>{{data.display_name || data.name}}</h4>
  <div class="col-12">
    <button *ngIf="data.current === 'Off'" (click)="onChangeEndpoint('Low')" class="btn-primary btn btn-sm">
      <span>ON</span>
    </button>
    <button *ngIf="data.current !== 'Off'" (click)="onChangeEndpoint('Off')" class="btn-danger btn btn-sm">
      <span>OFF</span>
    </button>
    <button [class.btn-primary]="data.current === 'Low'" [disabled]="data.current == 'Off'"
      (click)="onChangeEndpoint('Low')" class="btn btn-sm">Low
    </button>
    <button [class.btn-primary]="data.current === 'Medium'" [disabled]="data.current == 'Off'"
      (click)="onChangeEndpoint('Medium')" class="btn btn-sm">Medium
    </button>
    <button [class.btn-primary]="data.current === 'High'" [disabled]="data.current == 'Off'"
      (click)="onChangeEndpoint('High')" class="btn btn-sm">High
    </button>
    <div>
      <span>
        <small>
          <span class="ng-binding">0</span> Fan
        </small>
      </span>,
      <span>
        <small>
          <span>1</span> Fan
        </small>
      </span>,
      <span>
        <small>
          <span>2</span> Fan
        </small>
      </span>
    </div>
  </div>
</div>