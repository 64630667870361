import {
  Input,
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import * as _ from 'underscore';
import { SocketService } from '../../../../socket.service';

/**
 * Component to represent a switch actuator.
 */
@Component({
  selector: 'app-switch-actuator',
  templateUrl: './switch-actuator.component.html',
  styleUrls: ['./switch-actuator.component.scss']
})
export class SwitchActuatorComponent implements OnInit, OnDestroy {
  /**
   * Emits an endpoint change with its new value.
   */
  @Output() endpointChange: EventEmitter<any> = new EventEmitter();
  /**
   * Current node, parent of the endpoint.
   */
  @Input() node: any;
  /**
   * Current endpoint.
   */
  @Input() endpoint: any;
  /**
   * Indicates whether the actuator is connected or not.
   */
  @Input() public status: boolean;

  /**
   * Indicates whether the switch is turned on or off.
   */
  public active = false;
  /**
   * Current endpoint.
   */
  public data: any;
  /**
   * Subscription to actuation:updated backend event.
   */
  private ioConnectionActuation$: any;

  /**
   * Builds the component and initializes the socket service.
   * @param {SocketService} socketService Service to subscribe to the actuation updated event.
   */
  public constructor(private socketService: SocketService) { }

  /**
   * Initializes the switch and subscribes to the socket event.
   */
  public ngOnInit() {
    this.data = { ...this.endpoint };
    this.tarea();

    this.ioConnectionActuation$ = this.socketService
      .onMeasureCreated()
      .subscribe((measure: any) => {
        this.updateMeasure(measure);
      });
  }

  tarea() {
    const endpointId = this.endpoint.id;
    const lastCharId = endpointId.substr(endpointId.length - 1);
    const statusRelay = _.findWhere(this.node.scheme.endpoints, { id: 'status_relay_' + lastCharId });

    if (statusRelay) {
      this.data.current = statusRelay.current.toLowerCase() === 'on' ? true : false;
      this.data.listen_on = statusRelay;
    }
    this.checkedSwitch(this.data.current);
  }

  /**
   * Updates the switch state.
   * @param measure New value for the endpoint.
   */
  updateMeasure(measure: any) {
    if (measure.device !== this.node._id) {
      return;
    }
    if (this.data.listen_on) {
      if (measure.endpoint !== this.data.listen_on._id) {
        return;
      }
    } else {
      if (measure.endpoint !== this.data._id) {
        return;
      }
    }

    this.data.current = measure.value ? 'On' : 'Off';

    this.checkedSwitch(measure.value);
  }

  /**
   * Activates or deactivates the switch.
   * @param value Switch state.
   */
  checkedSwitch(value: any) {
    if (this.data.type === 'stateful') {
      // stateful
      if (typeof value === 'string') {
        this.active = value.toLowerCase() === 'on' ? true : false;
      } else {
        this.active = value ? true : false;
      }
    } else {
      // stateless
      this.active = value ? true : false;
    }
  }

  /**
   * Function executed when the switch is modified through the GUI.
   * @param value Switch state.
   */
  onChangeEndpoint(value) {
    if (this.data.type === 'stateful') {
      // stateful
      this.data.current = value ? 'On' : 'Off';
      this.endpointChange.emit({
        updated: true,
        _id: this.data._id,
        value: this.data.current
      });
    } else {
      // stateless
      this.data.current = value ? 1 : 0;
      this.endpointChange.emit({
        updated: true,
        _id: this.data._id,
        value: this.data.current
      });
    }
  }

  /**
   * Deactivates the socket event subscription.
   */
  public ngOnDestroy() {
    if (this.ioConnectionActuation$) {
      this.ioConnectionActuation$.unsubscribe();
    }
  }
}
