<div *ngFor="let endpoint of endpoints">
  <div [ngSwitch]="true" class="position-relative">
    <div *ngSwitchCase="
      TYPE_ENDPOINTS.SWITCH_X.test(endpoint.id) ||
      endpoint.id == TYPE_ENDPOINTS.COOLER ||
      ((TYPE_ENDPOINTS.RELAY_X.test(endpoint.id) ||
      TYPE_ENDPOINTS.COMMAND_RELAY_X.test(endpoint.id) ||
      endpoint.id == TYPE_ENDPOINTS.POWER || endpoint.id == TYPE_ENDPOINTS.POWER2 || endpoint.id == TYPE_ENDPOINTS.POWER3 ||
      TYPE_ENDPOINTS.POWERX.test(endpoint.id) || TYPE_ENDPOINTS.POWER_X.test(endpoint.id)) &&
        (node.scheme?.code !== TYPE_NODE.SMART_LIGHT_PUSH_WP2 && node.scheme?.code !== TYPE_NODE.SMART_LIGHT_MIRROR_WP2 && !TYPE_NODE.SMART_TOUCH.test(node.scheme?.code))) ||
      endpoint.id == TYPE_ENDPOINTS.ON ||
      endpoint.id.indexOf(TYPE_ENDPOINTS.STATUS_SWITCH + '_') == 0 ||
      endpoint.id == TYPE_ENDPOINTS.STATE ||
      endpoint.id == TYPE_ENDPOINTS.BLOCK">
      <app-switch-actuator [node]="node" [endpoint]="endpoint" (endpointChange)="onChange($event)" [status]="status">
      </app-switch-actuator>
    </div>
    <div
      *ngSwitchCase="
        (TYPE_ENDPOINTS.RELAY_X.test(endpoint.id) || TYPE_ENDPOINTS.POWERX.test(endpoint.id) || TYPE_ENDPOINTS.POWER_X.test(endpoint.id) || endpoint.id == TYPE_ENDPOINTS.POWER || endpoint.id == TYPE_ENDPOINTS.POWER2 || endpoint.id == TYPE_ENDPOINTS.POWER3) &&
          (node.scheme?.code === TYPE_NODE.SMART_LIGHT_PUSH_WP2 || node.scheme?.code === TYPE_NODE.SMART_LIGHT_MIRROR_WP2 || TYPE_NODE.SMART_TOUCH.test(node.scheme?.code))">
      <app-push-actuator [node]="node" [endpoint]="endpoint" (endpointChange)="onChange($event)" [status]="status">
      </app-push-actuator>
    </div>
    <div *ngSwitchCase="endpoint.id == TYPE_ENDPOINTS.FAN">
      <app-fan-actuator [node]="node" [endpoint]="endpoint" (endpointChange)="onChange($event)" [status]="status">
      </app-fan-actuator>
    </div>
    <div *ngSwitchCase="
      endpoint.id == TYPE_ENDPOINTS.FAN_SPEED ||
      endpoint.id.indexOf(TYPE_ENDPOINTS.FAN_SPEED + '_') == 0">
      <app-fan-speed-actuator [node]="node" [endpoint]="endpoint" (endpointChange)="onChange($event)" [status]="status">
      </app-fan-speed-actuator>
    </div>
    <div *ngSwitchCase="
      endpoint.id == TYPE_ENDPOINTS.TEMPERATURE_MODE ||
      endpoint.id.indexOf(TYPE_ENDPOINTS.TEMPERATURE_MODE + '_') == 0">
      <app-temperature-mode-actuator [node]="node" [endpoint]="endpoint" (endpointChange)="onChange($event)"
        [status]="status"></app-temperature-mode-actuator>
    </div>
    <div *ngSwitchCase="endpoint.id == TYPE_ENDPOINTS.SYSTEM_MODE">
      <app-system-mode-actuator [node]="node" [endpoint]="endpoint" (endpointChange)="onChange($event)"
        [status]="status"></app-system-mode-actuator>
    </div>
    <div *ngSwitchCase="endpoint.id == TYPE_ENDPOINTS.ECO_MODE">
      <app-eco-mode-actuator [node]="node" [endpoint]="endpoint" (endpointChange)="onChange($event)" [status]="status">
      </app-eco-mode-actuator>
    </div>
    <div *ngSwitchCase="endpoint.id == TYPE_ENDPOINTS.MANUAL_MODE">
      <app-manual-mode-actuator [node]="node" [endpoint]="endpoint" (endpointChange)="onChange($event)"
        [status]="status"></app-manual-mode-actuator>
    </div>
    <div *ngSwitchCase="endpoint.id == TYPE_ENDPOINTS.DIMMER">
      <app-dimmer-actuator [status]="status" [node]="node" [endpoint]="endpoint" (endpointChange)="onChange($event)">
      </app-dimmer-actuator>
    </div>
    <div class="position-relative" *ngSwitchCase="
      endpoint.id == TYPE_ENDPOINTS.BRIGHTNESS ||
      endpoint.id == TYPE_ENDPOINTS.BRIGHTNESS_ALT ||
      TYPE_ENDPOINTS.CHANNEL_X.test(endpoint.id) ||
      TYPE_ENDPOINTS.VALUE_X.test(endpoint.id) ||
      endpoint.id == TYPE_ENDPOINTS.GENERIC_DIMMER ||
      endpoint.id.indexOf(TYPE_ENDPOINTS.GENERIC_DIMMER + '_') == 0 ||
      endpoint.id == TYPE_ENDPOINTS.FIRST_CAPITAL_DIMMER ||
      endpoint.id == TYPE_ENDPOINTS.COLOR_TEMPERATURE ||
      endpoint.id == TYPE_ENDPOINTS.COLOR_TEMPERATURE_ALT">
      <app-brightness-actuator [node]="node" [endpoint]="endpoint" (endpointChange)="onChange($event)"
        [status]="status"></app-brightness-actuator>
    </div>
    <div *ngSwitchCase="endpoint.id == 'ct'">
      <app-color-temperature-actuator [node]="node" [endpoint]="endpoint" (endpointChange)="onChange($event)"
        [status]="status"></app-color-temperature-actuator>
    </div>
    <div *ngSwitchCase="endpoint.id == 'hue'">
      <app-hue-actuator [node]="node" [endpoint]="endpoint" (endpointChange)="onChange($event)" [status]="status">
      </app-hue-actuator>
    </div>
    <div *ngSwitchCase="endpoint.id == 'sat'">
      <app-saturation-actuator [node]="node" [endpoint]="endpoint" (endpointChange)="onChange($event)"
        [status]="status"></app-saturation-actuator>
    </div>
    <div *ngSwitchCase="
      endpoint.id == TYPE_ENDPOINTS.TEMPERATURE_CORRECTION ||
      endpoint.id == TYPE_ENDPOINTS.HUMIDITY_CORRECTION ||
      endpoint.id == TYPE_ENDPOINTS.WARNING_TEMPERATURE">
      <app-temperature-correction-actuator [node]="node" [endpoint]="endpoint" (endpointChange)="onChange($event)"
        [status]="status" [connected]="connected"></app-temperature-correction-actuator>
    </div>
    <div *ngSwitchCase="
      endpoint.id == TYPE_ENDPOINTS.TARGET_TEMPERATURE ||
      endpoint.id.indexOf(TYPE_ENDPOINTS.TARGET_TEMPERATURE + '_') == 0">
      <app-temperature-actuator [node]="node" [endpoint]="endpoint" (endpointChange)="onChange($event)"
        [status]="status">
      </app-temperature-actuator>
    </div>
  </div>
</div>