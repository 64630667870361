import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Node } from './../models/node.model';
import { environment } from '../../../environments/environment';

/**
 * Service to use the API nodes related calls.
 */
@Injectable()
export class NodeService {
  /**
   * Build the component and initialize certain attributes.
   * @param {HttpClient} http Service to make API calls.
   */
  public constructor(private http: HttpClient) { }

  /**
   * Sends a node to store it in the backend.
   * @param {Node} data Node to be stored.
   */
  public create(data: Node) {
    return this.http.post(`${environment.apiUrl}/devices`, data);
  }
  /**
   * Updates a node in the backend.
   * @param {string} id Node ID.
   * @param {Node} data Node to be updated.
   */
  public update(id: string, data: any) {
    return this.http.put(`${environment.apiUrl}/devices/${id}`, data);
  }
  /**
   * Deletes a node in the backend.
   * @param {string} id Node ID.
   */
  public delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/devices/${id}`);
  }
  /**
   * Gets one Node from the backend.
   * @param {string} id Node ID.
   */
  public show(id: string, params: any = {}) {
    return this.http.get(`${environment.apiUrl}/devices/${id}`, { params: params });
  }
  /**
   * Gets Nodes filtering by certain parameters.
   * @param params Parameters to filter the query.
   */
  public showAll(params: any = {}) {
    return this.http.get(`${environment.apiUrl}/devices`, { params: params });
  }
  /**
   * Gets Nodes filtering by certain parameters.
   * @param params Parameters to filter the query.
   */
  public showAllLite(params: any = {}) {
    return this.http.get(`${environment.apiUrl}/devices/lite`, { params: params });
  }

  /**
   * Get a list of node types
   * @param params Parameters to filter the query.
   */
  public getTypes(params: any = {}) {
    return this.http.get(`${environment.apiUrl}/devices/schemes`, { params: params });
  }

  public reboot(id: string) {
    return this.http.post(`${environment.apiUrl}/devices/${id}/reboot`, {});
  }

  public rebootGateway(id: string, acs: any = {}) {
    return this.http.post(`${environment.apiUrl}/devices/${id}/acs/reboot`, acs);
  }
  public factoryResetGateway(id: string, acs: any = {}) {
    return this.http.post(`${environment.apiUrl}/devices/${id}/acs/factory`, acs);
  }
  public unlock(id: string, params: any = {}) {
    return this.http.post(`${environment.apiUrl}/devices/${id}/unlock`, params);
  }

  public report(params: any = {}) {
    return this.http.get(`${environment.apiUrl}/devices/report`, { params: params });
  }
  public reportEndpoint(params: any = {}) {
    return this.http.get(`${environment.apiUrl}/devices/reportEndpoint`, { params: params });
  }

  public disconnected() {
    return this.http.get(`${environment.apiUrl}/devices/disconnected`);
  }

  public checkAssignDevice(mac: string) {
    return this.http.post(`${environment.apiUrl}/devices/checkAssign`, { mac: mac });
  }

  public roomNodes(mac: string) {
    return this.http.get(`${environment.apiUrl}/devices/roomNodes`, { params: { mac: mac }});
  }
}
