import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationModalComponent implements OnInit {
  public icon: string;
  @Input()
  settings: any = {};
  @Output()
  close: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.icon = this.settings.icon;
  }

  public hide() {
    this.close.emit();
  }

  public read() {

  }
}
