import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NodeService } from '../../../shared/services/node.service';

@Component({
  selector: 'app-input-nodes-server',
  templateUrl: './input-nodes-server.component.html',
  styleUrls: ['./input-nodes-server.component.scss']
})
export class InputNodesServerComponent implements OnInit {

  /**
   * Selected values in select box.
   */
  public selectedValues: any = [];

  public items: any = [];

  /**
   * Indicates whether the select box is disabled or not.
   */
  public disableSelector = false;

  /**
   * Manages changes related to disableSelector.
   */
  @Input() disabled: boolean;
  /**
   * Form where the select box is placed.
   */
  @Input() group: FormGroup;
  /**
   * The name of the form control used as select box.
   */
  @Input() name: string;
  /**
   * The code of the node type
   */
  @Input() code: string;
  /**
   * The id of the building
   */
  @Input() building: string;

  private items$: any;

  constructor(
    private nodeService: NodeService
  ) { }

  ngOnInit() {
    if (this.group) {
      this.group.controls[this.name].valueChanges.subscribe(
        (selectedValue) => {
          this.selectedValues = selectedValue
        }
      );
    }
  }

  /**
   * Turns the select box into disabled or enabled.
   * @param {SimpleChanges} changes Changes performed in the component.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled) {
      if (changes.disabled.currentValue !== undefined) {
        this.disableSelector = changes.disabled.currentValue;
      }
    }
    if (changes.code) {
      if (changes.code.currentValue !== undefined) {
        this.loadItems();
      }
    }
  }

  loadItems() {
    if(this.group.controls['action'].value == 'add')
    {
      this.clearItems();
    }
    if(this.code && this.building)
    {
      console.log(this.code, this.building);
      this.items$ = this.nodeService.showAll({
        code: this.code,
        building: this.building
      }).subscribe(data => {
        this.items = data['docs'];
      });
    }
  }

  clearItems() {
    this.group.get(this.name).patchValue('');
  }

  /**
   * Unsubscribe to all subscriptions.
   */
  ngOnDestroy() {
    if (this.items$) {
      this.items$.unsubscribe();
    }
  }

}
