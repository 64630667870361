import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-datatable-filter-select',
  templateUrl: './datatable-filter-select.component.html',
  styleUrls: ['./datatable-filter-select.component.scss']
})
export class DatatableFilterSelectComponent implements OnInit, OnDestroy, OnChanges {
  private filter$: Subscription;
  public filterData: any = [];
  public disabled = false;

  @Input()
  filter: any;
  @Input()
  enable: any;
  @Output()
  filtered = new EventEmitter();
  @Output()
  linksTo = new EventEmitter();

  constructor(private http: HttpClient, private translate: TranslateService) { }

  ngOnInit() {
    if (this.filter.dataFrom === undefined) {
      this.filter.dataFrom = 'docs';
    }

    if (this.filter.dependsOn) {
      this.disabled = true;
    } else {
      if (this.filter.localData) {
        this.filterData = this.filter.localData;
      } else {
        this.filter$ = this.http
          .get(`${environment.apiUrl}/${this.filter.apiSelf}`, {
            params: { limit: '100', sort: 'name' }
          })
          .subscribe(data => {
            if (this.filter.dataFrom !== null) {
              this.filterData = data[this.filter.dataFrom];
              if (this.filter.null) {
                this.filterData.unshift({name: this.translate.instant(this.filter.translation_key + '.no'), _id: 0});
              }
            } else {
              this.filterData = Object.keys(data).map(key => {
                if (this.filter._idParam) {
                  data[key]._id = data[key][this.filter._idParam];
                }

                if (this.filter.translateName) {
                  if ((typeof (data[key].name) !== 'undefined') && (data[key].name !== null) && (data[key].name !== '')) {
                    data[key].name = this.translate.instant(data[key].name);
                  }
                }

                return data[key];
              });

              if (this.filter.filterFunction) {
                this.filterData = this.filterData.filter(this.filter.filterFunction);
              }
            }
          });
      }
    }
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.enable.currentValue) {
      if (change.enable.currentValue[0] === this.filter.name) {
        if (change.enable.currentValue[3]) {
          if (change.enable.currentValue[3] !== '0') {
            this.disabled = false;
            this.filterData = [];
            if (this.filter.localData) {
              this.filterData = this.filter.localData;
            } else {
              const key = change.enable.currentValue[1];
              const params = { limit: '100', sort: 'name' };
              params[key] = change.enable.currentValue[3];
              this.filter$ = this.http
                .get(`${environment.apiUrl}/${this.filter.apiSelf}`, {
                  params: params
                })
                .subscribe(data => {
                  this.filterData = data['docs'];
                });
            }
          }
        } else {
          this.disabled = true;
          this.filter$.unsubscribe();
          this.filterData = [];
        }
      } else if (change.enable.currentValue[0] === this.filter.dependsOn && !change.enable.currentValue[3]) {
        this.disabled = true;
        if (this.filter$) {
          this.filter$.unsubscribe();
        }
        this.filterData = [];
      }
    }
  }

  onChange(ev: any) {
    if (this.filter.linksTo) {
      this.linksTo.emit([this.filter.linksTo, this.filter.apiFilter]);
      this.filtered.emit(ev);
    } else {
      this.linksTo.emit([null, this.filter.apiFilter]);
      this.filtered.emit(ev);
    }
  }

  ngOnDestroy() {
    if (this.filter$) {
      this.filter$.unsubscribe();
    }
  }
}
