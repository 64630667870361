import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Building } from './../models/building.model';
import { environment } from '../../../environments/environment';


@Injectable()
export class BuildingService {
  constructor(private http: HttpClient) {}

  create(data: Building) {
    return this.http.post(`${environment.apiUrl}/buildings`, data);
  }
  update(id: string, data: Building) {
    return this.http.put(`${environment.apiUrl}/buildings/${id}`, data);
  }
  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/buildings/${id}`);
  }
  show(id: string) {
    return this.http.get(`${environment.apiUrl}/buildings/${id}`);
  }
  showAll(params: any = {}) {
    return this.http.get(`${environment.apiUrl}/buildings`, { params: params });
  }
}
