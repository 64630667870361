<div id="notification-modal" class="modal-content">
  <div class="modal-body">
    <div class="row">
      <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="notification-content">
      <div class="icon-background" (click)="read()"></div>
      <div class="icon-background animated-background"></div>
      <span class="icon-gz-{{icon}}" (click)="read()"></span>
    </div>
  </div>
</div>