import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ElementRef,
  ViewChildren,
  QueryList,
  ViewChild
} from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DevicetypeService } from 'app/shared/services/devicetype.service';
import { DeviceType } from 'app/shared/models/devicetype.model';
import { NodeService } from 'app/shared/services/node.service';
import { Node } from 'app/shared/models/node.model';
import '@google/model-viewer/dist/model-viewer';

@Component({
  selector: 'app-map-devices',
  templateUrl: './map-devices.component.html',
  styleUrls: ['./map-devices.component.scss']
})
export class MapDevicesComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() disabled: boolean;
  @Input() section: string;
  @Input() filter: any;
  @Input() nmaps: number;
  @Input() input_maps: string;
  @Input() input_items: string;
  @Input() input_nmaps: string;
  @Input() model: string;

  public maps: { path: any, showClear: boolean }[] = [];
  public modelFile: { path: any, showClear: boolean };
  public apiUrl = environment.baseUrl;
  /**
   * List of devices in the select box.
   */
  public items: any[] = [];
  public totalItems = 0;
  /**
   * Carries { _id, name } values selected.
   */
  private selectedItems: any[] = [];
  public selectedValues: string[];
  private deviceTypes: DeviceType[];
  /**
   * Contains the list of devices mapped.
   */
  private items_positions: any[] = [];
  public mappedDevices: any[] = [];
  public currentTab = 0;
  /**
   * Variable to paginate the devices server response.
   */
  public currentPage = 1;
  /**
   * Total number of pages.
   */
  public totalPages = 0;
  /**
   * Indicates whether the component is loading the devices.
   */
  public loading = false;

  private serviceSubscriptions: Subscription[] = [];
  private all$: Subscription;
  private deviceTypes$: Subscription;

  @ViewChildren('fileName') private fileNames: QueryList<ElementRef>;
  @ViewChild('modelFileName') modelFileName: ElementRef;

  public constructor(
    private toastr: ToastrService,
    private deviceTypeService: DevicetypeService,
    private nodeService: NodeService,
    private formBuilder: FormBuilder
  ) { }

  public ngOnInit() {
    if (this.form) {
      this.deviceTypes$ = this.deviceTypeService
        .showAll()
        .subscribe((res: any) => {
          this.deviceTypes = res.docs;
          if (this.form.controls[this.model]) {
            const model = this.form.controls[this.model].value;
            if (model && model.filename) {
              this.modelFile = {
                path: `${this.apiUrl}/assets/${this.section}/${model.filename}`,
                showClear: true
              }
            } else {
              this.modelFile = {
                path: '',
                showClear: false
              }
            }
          }
          if (this.form.controls[this.input_maps]) {
            const formArray = this.form.controls[this.input_maps].value as FormArray;
            for (let index = 0; index < this.nmaps; index++) {
              const element = formArray[index];
              if (element && element.filename) {
                this.maps.push({
                  path: `${this.apiUrl}/assets/${this.section}/${element.filename}`,
                  showClear: true
                });
              } else {
                this.maps.push({
                  path: '',
                  showClear: false
                });
              }
            }
          }
          if (this.form.controls[this.input_items] && this.form.controls[this.input_items].value) {
            this.items_positions = this.form.controls[this.input_items].value;
          }
          this.initSelect();
        });
      if (this.form.controls[this.input_nmaps]) {
        this.form.controls[this.input_nmaps].valueChanges.subscribe(nmaps => {
          if (nmaps >= 1) {
            const result = this.nmaps - nmaps;
            if (result < 0) {
              const formArray = this.form.controls[this.input_maps] as FormArray;
              for (let index = 0; index > result; index--) {
                formArray.push(this.formBuilder.group({
                  filename: ''
                }));
                this.maps.push({
                  path: '',
                  showClear: false
                });
              }
            } else if (result > 0) {
              const formArray = this.form.controls[this.input_maps] as FormArray;
              for (let index = 0; index < result; index++) {
                formArray.removeAt(formArray.length - 1);
                this.maps.pop();
              }
            }
            this.nmaps = nmaps;
          }
        });
      }
    }
  }

  public onFileChange(event: any, index: number) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const mimeType = file.type;

      if (mimeType.match(/image\/*/) == null) {
        this.toastr.error('No image');
        return;
      }

      try {
        const reader = new FileReader();
        reader.onload = _event => {
          this.maps[index].path = reader.result as string;
        };
        reader.readAsDataURL(file);

        const form_maps = this.form.get(this.input_maps).value as FormArray;
        form_maps[index].filename = file;
      } catch (e) { }
      const fileNames = this.fileNames.toArray();
      fileNames[index].nativeElement.value = file.name;
      this.maps[index].showClear = true;
    }
  }

  public clearFile(index: number) {
    const form_maps = this.form.get(this.input_maps).value as FormArray;
    form_maps[index].filename = '';
    const fileNames = this.fileNames.toArray();
    fileNames[index].nativeElement.value = '';
    this.maps[index].showClear = false;
    this.maps[index].path = '';
  }

  private initSelect() {
    if (this.items_positions.length !== 0) {
      this.items_positions.forEach((element, index: number) => {
        this.serviceSubscriptions.push(
          this.nodeService.show(element.device).subscribe({
            next: (device: any) => {
              let index: number, color: string, image: string;
              for (index = 0; index < this.deviceTypes.length && this.deviceTypes[index].code !== device.scheme.code; index++) { }
              if (index < this.deviceTypes.length) {
                color = this.deviceTypes[index].color;
                image = this.deviceTypes[index].image;
              }
  
              const name = device.display_name && device.display_name !== '' ? device.display_name : device.name;
              this.selectedItems.push({
                _id: device._id,
                name: name,
                display_name: device.display_name,
                scheme: {
                  code: device.scheme.code
                },
                active: device.active
              });
              this.mappedDevices.push({
                _id: device._id,
                name: name,
                color: color,
                image: image,
                coordinates: [element.coordinates.left + 'px', element.coordinates.top + 'px'],
                plan: element.plan ? element.plan : 0,
                active: device.active
              });
            },
            error: (error: any) => {
              console.error(error);
              if (error.status === 404) {
                this.items_positions.splice(index, 1);
                this.form.controls[this.input_items].setValue(this.items_positions, { emitModelToViewChange: false });
              }
            }
          })
        );
      });
    }
    this.all$ = this.nodeService.showAll(this.filter).subscribe((res: any) => {
      this.totalItems = res.total;
      this.totalPages = res.pages;
      this.items = this.selectedItems;
      this.selectedValues = this.selectedItems.map(item => item.name);
      this.refreshItems(res.docs);
    });
  }

  public onTabClick(index: number) {
    this.currentTab = index;
  }

  public onAdd(event: any) {
    let index: number;
    for (
      index = 0;
      index < this.deviceTypes.length &&
      this.deviceTypes[index].code !== event.scheme.code;
      index++
    ) { }
    if (index < this.deviceTypes.length) {
      event.color = this.deviceTypes[index].color;
      event.image = this.deviceTypes[index].image;
    } else {
      event.color = 'grey';
      event.image = '';
    }

    const name = event.display_name && event.display_name !== '' ? event.display_name : event.name;
    this.selectedValues.push(event._id);
    this.selectedItems.push({
      _id: event._id,
      name: name,
      display_name: event.display_name,
      scheme: {
        code: event.scheme.code
      },
      active: event.active
    });
    this.mappedDevices.push({
      _id: event._id,
      name: name,
      color: event.color,
      image: event.image,
      coordinates: [0, 0],
      plan: this.currentTab,
      active: event.active
    });
    this.items_positions.push({
      device: event._id,
      coordinates: {
        left: 0,
        top: 0
      },
      plan: this.currentTab
    });
    this.form.controls[this.input_items].setValue(this.items_positions, { emitModelToViewChange: false });
  }

  public onDragEnded(item: any) {
    const device_id = item.source.element.nativeElement.attributes.id.value;
    const item_position = $('#' + device_id).position();
    const new_position = {
      coordinates: {
        left: item_position.left,
        top: item_position.top,
      },
      device: device_id,
      plan: this.currentTab
    };

    let index: number;
    for (
      index = 0;
      index < this.items_positions.length &&
      this.items_positions[index].device !== new_position.device;
      index++
    ) { }
    if (index < this.items_positions.length) {
      this.items_positions[index] = new_position;
    } else {
      this.items_positions.push(new_position);
    }
    this.form.controls[this.input_items].setValue(this.items_positions, { emitModelToViewChange: false });
  }

  public onRemove(event: any) {
    const index = this.items_positions.findIndex(
      item => item.device === event.value._id
    );
    const selectedItemIndex = this.selectedItems.findIndex(
      item => item._id === event.value._id
    );
    this.items_positions.splice(index, 1);
    this.selectedValues.splice(index, 1);
    this.selectedItems.splice(selectedItemIndex, 1);
    this.mappedDevices.splice(index, 1);

    if (this.selectedValues.length === 0) {
      this.items = [];
      this.currentPage = 1;
      let params = {
        page: 1,
        ...this.filter
      };
      this.all$.unsubscribe();
      this.all$ = this.nodeService
        .showAll(params)
        .subscribe((devices: any) => {
          this.totalItems = devices.total;
          this.items = devices.docs.map((device: Node) => {
            const name = device.display_name && device.display_name !== '' ? device.display_name : device.name;
            return {
              _id: device._id,
              name: name,
              display_name: device.display_name,
              scheme: {
                code: device.scheme.code
              },
              active: device.active
            };
          });
        });
    }
    this.form.controls[this.input_items].setValue(this.items_positions, { emitModelToViewChange: false });
  }

  public onClear() {
    this.items_positions = [];
    this.selectedValues = [];
    this.selectedItems = [];
    this.mappedDevices = [];

    this.items = [];
    this.currentPage = 1;
    let params = {
      page: 1,
      ...this.filter
    };
    this.all$.unsubscribe();
    this.all$ = this.nodeService
      .showAll(params)
      .subscribe((devices: any) => {
        this.totalItems = devices.total;
        this.items = devices.docs.map((device: Node) => {
          const name = device.display_name && device.display_name !== '' ? device.display_name : device.name;
          return {
            _id: device._id,
            name: name,
            display_name: device.display_name,
            scheme: {
              code: device.scheme.code
            },
            active: device.active
          };
        });
      });
    this.form.controls[this.input_items].setValue(this.items_positions, { emitModelToViewChange: false });
  }

  public backgroundImageUrl(image: string) {
    if (image) {
      return `url("${this.apiUrl}/assets/devicetypes/${image}")`;
    }
    return null;
  }

  /**
   * Takes more devices from the server when the user scrolls down to the bottom of the select box options, using the next page too.
   */
  public fetchMore() {
    if (this.currentPage >= this.totalPages) {
      return;
    }
    this.currentPage++;
    this.loadData(this.currentPage);
  }

  /**
   * Loads data into the select box refreshing it and filtering through certain parameters.
   * @param {string} search Term to search.
   * @param {string} page Number of the page to take.
   */
  private loadData(page?: number) {
    let params = {
      page: page || 1,
      ...this.filter
    };

    this.loading = true;
    this.all$ = this.nodeService.showAll(params).subscribe((res: any) => {
      this.loading = false;
      this.totalItems = res.total;
      this.totalPages = res.pages;
      this.refreshItems(res.docs);
    });
  }

  private refreshItems(new_items: any[]) {
    for (let index = 0; index < new_items.length; index++) {
      const element = new_items[index];
      const selectedItemsIndex = this.selectedItems.findIndex(selectedItem => selectedItem._id === element._id);
      if (selectedItemsIndex === -1) {
        this.items.push({
          _id: element._id,
          name: element.display_name && element.display_name !== '' ? element.display_name : element.name,
          display_name: element.display_name,
          scheme: {
            code: element.scheme.code
          },
          active: element.active
        });
      }
    }
  }

  public onModelFileChange(event: any) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      try {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = _event => {
          this.modelFile.path = reader.result;
        };

        const form_model = this.form.get(this.model).value;
        form_model.filename = file;
      } catch (e) { }
      this.modelFileName.nativeElement.value = file.name;
      this.modelFile.showClear = true;
    }
  }

  public clearModelFile() {
    const form_model = this.form.get(this.model).value;
    form_model.filename = '';
    this.modelFileName.nativeElement.value = '';
    this.modelFile.showClear = false;
    this.modelFile.path = '';
  }

  public ngOnDestroy() {
    if (this.serviceSubscriptions.length > 0) {
      for (let index = 0; index < this.serviceSubscriptions.length; index++) {
        this.serviceSubscriptions[index].unsubscribe();
      }
    }
    if (this.all$) {
      this.all$.unsubscribe();
    }
    if (this.deviceTypes$) {
      this.deviceTypes$.unsubscribe();
    }
  }
}
