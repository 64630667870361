import { Location } from '@angular/common';
import { AuthService } from './../services/auth.service';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class HasTagsGuardService implements CanActivate {

  constructor(private auth: AuthService, private location: Location) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const currentUser = this.auth.getAuthUser();
    const tagsGroup = route.data.tagsGroup;
    const tags = currentUser.groups[tagsGroup];

    if (currentUser.is_super_admin || currentUser.is_admin || tags) {
      return true;
    }
    this.location.back();
    return false;
  }
}
