<div *ngIf="!status" style="width: 100%; height: 100%; position: absolute; z-index: 1;"></div>
<div class="row" *ngIf="endpoint.current">
  <h4>{{endpoint.display_name || endpoint.name}}</h4>
  <div class="col-12">
    <div class="btn-group">
      <button [class.btn-primary]="endpoint.current === 'Low'" [class.btn-default]="endpoint.current !== 'Low'"
        [disabled]="endpoint.current == 'Off'" (click)="onChangeEndpoint('Low')" class="btn btn-lg">Low
      </button>
      <button [class.btn-primary]="endpoint.current === 'Medium'" [class.btn-default]="endpoint.current !== 'Medium'"
        [disabled]="endpoint.current == 'Off'" (click)="onChangeEndpoint('Medium')" class="btn btn-lg">Medium
      </button>
      <button [class.btn-primary]="endpoint.current === 'High'" [class.btn-default]="endpoint.current !== 'High'"
        [disabled]="endpoint.current == 'Off'" (click)="onChangeEndpoint('High')" class="btn btn-lg">High
      </button>
      <button [class.btn-primary]="endpoint.current === 'Auto'" [class.btn-default]="endpoint.current !== 'Auto'"
        [disabled]="endpoint.current == 'Off'" (click)="onChangeEndpoint('Auto')" class="btn btn-lg">Auto
      </button>
    </div>
  </div>
</div>
<br />