import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Constants } from '../../../shared/constants';

/**
 * Component used to represent certain types of nodes.
 */
@Component({
  selector: 'app-actuator-sensor',
  templateUrl: './actuator-sensor.component.html',
  styleUrls: ['./actuator-sensor.component.scss']
})
export class ActuatorSensorComponent implements OnInit {
  /**
   * Indicates whether the actuator is connected or not.
   */
  @Input() public status: boolean;
  /**
   * All endpoints whose dirs aren't input.
   */
  @Input() endpoints: any;
  /**
   * Contains the node ID.
   */
  @Input() device: any;
  /**
   * Used to emit an object with updated data related to the actuation.
   */
  @Output() setAction: EventEmitter<any> = new EventEmitter();

  /**
   * List with the types of endpoints list.
   */
  public TYPE_ENDPOINTS: any = Constants.TYPE_ENDPOINTS;

  /**
   * Builds the component.
   */
  public constructor() { }

  /**
   * Displays through the console, the node endpoints, ID and status.
   */
  public ngOnInit() {
    console.log('ENDPOINTS:::', this.endpoints);
    console.log('DEVICE:::', this.device, this.status);
  }

  /**
   * Emits an object with the value of the actuation when it has been changed.
   * @param data Information from the actuation.
   */
  onChange(data: any) {
    if (data.updated) {
      const objectSave: any = {
        device: this.device,
        endpoint: data._id,
        value: data.value
      };
      console.log('NODE AUTO GUARDAR:::>', objectSave);
      this.setAction.emit(objectSave);
    } else {
      console.error('Endpoint no updated');
    }
  }
}
