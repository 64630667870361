<div class="row">
  <div class="form-group col-12">
    <label for="map-devices-select">{{ 'devices.' | translate }}</label>
    <span>
      <span>
        <ng-select [disabled]="currentTab === nmaps" [items]="items" [multiple]="true" [virtualScroll]="true" [hideSelected]="true" [loading]="loading"
          class="input-tags" (add)="onAdd($event)" (remove)="onRemove($event)" (clear)="onClear()"
          (scrollToEnd)="fetchMore()" bindLabel="name" [(ngModel)]="selectedValues"
          [ngModelOptions]="{standalone: true}">
          <ng-template ng-header-tmp>
            <small class="form-text text-muted">{{ 'Loaded' | translate }} {{ items.length }} {{ 'of' | translate }}
              {{ totalItems }}</small>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <b>{{ index + 1 }}</b> - {{ item.name }}
          </ng-template>
        </ng-select>
      </span>
    </span>
  </div>
</div>

<div class="row" style="overflow: auto">
  <div class="form-group col-12" style="margin-top: 10px">
    <div class="tabs-container">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item" [ngClass]="{ 'active': currentTab === i }" *ngFor="let map of maps; let i = index"
          [attr.data-index]="i">
          <a class="nav-link" data-toggle="tab" (click)="onTabClick(i)">{{ 'plan' | translate }} #{{ i + 1 }}</a>
        </li>
        <li *ngIf="model" class="nav-item" [ngClass]="{ 'active': currentTab === nmaps }" [attr.data-index]="nmaps">
          <a class="nav-link" data-toggle="tab" (click)="onTabClick(nmaps)">{{ 'model' | translate }}</a>
        </li>
      </ul>
      <div class="tab-content">
        <div [formGroup]="form" class="tab-pane" [ngClass]="{ 'active': i === currentTab, 'show': i === currentTab }"
          role="tabpanel" *ngFor="let map of maps; let i = index">
          <div class="panel-body">
            <span *ngIf="map.path !== ''">
              <div class="map-container">
                <ng-container *ngFor="let device of mappedDevices">
                  <div [attr.id]="device._id" class="map-device" *ngIf="device.plan === i" [ngStyle]="{
                      'background-color': device.color,
                      'background-image': backgroundImageUrl(device.image),
                      'left': device.coordinates[0],
                      'top': device.coordinates[1]
                    }" [ngClass]="{ 'connected': device.active }" [attr.tooltip]="device.name"
                    (cdkDragEnded)="onDragEnded($event)" cdkDragBoundary=".map-container" cdkDrag>
                  </div>
                </ng-container>
                <img id="map" [src]="map.path" />
              </div>
            </span>

            <div class="input-group" [formArrayName]="input_maps" style="margin-top: 10px">
              <label class="input-group-btn">
                <span class="btn btn-primary" [formGroupName]="i">
                  {{ "browse" | translate }}...
                  <input type="file" class="form-control" style="display: none" (change)="onFileChange($event, i)" />
                </span>
              </label>
              <input #fileName type="text" class="form-control" readonly
                placeholder="{{ 'noFileSelected' | translate }}" />
              <label class="input-group-btn">
                <button *ngIf="map.showClear" type="button" class="btn btn-default" (click)="clearFile(i)">
                  {{ "clearFile" | translate }}
                </button>
              </label>
            </div>
          </div>
        </div>
        <div *ngIf="model" [formGroup]="form" class="tab-pane" [ngClass]="{ 'active': currentTab === nmaps, 'show': currentTab === nmaps }" role="tabpanel">
          <div class="panel-body">
            <div *ngIf="modelFile?.path !== ''" class="map-container">
              <model-viewer [src]="modelFile?.path" alt="{{ 'building.altModel' | translate }}" environment-image="neutral" auto-rotate camera-controls>
              </model-viewer>
            </div>
            <div class="input-group" style="margin-top: 10px">
              <label class="input-group-btn">
                <span class="btn btn-primary">
                  {{ "browse" | translate }}...
                  <input type="file" class="form-control" style="display: none"
                    (change)="onModelFileChange($event)" />
                </span>
              </label>
              <input #modelFileName type="text" class="form-control" readonly
                placeholder="{{ 'noFileSelected' | translate }}" />
              <label class="input-group-btn">
                <button *ngIf="modelFile?.showClear" type="button" class="btn btn-default" (click)="clearModelFile()">
                  {{ "clearFile" | translate }}
                </button>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>