import { GroupService } from './../services/group.service';
import { RoomService } from './../services/room.service';
import { BuildingService } from './../services/building.service';
import { User } from './../models/user.model';
import { NodeService } from './../services/node.service';
import { of } from 'rxjs';
import { UserService } from './../services/user.service';
import { AuthService } from './../services/auth.service';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ApartmentService } from '../services/apartment.service';
import { map } from 'rxjs/operators';
import { KeyService } from '../services/key.service';

@Injectable()
export class OrganizationGuardService implements CanActivate {

  constructor(
    private auth: AuthService,
    private location: Location,
    private userService: UserService,
    private nodeService: NodeService,
    private buildingService: BuildingService,
    private apartmentService: ApartmentService,
    private roomService: RoomService,
    private groupService: GroupService,
    private keyService: KeyService
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const currentUser = this.auth.getAuthUser();
    if (currentUser.is_super_admin) {
      return true;
    }

    const id = route.params.id;
    const service = route.data.service;
    switch (service) {
      case 'apartments':
        return this.itemService(this.apartmentService, id, currentUser);
      case 'buildings':
        return this.itemService(this.buildingService, id, currentUser);
      case 'nodes':
        return this.itemService(this.nodeService, id, currentUser);
      case 'rooms':
        return this.itemService(this.roomService, id, currentUser);
      case 'subscribers':
      case 'administrators':
        return this.itemService(this.userService, id, currentUser);
      case 'tags':
        return this.itemService(this.groupService, id, currentUser);
      case 'keys':
        return this.itemService(this.keyService, id, currentUser);
      default:
        return false;
    }
  }

  private itemService(service: any, itemId: string, currentUser: User) {
    return service.show(itemId).pipe(map(
      (item: any) => {
        if (currentUser.organization === item.organization) {
          return true;
        }
        this.location.back();
        return false;
      },
      err => {
        this.location.back();
        return of(false);
      })
    );
  }
}
