import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { io } from 'socket.io-client';
import { Constants } from './shared/constants';


/**
 * Service to provide a backend connection through web sockets.
 */
@Injectable()
export class SocketService {
  /**
   * Web socket.
   */
  private socket;

  private tokenKey = 'localhost:token';
  private userToken: string;

  /**
   * Build the service.
   */
  constructor() { }

  /**
   * Initialize the web socket.
   */
  public initSocket(): void {
    // Gets the backend URL from /src/environments/
    this.userToken = localStorage.getItem(this.tokenKey);
    const socketPath = `${environment.baseUrl}`;
    if (!this.socket) {
      const token = this.userToken;
      this.socket = io(socketPath, { query: { token }, transports: ['websocket'] });
      console.log('SOCKET INIT IN:::>', socketPath, 'WITH TOKEN::> ', token);
    } else {
      console.log('SOCKET IS RUNNING IN:::>', socketPath);
    }
  }

  /**
   * Returns an observable which emits events on measure:created socket event.
   * @returns {Observable}
   */
  public onMeasureCreated(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('measure:created', (data: any) => observer.next(data));
    });
  }

  /**
   * Returns an observable which emits events on device:created socket event.
   * @returns {Observable}
   */
  public onDeviceCreated(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('device:created', (data: any) => observer.next(data));
    });
  }

  /**
   * Returns an observable which emits events on actuation:updated socket event.
   * @returns {Observable}
   */
  public onActuationUpdated(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('actuation:updated', (data: any) => observer.next(data));
    });
  }

  public onError(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('error', (data: string) => observer.next(data));
    });
  }

  public onSession(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('session', (data: string) => observer.next(data));
    });
  }

  public onUpdate(entity: string): Observable<any> {
    switch (entity) {
      case Constants.ENTITIES.gateway:
        return this.onUpdateGateway();
      case Constants.ENTITIES.node:
        return this.onUpdateNode();
    }
  }

  public onUpdateNode(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('node:updated', (data: any) => observer.next(data));
    });
  }

  public onUpdateGateway(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('gateway:updated', (data: any) => observer.next(data));
    });
  }

  public onUpdateTablets(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('tablets:updated', () => observer.next());
    });
  }

  public onNotificationCreated(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('notification:created', (data: any) => observer.next(data));
    });
  }

  public onTest(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('test', (data: any) => observer.next(data));
    })
  }

  public disconnectSocket(): void {
    if (this.socket) {
      console.log('SOCKET DISCONNECTED');
      this.socket.disconnect();
      this.socket = null;
    }
  }
}
