import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy
} from '@angular/core';
import { SocketService } from '../../../../socket.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-hue-actuator',
  templateUrl: './hue-actuator.component.html',
  styleUrls: ['./hue-actuator.component.scss']
})
export class HueActuatorComponent implements OnInit, OnDestroy {
  @Input() endpoint: any;
  /**
   * Current node, parent of the endpoint.
   */
  @Input() node: any;
  @Output() endpointChange: EventEmitter<any> = new EventEmitter();
  @Input() public status: boolean;
  public data: any;
  private ioConnectionActuation$: Subscription;

  constructor(private socketService: SocketService) { }

  public ngOnInit() {
    this.data = { ...this.endpoint };
    if (!this.data.current) {
      this.data.current = 0;
    }

    this.ioConnectionActuation$ = this.socketService
      .onMeasureCreated()
      .subscribe((measure: any) => {
        if (this.node._id === measure.device && this.data._id === measure.endpoint)
        {
          return this.updateMeasure(measure);
        }
        return;
      });
  }

  updateMeasure(measure: any) {
    this.data.current = measure.value;
  }

  public onChangeDimmer(value: any) {
    this.data.current = value;

    this.endpointChange.emit({
      updated: true,
      _id: this.data._id,
      value: this.data.current
    });
  }

  public ngOnDestroy() {
    if (this.ioConnectionActuation$) {
      this.ioConnectionActuation$.unsubscribe();
    }
  }
}
