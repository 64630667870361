import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable()
export class OrganizationService {
  constructor(private http: HttpClient) {}

  create(data: FormData) {
    return this.http.post(`${environment.apiUrl}/organizations`, data);
  }
  update(id: string, data: FormData) {
    return this.http.put(`${environment.apiUrl}/organizations/${id}`, data);
  }
  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/organizations/${id}`);
  }
  show(id: string) {
    return this.http.get(`${environment.apiUrl}/organizations/${id}`);
  }
  showAll(params: any = {}) {
    return this.http.get(`${environment.apiUrl}/organizations`, {
      params: params
    });
  }

  exportDevices(id: string) {
    return this.http.get(`${environment.apiUrl}/organizations/${id}/export/devices`);
  }
}
