import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

import * as _ from 'underscore';
import { Constants } from '../../../shared/constants';

/**
 * Component used to represent smart4 actuators.
 */
@Component({
  selector: 'app-actuator-smart-four',
  templateUrl: './actuator-smart-four.component.html',
  styleUrls: ['./actuator-smart-four.component.scss']
})
export class ActuatorSmartFourComponent implements OnInit {
  /**
   * Indicates whether the actuator is connected or not.
   */
  @Input() public status: boolean;
  /**
   * Endpoints related to UP or DOWN actuations.
   */
  @Input() endpoints: any;
  /**
   * Contains the node ID.
   */
  @Input() device: any;
  /**
   * Complete and blade times.
   */
  @Input() times: any;
  /**
   * Emits an event to create a new smart4 actuation.
   */
  @Output() setAction: EventEmitter<any> = new EventEmitter();

  /**
   * List of endpoint types.
   */
  public TYPE_ENDPOINTS: any = Constants.TYPE_ENDPOINTS;
  /**
   * Endpoint Relay 0 to control FanSpeed Medium.
   */
  public fanSpeedMedium: any;
  /**
   * Endpoint Relay 1 to control FanSpeed High.
   */
  public fanSpeedHigh: any;
  /**
   * Endpoint Relay 2 to control Cooling On.
   */
  public coolingOn: any;
  /**
   * Endpoint Relay 3 to control Cooling Off.
   */
  public coolingOff: any;
  public disable = false;

  /**
   * Builds the component.
   */
  constructor() { }

  /**
   * Initializes both enpoints, UP and DOWN.
   */
  public ngOnInit() {
    const workingMode = _.findWhere(this.endpoints, { id: this.TYPE_ENDPOINTS.WORKING_MODE }) || {};
    if (workingMode.current === 'transparent') {
      this.disable = true;
    }
    this.fanSpeedMedium = _.findWhere(this.endpoints, { id: this.TYPE_ENDPOINTS.RELAY_3 }) || {};
    this.fanSpeedHigh = _.findWhere(this.endpoints, { id: this.TYPE_ENDPOINTS.RELAY_2 }) || {};
    this.coolingOn = _.findWhere(this.endpoints, { id: this.TYPE_ENDPOINTS.RELAY_1 }) || {};
    this.coolingOff = _.findWhere(this.endpoints, { id: this.TYPE_ENDPOINTS.RELAY_0 }) || {};
  }

  changeFanSpeed(value: string) {
    let mediumValue: string;
    let highValue: string;

    switch (value) {
      case 'Medium':
        mediumValue = this.fanSpeedMedium.current !== 'On' ? 'On' : 'Off';
        highValue = 'Off';
        break;
      case 'High':
        mediumValue = 'Off';
        highValue = this.fanSpeedHigh.current !== 'On' ? 'On' : 'Off';
        break;
    }

    this.fanSpeedMedium.current = mediumValue;
    this.fanSpeedHigh.current = highValue;

    // Send Value for Relay_0 (FanSpeed medium)
    this.setAction.emit({ device: this.device, endpoint: this.fanSpeedMedium._id, value: mediumValue, silent: true });
    // Send Value for Relay_1 (FanSpeed high)
    this.setAction.emit({ device: this.device, endpoint: this.fanSpeedHigh._id, value: highValue });
  }

  changeCooling(value: string) {
    let coolingOn: string;
    let coolingOff: string;

    switch (value) {
      case 'On':
        coolingOn = 'On';
        coolingOff = 'Off';
        break;
      case 'Off':
        coolingOn = 'Off';
        coolingOff = 'On';
        break;
    }

    this.coolingOn.current = coolingOn;
    this.coolingOff.current = coolingOff;

    // Send Value for Relay_2 (Cooling On)
    this.setAction.emit({ device: this.device, endpoint: this.coolingOn._id, value: coolingOn, silent: true });
    // Send Value for Relay_3 (Cooling Off)
    this.setAction.emit({ device: this.device, endpoint: this.coolingOff._id, value: coolingOff });
  }
}
