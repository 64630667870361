import {
  Input,
  Output,
  Component,
  OnInit,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { SocketService } from '../../../socket.service';
import { Subscription } from 'rxjs';

/**
 * Component used to manage smartlight switchers.
 */
@Component({
  selector: 'app-actuator-smartlight-switch',
  templateUrl: './actuator-smartlight-switch.component.html',
  styleUrls: ['./actuator-smartlight-switch.component.scss']
})
export class ActuatorSmartlightSwitchComponent implements OnInit, OnDestroy {
  /**
   * Indicates whether the actuator is connected or not.
   */
  @Input() public status: boolean;
  /**
   * All endpoints whose dirs aren't input.
   */
  @Input() endpoints: any;
  /**
   * Contains the node ID.
   */
  @Input() device: any;
  /**
   * Emits the changes on the switch.
   */
  @Output() setAction: EventEmitter<any> = new EventEmitter();

  /**
   * Indicates whether the first switch is activated.
   */
  public activeSwitch1: boolean;
  /**
   * Indicates whether the second switch is activated.
   */
  public activeSwitch2: boolean;
  /**
   * Value of the first switch.
   */
  public switch1: any;
  /**
   * Value of the second switch.
   */
  public switch2: any;
  /**
   * Represents the switch.
   */
  private dimmer: any;
  /**
   * Suscription to the socket event related to the actuation updates.
   */
  private ioConnectionActuation$: Subscription;

  /**
   * Builds the component and initializes the socket service.
   * @param {SocketService} socketService Service to subscribe to actuation updates event.
   */
  constructor(private socketService: SocketService) { }

  /**
   * Initializes the attributes and the socket communication.
   */
  public ngOnInit() {
    console.log('ENDPOINT ENDPOINTS:::', this.endpoints);
    console.log('ENDPOINT DEVICE:::', this.device);
    console.log('ENDPOINT STATUS:::', this.status);

    this.initializeEndpoints(this.endpoints);

    this.ioConnectionActuation$ = this.socketService
      .onMeasureCreated()
      .subscribe((measure: any) => {
        if (this.device === measure.device)
        {
          return this.updateMeasure(measure);
        }
        return;
      });
  }

  /**
   * Updates the value of the switches after they are updated in the backend.
   * @param measure Value of the switches.
   */
  updateMeasure(measure: any) {
    if (measure.device === this.device && measure.endpoint === this.dimmer._id) {
      this.dimmer.current = measure.value;
      this.activeSwitch1 = measure.value[0] ? true : false;
      this.activeSwitch2 = measure.value[1] ? true : false;
      return;
    }
  }

  /**
   * Initializes the switch endpoints.
   * ¿Why are there 3 endpoints when we just need one?
   * @param endpoints List of endpoints.
   */
  initializeEndpoints(endpoints: any) {
    this.switch1 = {
      ...(endpoints.filter(item => item.id === 'sw1')[0] || {})
    };
    this.switch2 = {
      ...(endpoints.filter(item => item.id === 'sw2')[1] || {})
    };
    this.dimmer = { ...(endpoints.filter(item => item.id === 'd')[0] || {}) };

    if (!this.dimmer.current) {
      this.dimmer.current = [];
      this.dimmer.current = [0, 0];
    }
    this.activeSwitch1 = this.dimmer.current[0] ? true : false;
    this.activeSwitch2 = this.dimmer.current[1] ? true : false;
  }

  /**
   * Changes the switch values and emits an event with the new actuation.
   * @param value Indicates whether the switch is off (false) or on (true).
   * @param endpoint Endpoint changed.
   * @param index Number of the switch.
   */
  onChangeEndpoint(value, endpoint, index) {
    if (value) {
      this.dimmer.current[index] = 255;
    } else {
      this.dimmer.current[index] = 0;
    }
    // Update dimmer
    this.setAction.emit({
      device: this.device,
      endpoint: this.dimmer._id,
      value: this.dimmer.current
    });
  }

  /**
   * Removes the connection to the socket.
   */
  public ngOnDestroy() {
    if (this.ioConnectionActuation$) {
      this.ioConnectionActuation$.unsubscribe();
    }
  }
}
