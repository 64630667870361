import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Schedule } from '../models/schedule.model';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  public constructor(private http: HttpClient) { }

  public create(data: Schedule) {
    return this.http.post(`${environment.apiUrl}/schedules`, data);
  }
  public update(id: string, data: Schedule) {
    return this.http.put(`${environment.apiUrl}/schedules/${id}`, data);
  }
  public delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/schedules/${id}`);
  }
  public show(id: string) {
    return this.http.get(`${environment.apiUrl}/schedules/${id}`);
  }
  public showAll(params: any = {}) {
    return this.http.get(`${environment.apiUrl}/schedules`, { params: params });
  }
}
