<div *ngIf="!status" style="width: 100%; height: 100%; position: absolute; z-index: 1;"></div>
<div class="row">
  <div class="col-sm-6">
    <h5>Switch 0 </h5>

    <app-switch-box [checked]="activeSwitch1" (switchStatus)="onChangeEndpoint($event, switch1, 0)">
    </app-switch-box>

  </div>

  <div class="col-sm-6">
    <h5>Switch 1 </h5>

    <app-switch-box [checked]="activeSwitch2" (switchStatus)="onChangeEndpoint($event, switch2, 1)">
    </app-switch-box>

  </div>
</div>