import { WidgetsModule } from './../widgets/widgets.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { NavigationComponent } from './navigation/navigation.component';
import { TopnavbarComponent } from './topnavbar/topnavbar.component';
import { BasicLayoutComponent } from './layouts/basic-layout/basic-layout.component';
import { MobileLayoutComponent } from './layouts/mobile-layout/mobile-layout.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    TranslateModule,
    WidgetsModule,
    FontAwesomeModule
  ],
  declarations: [
    FooterComponent,
    NavigationComponent,
    TopnavbarComponent,
    BasicLayoutComponent,
    MobileLayoutComponent,
    BreadcrumbsComponent
  ],
  exports: [
    FooterComponent,
    NavigationComponent,
    TopnavbarComponent,
    BasicLayoutComponent,
    MobileLayoutComponent,
    BreadcrumbsComponent
  ]
})
export class TemplateModule { }
