<div *ngIf="!status" style="width: 100%; height: 100%; position: absolute; z-index: 1;"></div>
<div *ngFor="let endpoint of endpoints" class="col-lg-6">

  <div [ngSwitch]="true">
    <h4>{{ endpoint.display_name || endpoint.name }}</h4>
    <div *ngSwitchCase="
      endpoint.id == TYPE_ENDPOINTS.RELAY_0 ||
      endpoint.id == TYPE_ENDPOINTS.RELAY_1">
      <button class="squishy">
        <span class="squishy-button"></span>
        <span class="squishy-label">
          <span
            [ngClass]="endpoint.current == 'Off' ? 'icon-gz-remote-sensor-locked green' : 'icon-gz-remote-sensor-unlocked red'"></span>
        </span>
      </button>
    </div>

    <div *ngSwitchCase="
      endpoint.id == TYPE_ENDPOINTS.RELAY_2 ||
      endpoint.id == TYPE_ENDPOINTS.RELAY_3 ||
      endpoint.id == TYPE_ENDPOINTS.RELAY_4 ||
      endpoint.id == TYPE_ENDPOINTS.RELAY_5">
      <button class="squishy">
        <span class="squishy-button"></span>
        <span class="squishy-label">
          <span
            [ngClass]="endpoint.current == 'Off' ? 'icon-gz-sensor-closed green' : 'icon-gz-sensor-open red'"></span>
        </span>
      </button>
    </div>

    <div *ngSwitchCase="
      endpoint.id == TYPE_ENDPOINTS.RELAY_6 ||
      endpoint.id == TYPE_ENDPOINTS.RELAY_7">
      <button class="squishy">
        <span class="squishy-button"></span>
        <span class="squishy-label">
          <span class="icon-gz-movement-sensor" [ngClass]="endpoint.current == 'Off' ? 'green' : 'red'"></span>
        </span>
      </button>
    </div>
  </div>

</div>