export class Constants {
  static TYPE_ENDPOINTS = {
    BATTERY: 'b',
    BATTERY_ALT: 'battery',
    BATTERY_LEVEL: 'attr_batteryLevel',
    BATTERY_PERCENTAGE: 'battery-percentage',
    INTERNAL_TEMP: 't',
    POWER_SIGNAL: 'l',
    POWER_CONPSUMPTION: 'p',
    SWITCH_X: /^sw[0-9]+$/,
    DIMMER: 'd',
    TIMESTAMP: 'ts',
    COOLER: 'co',
    FAN: 'f',
    RELAY_X: /^relay_[0-9]+$/,
    RELAY_0: 'relay_0',
    RELAY_1: 'relay_1',
    RELAY_2: 'relay_2',
    RELAY_3: 'relay_3',
    RELAY_4: 'relay_4',
    RELAY_5: 'relay_5',
    RELAY_6: 'relay_6',
    RELAY_7: 'relay_7',
    COMMAND_RELAY_X: /^command_relay_[0-9]+$/,
    VALUE_X: /^value_[0-9]+$/,
    POWER: 'power',
    POWER2: 'Power',
    POWER3: 'POWER',
    POWERX: /^power[0-9]+$[^Power[0-9]+$|^POWER[0-9]+$/,
    POWER_X: /^power_[0-9]+$|^Power_[0-9]+$|^POWER_[0-9]+$/,
    BRIGHTNESS: 'bri',
    BRIGHTNESS_ALT: 'brightness',
    ON: 'on',
    DALI: 'dali',
    VERSION: 'version',
    VERSION2: 'Version',
    LOCATION: 'location',
    UPTIME: 'c',
    UPTIME2: 'uptime',
    UPTIME3: 'Uptime',
    UPTIME_SEC: 'UptimeSec',
    BOARD: 'board',
    IP: 'ip',
    IPADDRESS: 'IPAddress',
    RSSI: 'rssi',
    EUI: 'EUI',
    TEMPERATURE: 'temperature',
    TEMPERATURE0: 'temperature_0',
    TEMPERATURE1: 'temperature_1',
    TARGET_TEMPERATURE: 'display_targettemperature',
    STATUS_SWITCH: 'status',
    DISPLAY: 'display_status',
    BLOCK: 'relayblock',
    LIGHT: 'light_relay',
    BLIND_RELAY_X: /^blind_relay_[0-9]+$/,
    BLIND_DOWN: 'blind_relay_0',
    BLIND_UP: 'blind_relay_1',
    TEMPERATURE_RELAY: 'temp_relay',
    HEATER: 'heater_relay',
    TEMPERATURE_DISPLAY_TARGETTEMPERATURE: 'temp_display_targettemperature',
    WIFI_DEVICES: 'wifitotaldevices',
    WIFI_CLIENT: 'wifidevice',
    SENSOR_DATA: 'data',
    FAN_SPEED: 'fan_speed',
    TEMPERATURE_MODE: 'mode',
    GENERIC_DIMMER: 'dimmer',
    FIRST_CAPITAL_DIMMER: 'Dimmer',
    TEMPERATURE_CORRECTION: 'temperature_correction',
    CO2: 'co2',
    ECO2: 'eCO2',
    TVOC: 'TVOC',
    PRESSURE: 'pressure',
    HUMIDITY: 'humidity',
    HUMIDITY_CORRECTION: 'humidity_correction',
    WORKING_MODE: 'workingmode',
    WARNING_TEMPERATURE: 'warningtemperature',
    CHANNEL_X: /^channel_[0-3]+$/,
    LATLON: 'latlon',
    LATITUDE: 'latitude',
    LONGITUDE: 'longitude',
    IMAGE: 'image',
    VOLUME: 'volume',
    MUTE: 'mute',
    STATE: 'state',
    TITLE: 'title',
    ARTIST: 'artist',
    ALBUM: 'album',
    DURATION: 'duration',
    ACTION: 'action',
    SYSTEM_MODE: 'system_mode',
    ECO_MODE: 'eco_mode',
    MANUAL_MODE: 'manual_mode',
    LAST_JOINED: 'join',
    LAST_LEFT: 'leave',
    LED: 'led',
    COLOR_TEMPERATURE: 'ct',
    COLOR_TEMPERATURE_ALT: 'color_temp',
    VCC: 'vcc',
    VOLTAGE: 'voltage'
  };

  static TYPE_NODE = {
    SMARTLIGHT_SWITCH: '000002',
    THERMOSTAT: '000001',
    LIGHT2: '000003',
    HEATING: '000004',
    SONOFF_SWITCH: '000005',
    DOOR_BELL: '000007',
    LIGHT3: '0000011',
    THERMOSTAT2: '0000010',
    BLIND_PUSH: '000015',
    BLIND: '000017',
    SENSOR: '000024',
    SMART_BLIND: '000029',
    DISPLAY: '000060',
    SMART_CLIMATE: '000040',
    SMART_DISPLAY: '00006',
    SMART_PANEL: '0004',
    SMART_FOUR_CHANNELS: '000070',
    SMART_4C_MULTI_W: '000071',
    SMART_BLIND_W: '000092',
    SMART_LIGHT_PUSH_WP2: '000093',
    SMART_LIGHT_MIRROR_WP2: '000094',  
    SMART_BLIND_PUSH_WP2: '000095',  
    SMART_SPEAKER: '0ZPS18',
    SMART_TOUCH: /^0008[0-9][0-9]$/
  };

  static TYPE_SENSORS = {
    ULTRASONIC: 'ultrasonic',
    PARKING_SENSOR: 'parkingSensor',
    PERSON_COUNTER: 'personCounter'
  }

  static COLORS = {
    green: '#1ab394',
    orange: '#f8ac59',
    red: '#ed5565',
    yellow: '#ffff00',
    blue: '#1c84c6',
    blue_green: '#23c6c8',
    pink: '#e68293',
    pink_hover: '#e4798b',
    fluorescent_green: '#55DD55'
  }

  static ENTITIES = {
    node: 'NODE',
    gateway: 'GATEWAY',
    tablet: 'TABLET',
    primitive: 'PRIMITIVE',
    endpoint: 'ENDPOINT'
  }

  static PLACES = {
    unlocated: 'unlocated',
    building: 'building',
    apartment: 'apartment',
    room: 'room'
  }

  static DEVICE_FILTERS = {
    nodesString: '(0(?!(01|81))|1|3|4|7|8)',
    nodesRegex: /^(0(?!(01|81))|1|3|4|7|8)/,
    gatewaysString: '5',
    gatewaysRegex: /^5/,
    mailboxesString: '001',
    mailboxesRegex: /^001/,
    sensorsString: '2',
    sensorsRegex: /^2/,
    mobilesString: '6',
    mobilesRegex: /^6/,
    edgeServersString: '9',
    edgeServersRegex: /^9/,
    sipServersString: '081',
    sipServersRegex: /^081/,
    doorsString: '0',
    doorsRegex: /^0/,
    nodesSensorsString: '(0(?!01)|1|2|3|4|7|8)',
    nodesSensorsRegex: /^(0(?!01)|1|2|3|4|7|8)/,
  }

  static GROUPS = ['panic', 'smoke'];
  static ICONS = {
    'panic': 'panic',
    'smoke': 'fire'
  };

  static LANGUAGES = {
    english: 'en',
    german: 'de',
    spanish: 'es',
    french: 'fr',
    italian: 'it'
  };

  static REVERSE_GEOCODING = {
    number: 'street_number',
    street: 'route',
    postal: 'postal_code',
    city: 'locality',
    state: 'administrative_area_level_2',
    country: 'country'
  };

  static OAUTH2_GRANTS = [ 'authorization_code', 'refresh_token' ];
}
