import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy
} from '@angular/core';
import { SocketService } from '../../../../socket.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-brightness-actuator',
  templateUrl: './brightness-actuator.component.html',
  styleUrls: ['./brightness-actuator.component.scss']
})
export class BrightnessActuatorComponent implements OnInit, OnDestroy {
  @Input() endpoint: any;
  /**
   * Current node, parent of the endpoint.
   */
  @Input() node: any;
  @Input() public status: boolean;
  @Output() endpointChange: EventEmitter<any> = new EventEmitter();

  public data: any;
  private ioConnectionActuation$: Subscription;

  public constructor(private socketService: SocketService) { }

  public ngOnInit() {
    this.data = { ...this.endpoint };
    if (!this.data.current) {
      this.data.current = 0;
    }

    if (this.data.value.length === 0) {
      this.data.value.push({ min: 0, max: 100 })
    } else {
      if (this.data.value[0].min === null) {
        this.data.value[0].min = 0;
      }
      if (this.data.value[0].max === null) {
        this.data.value[0].max = 100;
      }
    }

    this.ioConnectionActuation$ = this.socketService
      .onMeasureCreated()
      .subscribe((measure: any) => {
        if (this.node._id === measure.device && this.data._id === measure.endpoint)
        {
          return this.updateMeasure(measure);
        }
        return;
      });
  }

  updateMeasure(measure: any) {
    this.data.current = measure.value;
  }

  onChangeDimmer(value: any) {
    this.data.current = value;
    console.log('dimmer', value);
    this.endpointChange.emit({
      updated: true,
      _id: this.data._id,
      value: this.data.current
    });
  }

  public ngOnDestroy() {
    if (this.ioConnectionActuation$) {
      this.ioConnectionActuation$.unsubscribe();
    }
  }
}
