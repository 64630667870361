import { Component, OnInit, OnDestroy } from '@angular/core';
import { RoutingService } from '../../shared/services/routing.service';
import { Subscription } from 'rxjs';

/**
 * Component used to represent the breadcrumbs on every page.
 */
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  /**
   * Default title.
   */
  public title = '';
  /**
   * Breadcrumbs.
   */
  public data: any[] = [];
  /**
   * Subscription to the breadcrumbs change.
   */
  private sub$: Subscription;

  /**
   * Builds the component and initializes the routing service.
   * @param {RoutingService} routingService Service to manage data traspassing between routes.
   */
  constructor(private routingService: RoutingService) { }

  /**
   * Obtains the breadcrumbs and subscribes to their changes.
   */
  ngOnInit() {
    this.sub$ = this.routingService.getBreadCrumb().subscribe(dataResult => {
      this.data = dataResult;
    });
  }
  /**
   * Unsubscribes to the breadcrumbs change.
   */
  ngOnDestroy() {
    this.sub$.unsubscribe();
  }
}
