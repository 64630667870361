<div *ngIf="!status" style="width: 100%; height: 100%; position: absolute; z-index: 1;"></div>
<div class="row" *ngIf="endpoint.current !== undefined">
  <h4>{{ endpoint.display_name || endpoint.name }}</h4>
  <div class="col-12">
    <div class="btn-group">
      <button [class.btn-primary]="endpoint.current === 'Auto'" [class.btn-default]="endpoint.current !== 'Auto'"
        [disabled]="endpoint.current == 'Off'" (click)="onChangeEndpoint('Auto')"
        class="btn btn-lg">{{ 'auto' | translate }}
      </button>
      <button [class.btn-primary]="endpoint.current === 'Manual'" [class.btn-default]="endpoint.current !== 'Manual'"
        [disabled]="endpoint.current == 'Off'" (click)="onChangeEndpoint('Manual')"
        class="btn btn-lg">{{ 'manual' | translate }}
      </button>
    </div>
  </div>
</div>