import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { SocketService } from 'app/socket.service';

@Component({
  selector: 'app-push-actuator',
  templateUrl: './push-actuator.component.html',
  styleUrls: ['./push-actuator.component.scss']
})
export class PushActuatorComponent implements OnInit, OnDestroy, AfterViewInit {

  /**
   * Indicates whether the switch is turned on or off.
   */
  private checked: boolean = false;
  public disabled: boolean = true;
  /**
   * Subscription to actuation:updated backend event.
   */
  private ioConnection$: any;

  /**
   * Emits an endpoint change with its new value.
   */
  @Output() endpointChange: EventEmitter<any> = new EventEmitter();
  /**
   * Current node, parent of the endpoint.
   */
  @Input() node: any;
  /**
   * Current endpoint.
   */
  @Input() endpoint: any;
  /**
   * Indicates whether the actuator is connected or not.
   */
  @Input() public status: boolean;
  @ViewChild('pushbutton') pushbutton: ElementRef;

  public constructor(private socket: SocketService) { }

  public ngOnInit(): void {
    this.disabled = this.node.blocked;
    this.checked = this.endpoint.current === 'On' ? true : false;

    this.ioConnection$ = this.socket
      .onMeasureCreated()
      .subscribe((measure: any) => {
        if (measure.device !== this.node._id || measure.endpoint !== this.endpoint._id) {
          return;
        }
        if (measure.value === 'On') {
          this.endpoint.current = 'On';
          this.checked = true;
          this.pushbutton.nativeElement.classList.add('checked');
        } else {
          this.endpoint.current = 'Off';
          this.checked = false;
          this.pushbutton.nativeElement.classList.remove('checked');
        }
      });
  }

  public ngAfterViewInit() {
    if (this.checked) {
      this.pushbutton.nativeElement.classList.add('checked');
    }
  }

  public pressButton(event: any): void {
    if (event.button === 0) {
      if (this.checked) {
        this.pushbutton.nativeElement.classList.remove('checked');
        this.changeEndpoint('Off');    
      } else {
        this.pushbutton.nativeElement.classList.add('checked');
        this.changeEndpoint('On');
      }
      this.checked = !this.checked;
    }
  }
  private changeEndpoint(value: string): void {
    this.endpointChange.emit({
      updated: true,
      _id: this.endpoint._id,
      value: value
    });
  }

  /**
   * Deactivates the socket event subscription.
   */
  public ngOnDestroy(): void {
    if (this.ioConnection$) {
      this.ioConnection$.unsubscribe();
    }
  }
}
