import { TagsGuardService } from './guards/tags-guard.service';
import { HasTagsGuardService } from './guards/has-tags-guard.service';
import { RolesGuardService } from './guards/roles-guard.service';
import { TabletService } from './services/tablet.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { AuthGuardService } from './guards/auth-guard.service';
import { MobileGuardService } from './guards/mobile-guard.service';
import { BuildingService } from './services/building.service';
import { UserService } from './services/user.service';
import { ApartmentService } from './services/apartment.service';
import { RoomService } from './services/room.service';
import { NodeService } from './services/node.service';
import { SensorService } from './services/sensor.service';
import { ActuatorService } from './services/actuator.service';
import { RoutingService } from './services/routing.service';
import { GroupService } from './services/group.service';
import { MeasureService } from './services/measure.service';
import { RuleService } from './services/rule.service';
import { OrganizationService } from './services/organization.service';
import { transformEndpointValues } from './pipes/transformEndpointValues.pipe';
import { GetAssignedID } from './pipes/endpoint-assigned-id.pipe';
import { SensorDataPipe } from './pipes/sensorData.pipe';
import { SecondsToHumanReadablePipe } from './pipes/seconds-to-human-readable.pipe';
import { StringToIntPipe } from './pipes/string-to-int.pipe';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { SettingsService } from './services/settings.service';
import { OrganizationGuardService } from './guards/organization-guard.service';
import { NotificationService } from './services/notification.service';
import { LogService } from './services/log.service';
import { DateToStringPipe } from '../date-to-string.pipe';
import { DoorService } from './guards/door.service';
import { Helpers } from './helpers';
import { DevicetypeService } from './services/devicetype.service';
import { KeyService } from './services/key.service';
import { ScheduleService } from './services/schedule.service';
import { Oauth2ClientService } from './services/oauth2-client.service';

@NgModule({
  imports: [
    HttpClientModule,
    CommonModule
  ],
  declarations: [
    transformEndpointValues,
    GetAssignedID,
    SecondsToHumanReadablePipe,
    StringToIntPipe,
    DateToStringPipe,
    SensorDataPipe
  ],
  exports: [
    transformEndpointValues,
    GetAssignedID,
    SecondsToHumanReadablePipe,
    StringToIntPipe,
    DateToStringPipe,
    SensorDataPipe
  ],
  providers: [OrganizationGuardService]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        AuthGuardService,
        MobileGuardService,
        AuthService,
        BuildingService,
        ApartmentService,
        RoomService,
        ActuatorService,
        UserService,
        NodeService,
        RoutingService,
        GroupService,
        SensorService,
        MeasureService,
        RuleService,
        OrganizationService,
        SettingsService,
        TabletService,
        RolesGuardService,
        HasTagsGuardService,
        TagsGuardService,
        NotificationService,
        LogService,
        DoorService,
        DevicetypeService,
        KeyService,
        ScheduleService,
        Oauth2ClientService,
        Helpers
      ]
    };
  }
}
