<div *ngIf="!status" style="width: 100%; height: 100%; position: absolute; z-index: 1;"></div>

<div class="actuator">
  <div style="text-align:center">
    <div class="row">
      <div class="col-lg-12" style="margin-bottom: 10px;">Complete</div>
      <div class="col-lg-12">
        <button [disabled]="sendedValue === 'On'" (click)="pushOn('UP', BLIND_ACTIONS.COMPLETE)" class="squishy">
          <span class="squishy-button"></span>
          <span class="squishy-label">
            <span class="icon-gz-arrow-up"></span>
          </span>
        </button>
        <button [disabled]="sendedValue === 'On'" (click)="pushOn('DOWN', BLIND_ACTIONS.COMPLETE)" class="squishy">
          <span class="squishy-button"></span>
          <span class="squishy-label">
            <span class="icon-gz-arrow-down"></span>
          </span>
        </button>
        <button (click)="pushOff( BLIND_ACTIONS.COMPLETE )" class="squishy squishy-danger">
          <span class="squishy-button"></span>
          <span class="squishy-label">
            <span class="icon-gz-stop"></span>
          </span>
        </button>
      </div>
    </div>
  </div>
</div>

<br>

<div class="actuator">
  <div style="text-align:center">
    <div class="row">
      <div class="col-lg-12" style="margin-bottom: 10px;">
        Partial
        <span class="badge badge-info">{{timing}}</span>
      </div>
      <div class="col-lg-12">
        <button class="squishy" [disabled]="sendedValue === 'On' && typeActionRunning !== BLIND_ACTIONS.PARTIAL"
          (mousedown)="pushOn('UP', BLIND_ACTIONS.PARTIAL)" (mouseup)="pushOff(BLIND_ACTIONS.PARTIAL)">
          <span class="squishy-button"></span>
          <span class="squishy-label">
            <span class="icon-gz-arrow-up"></span>
          </span>
        </button>
        <button [disabled]="sendedValue === 'On' && typeActionRunning !== BLIND_ACTIONS.PARTIAL"
          (mousedown)="pushOn('DOWN', BLIND_ACTIONS.PARTIAL)" (mouseup)="pushOff( BLIND_ACTIONS.PARTIAL )"
          class="squishy">
          <span class="squishy-button"></span>
          <span class="squishy-label">
            <span class="icon-gz-arrow-down"></span>
          </span>
        </button>
      </div>
    </div>
  </div>
</div>

<br>

<div class="actuator">
  <div style="text-align:center">
    <div class="row">
      <div class="col-lg-12" style="margin-bottom: 10px;">Blades</div>
      <div class="col-lg-12">
        <button [disabled]="sendedValue === 'On'" (click)="pushOn('UP', BLIND_ACTIONS.BLADES)" class="squishy">
          <span class="squishy-button"></span>
          <span class="squishy-label">
            <span class="icon-gz-blades-open"></span>
          </span>
        </button>
        <button [disabled]="sendedValue === 'On'" (click)="pushOn('DOWN', BLIND_ACTIONS.BLADES)" class="squishy">
          <span class="squishy-button"></span>
          <span class="squishy-label">
            <span class="icon-gz-blades-close"></span>
          </span>
        </button>
      </div>
    </div>
  </div>
</div>