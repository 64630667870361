<div class="m-grid__item m_grid_wrapper m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-grid--tablet-and-mobile m-grid--hor-tablet-and-mobile m-login m-login--1 m-login--signin"
    id="m_login">
    <div class="m-grid__item m-grid__right m-grid__item--order-tablet-and-mobile-2 m-login__aside">
        <div class="input-group" id="language-selector">
            <span class="input-group-addon">
                <fa-icon [icon]="faGlobe"></fa-icon>
            </span>
            <select class="form-control" [ngModel]="language" (ngModelChange)="onChangeLanguage($event)">
                <option [value]="languages.english">English</option>
                <option [value]="languages.german">Deutsche</option>
                <option [value]="languages.spanish">Español</option>
                <option [value]="languages.french">Français</option>
                <option [value]="languages.italian">Italiano</option>
            </select>
        </div>
        <div class="m-stack m-stack--hor m-stack--desktop">
            <div class="m-stack__item m-stack__item--fluid">
                <div class="m-login__wrapper">
                    <div class="m-login__logo">
                        <a href="oauth2/authorize">
                            <img src="assets/custom/images/logo_green.png">
                        </a>
                    </div>
                    <div *ngIf="queryError">
                        BAD URL PARAMETERS
                    </div>
                    <div class="m-login__signin" *ngIf="!showLoginPage && !queryError">
                        <div class="m-login__head">
                            <form (ngSubmit)="onAuthorize()" class="m-login__form m-form">
                                <div *ngIf="error.success != null && error.success == false" class="alert alert-danger">
                                    {{ error.message }}
                                </div>
                                <div class="m-login__form-action">
                                    <button id="m_login_signin_submit"
                                        class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">
                                        {{ 'authorize' | translate }}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="m-login__signin" *ngIf="showLoginPage && !queryError">
                        <div class="m-login__head">
                            <form [formGroup]="form" (ngSubmit)="onLogin()" class="m-login__form m-form" novalidate
                                autocomplete="off">

                                <div *ngIf="error.success != null && error.success == false" class="alert alert-danger">
                                    {{ error.message }}
                                </div>

                                <div class="form-group m-form__group" [ngClass]="{
                                    'has-danger': form.controls['username'].invalid && (form.controls['username'].dirty || form.controls['username'].touched),
                                    'has-success': form.controls['username'].valid && (form.controls['username'].dirty || form.controls['username'].touched)
                                }">
                                    <input placeholder="{{ 'username' | translate }}" type="text"
                                        class="form-control m-input" formControlName="username" autocomplete="username"
                                        autofocus>
                                    <span class=""
                                        *ngIf="form.controls['username'].errors && (form.controls['username'].dirty || form.controls['username'].touched)">
                                        <p *ngIf="form.controls['username'].errors.required">{{ 'usernameRequired' |
                                            translate }}</p>
                                    </span>
                                </div>
                                <div class="form-group m-form__group input-password">
                                    <input placeholder="{{ 'Password' | translate }}"
                                        [type]="showPassword1 ? 'text' : 'password'"
                                        class="form-control m-input input-password" name="password"
                                        formControlName="password" autocomplete="current-password">
                                    <fa-icon (click)="onShowPassword1()" [icon]="showPassword1 ? faEye : faEyeSlash">
                                    </fa-icon>
                                </div>
                                <div class="form-group m-form__group" [ngClass]="{
                                    'has-danger': form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched),
                                    'has-success': form.controls['password'].valid && (form.controls['password'].dirty || form.controls['password'].touched)
                                }">
                                    <span class=""
                                        *ngIf="form.controls['password'].errors && (form.controls['password'].dirty || form.controls['password'].touched)">
                                        <p *ngIf="form.controls['password'].errors.required">{{ 'passRequired' |
                                            translate }}</p>
                                        <p *ngIf="form.controls['password'].errors.minlength">{{ 'passLength' |
                                            translate }}</p>
                                    </span>
                                </div>
                                <div class="m-login__form-action">
                                    <button [disabled]="!form.valid" id="m_login_signin_submit"
                                        class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">
                                        {{ 'signIn' | translate }}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<footer class="main-footer copyright-footer">
    Real Estate Platform - <strong>{{ version }}</strong>
    <div class="pull-right">
        <strong>Copyright © 2017-21 goZmart Iberia. {{ 'allRights' | translate }}.</strong>
    </div>
</footer>