import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.scss']
})
export class ModalAlertComponent implements OnInit {
  
  title: string;
  message: any;
  item: any;

  @Input() settings: any = {}; 
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() confirm: EventEmitter<any> = new EventEmitter();
  
  constructor() {}
  
  ngOnInit(){
    this.title = this.settings.title;
    this.message = this.settings.message;
    this.item = this.settings.item;
  }

  sendCancel(){
    this.close.emit(true);
  }
  sendConfirm(){
    this.confirm.emit(this.item);
  }

}
