<div class="row white-bg px-2">
  <div class="col py-3">
    <h2>{{ data[data.length - 1].title || 'title' }}</h2>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumb of data; let i = index" [class.active]="i == data.length -1">
          <a [routerLink]="[breadcrumb.path]">
            <span>{{ breadcrumb?.label }}</span>
          </a>
        </li>
      </ol>
    </nav>
  </div>
</div>