import {
  Output,
  Input,
  Component,
  OnInit,
  EventEmitter,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { distinctUntilChanged, debounceTime, switchMap } from 'rxjs/operators';

/**
 * Component used to represent a select box.
 */
@Component({
  selector: 'app-input-select-server',
  templateUrl: './input-select-server.component.html',
  styleUrls: ['./input-select-server.component.scss']
})
export class InputSelectServerComponent implements OnInit, OnChanges {
  /**
   * Array of items to show in the select box.
   */
  public items: any[] = [];
  /**
   * Page to show.
   */
  public currentPage = 1;
  /**
   * Total number of pages returned.
   */
  public totalPages = 0;
  /**
   * Total number of items.
   */
  public totalItems = 0;
  /**
   * Limit of items to return.
   */
  public limit = 10;
  /**
   * Indicates whether the items are being loaded.
   */
  public loading = false;
  /**
   * Subscription to the changes in the select box search text box.
   */
  public itemsTypeahead = new EventEmitter<string>();
  /**
   * Disables the select box in case it is disabled.
   */
  public disableSelector = false;
  /**
   * Value of the selected item.
   */
  public selectedValue = '';
  public placeholder: string;
  /**
   * Form of the select box.
   */
  @Input() group: FormGroup;
  /**
   * Name of the select box.
   */
  @Input() name: string;
  /**
   * Indicates whether the select box it's disabled or not.
   */
  @Input() disabled: boolean;
  /**
   * Settings set to load data with the conditions wanted.
   */
  @Input() settings: any;
  /**
   * Select box property for the label.
   */
  @Input() public label: string;
  /**
   * Emits the value of the item selected when the select box is initialized or changed.
   */
  @Output() onChange = new EventEmitter();
  @Output() onSelect = new EventEmitter();

  /**
   * Builds the component and initializes a service.
   * @param {HttpClient} http Service used to make API calls.
   */
  public constructor(private http: HttpClient) { }

  /**
   * Initializes certain variables including the list of items to show in the select box and subscribes to any external change.
   */
  public ngOnInit() {
    if (!this.settings) {
      return;
    }
    if (this.group) {
      this.selectedValue = this.group.controls[this.name].value;
    }
    if (!this.label) {
      this.label = 'name';
    }

    if (
      this.selectedValue !== '' &&
      this.selectedValue !== undefined &&
      this.selectedValue !== null &&
      !this.disabled
    ) {
      this.http
        .get(
          `${environment.apiUrl}/${this.settings.section}/${this.selectedValue}`
        )
        .subscribe((res: any) => {
          this.loadData();
        });
    } else {
      this.loadData();
    }
    this.serverSideSearch();

    if (this.group) {
      // Every time a value of the form changes, the select box checks whether how was it affected by the change.
      // If there was any change, it is emitted to the form.
      this.group.valueChanges.subscribe(val => {
        if (val[this.name] !== this.selectedValue) {
          this.selectedValue = val[this.name];
          this.onChange.emit(val[this.name]);
          this.onSelectItem(val[this.name]);
        }
      });
    }
  }

  /**
   * Function executed when data in the select box changes.
   * @param {SimpleChanges} changes Changes produced.
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (
      changes.settings &&
      !changes.settings.firstChange &&
      changes.settings.currentValue !== changes.settings.previousValue
    ) {
      this.items = [];
      this.currentPage = 1;
      this.totalPages = 0;
      this.totalItems = 0;
      if (changes.settings.currentValue.section) {
        this.loadData();
      }
    }

    if (changes.disabled &&
      changes.disabled.currentValue !== undefined &&
      changes.disabled.currentValue !== changes.disabled.previousValue) {
      this.disableSelector = changes.disabled.currentValue;
      changes.disabled.currentValue ? this.group.controls[this.name].disable() : this.group.controls[this.name].enable();
    }
  }

  public onChangeItem(value) {
    this.onChange.emit(value);
  }

  public onSelectItem(value) {
    const itemSelected = this.items.find(node => node._id === value);

    if (itemSelected && itemSelected.name) {
      this.onSelect.emit(itemSelected.name);
    }
  }

  public onClearBox() {
    this.items = [];
    this.currentPage = 1;
    this.totalPages = 0;
    this.totalItems = 0;
    this.loadData();
  }

  /**
   * Loads the select box items.
   * @param search Name of the item for the search.
   */
  private loadData(search?: string) {
    if (!this.disabled) {
      let params = {
        page: this.currentPage,
        limit: this.limit,
        ...this.settings.filter
      };
      if (search) {
        params = { ...params, search: search };
      }

      this.loading = true;
      this.http
        .get(`${environment.apiUrl}/${this.settings.section}`, { params: params })
        .subscribe((res: any) => {
          this.loading = false;
          this.totalItems = res.total;
          this.totalPages = res.pages;
          const isApartment = this.settings.section === 'devices' && this.settings.filter?.hasOwnProperty('building');
          if (isApartment) {
            res.docs.forEach((item: any) => {
              if (item.apartment) {
                item.name += ' (' + item.apartment.name + ')' + ' (' + item.scheme.name + ')'
              }
            });
          }
          const aux = [...this.items, ...res.docs];
          if (this.selectedValue) {
            const selectedItem = aux.find(
              item => item._id === this.selectedValue
            );
            if (!selectedItem) {
              this.loading = true;
              const call: string = environment.apiUrl + '/' + this.settings.section + '/' + this.selectedValue + (isApartment ? '?include=apartment' : '');
              this.http
                .get(call)
                .subscribe((selected: any) => {
                  if (isApartment) {
                    if (selected.apartment) {
                      selected.name += ' (' + selected.apartment.name + ')' + ' (' + selected.scheme.name + ')'
                    }
                  }

                  aux.splice(0, 0, selected);
                  this.items = aux;
                  this.loading = false;
                });
            } else {
              this.items = aux;
            }
          } else {
            this.items = aux;
          }
        });
    }
  }

  /**
   * Increases the number of items to be displayed.
   * It is executed when the user scrolls down since that means he/she wants see more options.
   */
  public fetchMore() {
    if (this.currentPage >= this.totalPages) {
      return;
    }
    this.currentPage++;
    this.loadData();
  }

  /**
   * Searches for the name of the item typed and refreshes the select box.
   */
  private serverSideSearch() {
    if (!this.disabled) {
      this.itemsTypeahead.pipe(
        distinctUntilChanged(),
        debounceTime(200),
        switchMap((term: any) => {
          let params = {
            ...this.settings.filter
          };
          if (term !== '' && term !== undefined && term !== null) {
            params = { ...params, search: term };
          }
          return this.http.get(`${environment.apiUrl}/${this.settings.section}`, {
            params: params
          });
        })
      ).subscribe(
        (res: any) => {
          this.items = [].concat(res.docs);
          this.totalItems = res.total;
          this.totalPages = res.pages;
        },
        err => {
          console.log(err);
          this.items = [];
          this.totalItems = 0;
          this.totalPages = 0;
        }
      );
    }
  }

  public getNestedLabelValue(item: any) {
    const labels = this.label.split('.');
    let labelValue = item;
    labels.forEach(label => {
      labelValue = labelValue[label];
    });
    return labelValue;
  }
}
