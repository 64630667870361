import { Component, OnInit } from '@angular/core';
import { smoothlyMenu } from './../../app.helpers';
import { AuthService } from '../../shared/services/auth.service';
import { IconDefinition, faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
declare var jQuery: any;

@Component({
  selector: 'app-topnavbar',
  templateUrl: './topnavbar.component.html',
  styleUrls: ['./topnavbar.component.scss']
})

export class TopnavbarComponent implements OnInit {

  public faBars: IconDefinition = faBars;
  public faSignOutAlt: IconDefinition = faSignOutAlt;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {
  }

  toggleNavigation(): void {
    jQuery('body').toggleClass('mini-navbar');
    smoothlyMenu();
  }

  logout() {
    this.authService.logout();
  }

}
