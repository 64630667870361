import { TemplateModule } from './template/template.module';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { UserModule } from './user/user.module';
import { SharedModule } from './shared/shared.module';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { SocketService } from './socket.service';
import { AuthGuardService } from './shared/guards/auth-guard.service';
import { MobileGuardService } from './shared/guards/mobile-guard.service';
import { RequestInterceptorService } from './shared/services/request-interceptor.service';
import { Oauth2Module } from './oauth2/oauth2.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const ROUTES_APP: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'building',
    loadChildren: () => import('./building/building.module').then(m => m.BuildingModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'apartment',
    loadChildren: () => import('./apartment/apartment.module').then(m => m.ApartmentModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'room',
    loadChildren: () => import('./room/room.module').then(m => m.RoomModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'node',
    loadChildren: () => import('./node/node.module').then(m => m.NodeModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'facility/node',
    loadChildren: () => import('./node/node.module').then(m => m.NodeModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'sensor',
    loadChildren: () => import('./sensor/sensor.module').then(m => m.SensorModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'mobile',
    loadChildren: () => import('./mobile/node/node-mobile.module').then(m => m.NodeMobileModule),
    canLoad: [MobileGuardService]
  },
  {
    path: 'mobiles',
    loadChildren: () => import('./mobile/mobile.module').then(m => m.MobileModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'subscriber',
    loadChildren: () => import('./subscriber/subscriber.module').then(m => m.SubscriberModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'administrator',
    loadChildren: () => import('./administrator/administrator.module').then(m => m.AdministratorModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'tags',
    loadChildren: () => import('./tags/tags.module').then(m => m.TagsModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'organization',
    loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'gateway',
    loadChildren: () => import('./gateway/gateway.module').then(m => m.GatewayModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'tablet',
    loadChildren: () => import('./tablet/tablet.module').then(m => m.TabletModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'logs',
    loadChildren: () => import('./log/log.module').then(m => m.LogModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'doors',
    loadChildren: () => import('./door/door.module').then(m => m.DoorModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'devicetype',
    loadChildren: () => import('./devicetype/devicetype.module').then(m => m.DevicetypeModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'mailbox',
    loadChildren: () => import('./mailbox/mailbox.module').then(m => m.MailboxModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'key',
    loadChildren: () => import('./key/key.module').then(m => m.KeyModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'device',
    loadChildren: () => import('./device/device.module').then(m => m.DeviceModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'edge-server',
    loadChildren: () => import('./edge-server/edge-server.module').then(m => m.EdgeServerModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'sip-server',
    loadChildren: () => import('./sip-server/sip-server.module').then(m => m.SipServerModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'oauth2-clients',
    loadChildren: () => import('./oauth2-client/oauth2-client.module').then(m => m.Oauth2ClientModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'oauth2',
    loadChildren: () => import('./oauth2/oauth2.module').then(m => m.Oauth2Module)
  },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TemplateModule,
    Oauth2Module,
    UserModule,
    SharedModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    RouterModule.forRoot(ROUTES_APP, { enableTracing: false, relativeLinkResolution: 'corrected' })
  ],
  providers: [
    SocketService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
