import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy
} from '@angular/core';
import { SocketService } from '../../../../socket.service';
import { Subscription } from 'rxjs';
import { Constants } from '../../../../shared/constants';
import * as _ from 'underscore';

@Component({
  selector: 'app-temperature-actuator',
  templateUrl: './temperature-actuator.component.html',
  styleUrls: ['./temperature-actuator.component.scss']
})
export class TemperatureActuatorComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() endpoint: any;
  /**
   * Current node, parent of the endpoint.
   */
  @Input() node: any;
  @Output() endpointChange: EventEmitter<any> = new EventEmitter();
  @Input() public status: boolean;

  public data: any;

  public min: any;
  public max: any;

  private tempGraphEl: any;

  public TYPE_ENDPOINTS = Constants.TYPE_ENDPOINTS;

  public actualTemperature: any;
  private ioConnectionActuation$: Subscription;

  public refreshIntervalId: any;
  public dataInitCurrent: number;
  public dataEndCurrent: number;

  public constructor(private socketService: SocketService) { }

  public ngOnInit() {
    this.data = { ...this.endpoint };

    // Getting range from temperature to set proper limits to the actuator
    this.min = this.data.value[0].min;
    this.max = this.data.value[0].max;

    if (!this.data.current) {
      this.data.current = this.min;
    }

    this.setActualTemperature();
    this.initSocketIO();
  }

  initSocketIO() {
    this.ioConnectionActuation$ = this.socketService.onMeasureCreated().subscribe((measure: any) => {
      this.updateMeasure(measure);
      this.updateActualTemperature(measure);
    });
  }

  setActualTemperature() {
    let temperatureSufix = '';
    const endpointTemperatureArray = this.data.id.split(this.TYPE_ENDPOINTS.TARGET_TEMPERATURE);
    if (endpointTemperatureArray.length > 1) {
      temperatureSufix = endpointTemperatureArray.pop();
    }

    this.actualTemperature = _.findWhere(this.node.scheme.endpoints, { id: `${this.TYPE_ENDPOINTS.TEMPERATURE}${temperatureSufix}` });
  }

  ngAfterViewInit() {
    this.tempGraphEl = document.getElementById('tempGraph-' + this.data._id);
    this.tempGraphEl.setAttribute('style', `--min:${this.min}; --max:${this.max}; --val:${this.data.current}`);
  }

  updateMeasure(measure: any) {
    if (measure.device !== this.node._id || measure.endpoint !== this.data._id) {
      return;
    }

    this.data.current = measure.value;
    this.tempGraphEl.setAttribute('style', this.minMaxVal());
  };

  updateActualTemperature(measure: any) {
    if (measure.device !== this.node._id || measure.endpoint !== this.actualTemperature._id) {
      return;
    }

    this.actualTemperature.current = measure.value;
  }

  minMaxVal() {
    return `--min: ${this.min}; --max: ${this.max}; --val: ${this.data.current}`;
  }

  public onEndChangeDimmer() {
    clearInterval(this.refreshIntervalId);
    this.dataEndCurrent = this.data.current
    if (this.dataInitCurrent !== this.dataEndCurrent) {
      this.endpointChange.emit({
        updated: true,
        _id: this.data._id,
        value: this.data.current
      });
    }
  }

  public onChangeDimmer(value: number) {
    this.dataInitCurrent = this.data.current
    this.refreshIntervalId = setInterval(function () {
      if (this.data.current + value < this.min) {
        this.data.current = this.min;
      } else if (this.data.current + value > this.max) {
        this.data.current = this.max;
      } else {
        this.data.current += value;
      }
      this.tempGraphEl.setAttribute('style', this.minMaxVal());
    }.bind(this), 500);
  }

  public ngOnDestroy() {
    if (this.ioConnectionActuation$) {
      this.ioConnectionActuation$.unsubscribe();
    }
  }
}
