import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '../constants';

@Pipe({
  name: 'transformEndpointValues'
})
// tslint:disable-next-line:class-name
export class transformEndpointValues implements PipeTransform {
  transform(
    value: any,
    endpoint: any,
    section?: string
  ): Number | Number[] | string {
    try {
      if (endpoint.id === Constants.TYPE_ENDPOINTS.DIMMER ||
        endpoint.id === Constants.TYPE_ENDPOINTS.GENERIC_DIMMER) {
        if (section === 'section:value') {
          let changedDimmer = [];
          if (!value) {
            changedDimmer = [0, 0];
          } else {
            changedDimmer[0] = Math.ceil((Number(value[0]) * 100) / 255);
            changedDimmer[0] =
              changedDimmer[0] > 100 ? 100 : changedDimmer[0];
            changedDimmer[1] = Math.ceil((Number(value[1]) * 100) / 255);
            changedDimmer[1] =
              changedDimmer[1] > 100 ? 100 : changedDimmer[1];
          }
          return changedDimmer;
        }
      } else if (endpoint.id === Constants.TYPE_ENDPOINTS.BRIGHTNESS ||
        endpoint.id === Constants.TYPE_ENDPOINTS.BRIGHTNESS_ALT ||
        Constants.TYPE_ENDPOINTS.VALUE_X.test(endpoint.id) ||
        Constants.TYPE_ENDPOINTS.CHANNEL_X.test(endpoint.id)) {
          let changedValue;
          if (!value) {
            changedValue = 0;
            return changedValue;
          } else {
            changedValue = Math.ceil((Number(value) * 100) / 255);
            return changedValue > 100 ? 100 : changedValue;
          }
      } else if (endpoint.id === Constants.TYPE_ENDPOINTS.DURATION) {
        if (!value) {
          return '0:00';
        } else {
          const minutes = Math.floor(value / 60);
          const seconds = Math.round((value % 60) * 0.6)
          return minutes + ':' + (seconds < 10 ? '0' + seconds : seconds);
        }
      } else {
        if (endpoint.units.length && value) {
          if (Array.isArray(value)) {
            if (
              'factor' in endpoint.units[0] &&
              'offset' in endpoint.units[0]
            ) {
              for (let i = 0; i < value.length; i++) {
                const tempValue =
                  Number(value) * Number(endpoint.units[0].factor) -
                  Number(endpoint.units[0].offset);
                if (tempValue) {
                  value[i] = Math.round(tempValue * 100) / 100;
                } else {
                  value[i] = 0;
                }
              }
              return value;
            }
          } else {
            if (
              'factor' in endpoint.units[0] &&
              'offset' in endpoint.units[0]
            ) {
              const tempValue =
                Number(value) * Number(endpoint.units[0].factor) -
                Number(endpoint.units[0].offset);
              if (tempValue) {
                return Math.round(tempValue * 100) / 100;
              } else {
                return 0;
              }
            }
          }
        }
      }
    } catch (e) {
      console.error('No transform value', e);
    }
    if (typeof value === 'boolean') {
      return value ? 'On' : 'Off';
    } else {
      return value;
    }
  }
}
