import { ApartmentService } from './../services/apartment.service';
import { BuildingService } from './../services/building.service';
import { User } from './../models/user.model';
import { of } from 'rxjs';
import { Location } from '@angular/common';
import { AuthService } from './../services/auth.service';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { NodeService } from '../services/node.service';
import { TabletService } from '../services/tablet.service';
import { RoomService } from '../services/room.service';
import { map } from 'rxjs/operators';

@Injectable()
export class TagsGuardService implements CanActivate {

  constructor(
    private auth: AuthService,
    private location: Location,
    private nodeService: NodeService,
    private tabletService: TabletService,
    private buildingService: BuildingService,
    private apartmentService: ApartmentService,
    private roomService: RoomService
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const currentUser = this.auth.getAuthUser();
    if (currentUser.is_super_admin) {
      return true;
    }

    const id = route.params.id;
    const service = route.data.service;

    if (currentUser.is_admin && service !== 'tablets') {
      return true;
    }
    switch (service) {
      case 'apartments':
        return this.itemService(this.apartmentService, id, 'apartments', currentUser);
      case 'buildings':
        return this.itemService(this.buildingService, id, 'buildings', currentUser);
      case 'nodes':
        return this.itemService(this.nodeService, id, 'devices', currentUser);
      case 'rooms':
        return this.itemService(this.roomService, id, 'rooms', currentUser);
      case 'tablets':
        return this.itemService(this.tabletService, id, 'devices', currentUser);
      default:
        return false;
    }
  }

  private itemService(serviceClass: any, itemId: string, tagsGroup: string, currentUser: User) {
    return serviceClass.show(itemId).pipe(map(
      (item: any) => {
        const itemGroupTags = currentUser.groups[tagsGroup];
        if (this.checkTags(itemGroupTags, item.groups)) {
          return true;
        }
        this.location.back();
        return false;
      },
      err => {
        this.location.back();
        return of(false);
      }
    ));
  }

  private checkTags(userTags: string[], tags: string[]) {
    if (!userTags || !tags || userTags.length === 0 || tags.length === 0) {
      return false;
    }
    let index;
    for (index = 0; index < userTags.length && tags.indexOf(userTags[index]) === -1; index++) { }
    return (index < userTags.length);
  }
}
