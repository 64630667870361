import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router
} from '@angular/router';
import * as _ from 'underscore';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

/**
 *
 */
@Injectable()
export class RoutingService {
  /**
   * Observable and observer with the breadcrumbs.
   */
  private breadcrumbs$ = new BehaviorSubject<any[]>([]);
  private data: any[] = [];
  private nueva_pagina = true;
  public optional: any = {};
  private history = [];

  /**
   * Additional breadcrumb.
   */
  private optionalBreadcrumb: string = null;

  /**
   * Builds the component and initializes route services.
   * @param {ActivatedRoute} route Contains the information about the route.
   * @param {Router} router Provides the navigation and url manipulation capabilities.
   */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged()
    ).subscribe(event => this.initializeBreadcrumbs(event));
  }

  /**
   * Initializes breadcrumbs.
   * @param event Data of the new route.
   */
  private initializeBreadcrumbs(event: any) {
    const breadcrumbs = sessionStorage.getItem('breadcrumbs');
    if (!breadcrumbs || this.optionalBreadcrumb) {
      const item = this.getRouteItem();
      this.push(item);
    } else {
      this.data = JSON.parse(breadcrumbs);
      const index_breadcrumbs = this.indexOfRoute(event.url);
      let item = this.getRouteItem();
      if (index_breadcrumbs !== -1) {
        item = this.data[index_breadcrumbs];
        this.data = this.data.slice(0, index_breadcrumbs);
      } else if (this.nueva_pagina || item.nav === '0') {
        this.data = [];
      }
      this.data.push(item);
      this.breadcrumbs$.next(this.data);
    }
    sessionStorage.setItem('breadcrumbs', JSON.stringify(this.data));
    this.nueva_pagina = false;
    this.optionalBreadcrumb = null;
  }

  /**
   * Returns the index of a route in the data array.
   * @param {string} route Route.
   * @return {number} Index of the data array.
   */
  private indexOfRoute(route: string): number {
    let index: number;
    for (index = 0; index < this.data.length && route !== this.data[index].path; index++) { };
    if (index >= this.data.length) {
      return -1;
    }
    return index;
  }

  /**
   * Returns the data of a route.
   */
  private getDataRoute() {
    let child = this.route.firstChild;
    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
      } else if (child.snapshot.data) {
        return child.snapshot.data;
      } else {
        child = null;
      }
    }
    return null;
  }

  /**
   * Returns a breadcrumb element.
   */
  private getRouteItem() {
    const tempItem = this.getDataRoute() || {};

    let tempLabel = tempItem.breadcrumb || '';
    if (tempLabel.match(/{{([\s\S]+?)}}/g)) {
      if (this.optionalBreadcrumb) {
        tempLabel = tempLabel.replace(
          /{{([\s\S]+?)}}/g,
          this.optionalBreadcrumb
        );
      } else {
        tempLabel = tempLabel.replace(/{{|}}/g, '');
      }
    }
    return {
      title: tempItem.title ? this.translate.instant(tempItem.title) : '',
      path: window.location.pathname,
      nav: tempItem.nav || '0',
      label: tempItem.breadcrumb_translate ?
        this.translate.instant(tempItem.breadcrumb_translate, { value: tempLabel })
        : (tempLabel ? this.translate.instant(tempLabel) : '')
    };
  }

  /**
   * Adds an element to the breadcrumbs.
   * @param item Breadcrumb.
   */
  private push(item: any) {
    if (item.nav !== 0 && this.data.length > 0) {
      let dataResult: any[] = [];
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].nav < item.nav) {
          dataResult = [...dataResult, this.data[i]];
        }
        dataResult = [...dataResult, item];
        dataResult = _.sortBy(
          _.uniq(dataResult, (itemB) => {
            return itemB.path;
          }),
          (itemB: any) => {
            return itemB.nav;
          }
        );
      }
      this.data = dataResult;
    } else {
      this.data = [item];
    }

    this.breadcrumbs$.next(this.data);
  }

  /**
   * Returns the list of breadcrumbs.
   * @return {Observable} List of breadcrumbs as observable.
   */
  getBreadCrumb(): Observable<any> {
    return this.breadcrumbs$.asObservable();
  }

  /**
   * Makes the interface navigate to a certain route.
   * @param routerArray Route to follow.
   * @param optional Title of the breadcrumb for the next route.
   */
  goTo(routerArray: any, optional: any = {}) {
    this.optional = optional;
    this.optionalBreadcrumb = optional.breadcrumb || null;
    this.router.navigate(routerArray);
  }

  public loadRouting(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(({ url }: NavigationStart) => {
        this.history = [...this.history, url];
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/dashboard';
  }
}
