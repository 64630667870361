import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringToInt'
})
export class StringToIntPipe implements PipeTransform {

  transform(value: string, args?: any): number {
    return parseInt(value, 10);
  }

}
